/** @format */

import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { formatDateFromDb } from "../../utils/date/date_formatter";
import { Order } from "../../models/order";
import { MultipleFileUpload } from "../../utils/files/multipleFileUpload";
import { returnInboundUnits } from "../../pages/admin/3plclients/api/unitsApi";
import { useNotification } from "../../utils/notification/notificationContext";
import { useState } from "react";
import LoadingWheel from "../loadingWheel";
import CopyButton from "../copyButton";

function CheckInDatesModal({
    openCheckInDatesModal,
    setOpenCheckInDatesModal,
    selectedOrder,
    filesByOrder,
    handleFileChange,
    removeFile,
    handleRefetch,
    client_type = "admin",
}: {
    openCheckInDatesModal: boolean;
    setOpenCheckInDatesModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedOrder: Order | null;
    filesByOrder?: {
        [orderId: string]: File[];
    };
    handleFileChange?: (orderId: string, files: File[]) => void;
    removeFile?: (orderId: string, fileToRemove: File) => void;
    handleRefetch?: () => void;
    client_type?: string;
}) {
    const { showNotification } = useNotification();

    const [loadings, setLoadings] = useState<string[]>([]);

    const handleReturn = async (check_in_id: string) => {
        setLoadings((prev) => [...prev, check_in_id]);
        const data = await returnInboundUnits(check_in_id);
        if (data.status === "success") {
            setOpenCheckInDatesModal(false);
            if (handleRefetch) {
                handleRefetch();
            }
            showNotification("Returned units to inbound", "", "success");
        } else {
            showNotification("Failed to remove units", data.message, "error");
        }
        setLoadings((prev) => prev.filter((id) => id !== check_in_id));
    };

    return (
        <Dialog
            open={openCheckInDatesModal}
            onClose={() => setOpenCheckInDatesModal(false)}
            className="relative z-20"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-20 w-screen">
                <div className="h-4/5 flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-3/5 w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-lg sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() => setOpenCheckInDatesModal(false)}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-3"
                        >
                            Check in information
                        </DialogTitle>
                        <div className="text-sm text-gray-700 flex flex-row items-center justify-between">
                            <span>Shipment ID: {selectedOrder?.order_id}</span>
                            <CopyButton
                                textToCopy={selectedOrder?.order_id || ""}
                            />
                        </div>
                        <div className="pb-2"></div>
                        <div className="w-full h-px bg-gray-200"></div>

                        <div className="flex-1 overflow-y-auto mt-4 mb-4 space-y-4">
                            <table className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full">
                                <thead>
                                    <tr className="text-left text-sm">
                                        <th className="py-2 sm:min-w-32 min-w-32 ">
                                            Check in date
                                        </th>
                                        <th className="py-2 sm:min-w-32 min-w-28 whitespace-nowrap">
                                            Units
                                        </th>
                                        {client_type === "admin" && (
                                            <th className="py-2 sm:min-w-32 min-w-28 whitespace-nowrap">
                                                Location
                                            </th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedOrder &&
                                        selectedOrder.check_ins.map(
                                            (item, index) => (
                                                <tr
                                                    key={index}
                                                    className="relative border-t border-gray-200 text-sm text-gray-500"
                                                >
                                                    <td className="py-2.5">
                                                        {formatDateFromDb(
                                                            item.check_in_date
                                                        )}
                                                    </td>
                                                    <td className="">
                                                        {item.quantity_received
                                                            ? item.quantity_received
                                                            : "All"}
                                                    </td>
                                                    {client_type ===
                                                        "admin" && (
                                                        <>
                                                            <td className="">
                                                                {item.location}
                                                            </td>
                                                            <td>
                                                                {loadings.includes(
                                                                    item.check_in_id
                                                                ) ? (
                                                                    <LoadingWheel size="small" />
                                                                ) : (
                                                                    <TrashIcon
                                                                        onClick={() =>
                                                                            handleReturn(
                                                                                item.check_in_id
                                                                            )
                                                                        }
                                                                        className="w-4 h-4 text-red-600 hover:text-red-500"
                                                                    />
                                                                )}
                                                            </td>
                                                        </>
                                                    )}
                                                </tr>
                                            )
                                        )}
                                </tbody>
                            </table>
                            <div className="pt-2"></div>
                            <span className="text-sm font-semibold">Notes</span>
                            <p className="text-sm text-gray-600">
                                {selectedOrder?.admin_notes}
                            </p>
                            <div className="grid grid-cols-2 gap-10">
                                {selectedOrder &&
                                    selectedOrder.image_urls &&
                                    selectedOrder.image_urls.map((image) => (
                                        <div>
                                            <img
                                                src={image}
                                                className=" aspect-auto w-full"
                                                alt="tracking_image"
                                            />
                                            <a
                                                href={image}
                                                className="text-sm text-blue-600"
                                            >
                                                Save image
                                            </a>
                                        </div>
                                    ))}
                            </div>
                            {filesByOrder && handleFileChange && removeFile && (
                                <>
                                    <h1 className="text-sm font-semibold">
                                        Upload images
                                    </h1>
                                    <MultipleFileUpload
                                        selectedFiles={
                                            selectedOrder && filesByOrder
                                                ? filesByOrder[
                                                      selectedOrder.order_id
                                                  ]
                                                : []
                                        }
                                        handleFileChangeRoot={handleFileChange}
                                        removeFile={removeFile}
                                        orderId={
                                            selectedOrder
                                                ? selectedOrder.order_id
                                                : ""
                                        }
                                        fileType="Images"
                                    />
                                </>
                            )}
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}

export default CheckInDatesModal;
