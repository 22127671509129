/** @format */
import { useState, useEffect } from "react";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import LoadingWheel from "../../../../components/loadingWheel";
import { useParams } from "react-router-dom";
import { ScannedBarcode } from "../../../../models/scannedBarcode";
import {
    addTrackingToClient,
    deleteTrackingFromClient,
} from "../api/trackingsApi";
import { v4 as uuidv4 } from "uuid";
import useScanDetection from "../../../../utils/useScanDetection";
import React, { ChangeEvent, KeyboardEvent } from "react";

export function AddClientTrackingsModal({
    openAddClientTrackingsModal,
    setOpenAddClientTrackingsModal,
}: {
    openAddClientTrackingsModal: boolean;
    setOpenAddClientTrackingsModal: React.Dispatch<
        React.SetStateAction<boolean>
    >;
}) {
    const [loading, setLoading] = useState(false);

    const { user_id } = useParams();

    const [scannedBarcodes, setScannedBarcodes] = useState<ScannedBarcode[]>(
        []
    );

    const [currentInput, setCurrentInput] = useState<string>("");

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCurrentInput(e.target.value);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && currentInput.trim()) {
            e.preventDefault();

            const newScannedBarcode: ScannedBarcode = {
                id: uuidv4(), // Generate a unique ID
                barcode: String(currentInput.trim()),
                dateScanned: new Date().toLocaleString(),
                isLoading: true, // Set loading to true initially
            };
            setScannedBarcodes((prev) => [...prev, newScannedBarcode]);

            handleAddTracking(newScannedBarcode);

            setCurrentInput("");
        }
    };

    const removeBarcode = (id: string) => {
        setScannedBarcodes((prev) =>
            prev.map((item) =>
                item.id === id ? { ...item, isLoading: true } : item
            )
        );

        handleDeleteTracking(id);
    };

    const handleAddTracking = async (scannedBarcode: ScannedBarcode) => {
        const data = await addTrackingToClient(user_id, scannedBarcode);
        if (data.status === "success") {
            setScannedBarcodes((prev) =>
                prev.map((item) =>
                    item.id === scannedBarcode.id
                        ? { ...item, isLoading: false, error: data.message }
                        : item
                )
            );
        } else {
            setScannedBarcodes((prev) =>
                prev.map((item) =>
                    item.id === scannedBarcode.id
                        ? { ...item, isLoading: false, error: data.message }
                        : item
                )
            );
        }
    };

    const handleDeleteTracking = async (id: string) => {
        const data = await deleteTrackingFromClient(id);
        if (data.status === "success") {
        } else {
        }
        setScannedBarcodes((prev) => prev.filter((item) => item.id !== id));
    };

    return (
        <Dialog
            open={openAddClientTrackingsModal}
            onClose={() => setOpenAddClientTrackingsModal(false)}
            className="relative z-10"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen">
                <div className="h-4/5 flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-4/5 w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-lg sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() =>
                                    setOpenAddClientTrackingsModal(false)
                                }
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-5"
                        >
                            Scan received tracking numbers
                        </DialogTitle>
                        <div className="w-full h-px bg-gray-200"></div>

                        <div className="flex-1 overflow-y-auto mt-4 mb-4 flex-col space-y-4">
                            <span className="text-gray-500 text-sm">
                                {scannedBarcodes.length} barcodes scanned
                            </span>

                            <input
                                type="text"
                                id="tracking"
                                value={currentInput}
                                onChange={handleInputChange}
                                onKeyDown={handleKeyDown}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                placeholder="Press here and start scanning."
                            />

                            {scannedBarcodes.map((barcode, index) => (
                                <div className="flex flex-row w-full justify-between items-center">
                                    <div className="flex flex-col">
                                        <span className="text-sm">
                                            {barcode.barcode}
                                        </span>
                                        <span className="text-sm text-gray-600">
                                            {barcode.dateScanned}
                                        </span>
                                        {barcode.error ? (
                                            <span className="text-xs text-red-500">
                                                {barcode.error}
                                            </span>
                                        ) : null}
                                    </div>
                                    {barcode.isLoading ? (
                                        <div className="w-5 h-5">
                                            <LoadingWheel />{" "}
                                        </div>
                                    ) : (
                                        <TrashIcon
                                            onClick={() =>
                                                removeBarcode(barcode.id)
                                            }
                                            className="w-5 h-5 text-red-500"
                                        />
                                    )}
                                </div>
                            ))}
                        </div>

                        {/* Fixed buttons at the bottom */}
                        <div className="pt-4 sm:pt-0 flex justify-between">
                            <div className="space-x-2">
                                <button
                                    type="button"
                                    onClick={() => setScannedBarcodes([])}
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                >
                                    Clear fields
                                </button>
                            </div>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}
