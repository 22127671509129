/** @format */

import { FaInstagram, FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const navigation = {
    company: [
        { name: "Home", href: "#" },
        { name: "Contact", href: "/contact" },
    ],
    services: [
        { name: "Order Fulfillment", href: "/order-fulfillment" },
        { name: "Ecommerce Management", href: "/ecommerce-management" },
    ],
    social: [
        {
            name: "Instagram",
            href: "https://www.instagram.com/urva/",
            icon: FaInstagram,
        },
        {
            name: "LinkedIn",
            href: "https://www.linkedin.com/company/urvafreight",
            icon: FaLinkedin,
        },
        {
            name: "Twitter",
            href: "https://x.com/urvafreight",
            icon: FaXTwitter,
        },
    ],
};

export default function Footer() {
    const currentYear = new Date().getFullYear(); // Get the current year
    return (
        <footer aria-labelledby="footer-heading" className="bg-white">
            <h2 id="footer-heading" className="sr-only">
                Footer
            </h2>
            <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
                <div className="xl:grid xl:grid-cols-3 xl:gap-8">
                    <div className="space-y-8">
                        <p className="text-3xl font-semibold">URVA</p>
                        <p className="text-sm leading-6 text-gray-600">
                            Let us handle your operations, so you can focus on
                            growing your business.
                        </p>
                        <p className="text-sm leading-6 text-gray-600">
                            <span className="font-semibold">
                                Delaware Warehouse | 100% Sales Tax Free
                            </span>
                            <br></br>
                            Bear, Delaware
                            <br></br>
                            contact@urvafreight.com
                        </p>
                    </div>
                    <div className="mt-16 grid grid-cols-3 gap-8 xl:col-span-2 xl:mt-0">
                        <div className="xl:mx-auto ml-0">
                            <h3 className="text-sm font-semibold leading-6 text-gray-900">
                                Follow Us
                            </h3>
                            <div className="flex space-x-6 pt-6">
                                {navigation.social.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className="text-gray-400 hover:text-gray-500"
                                    >
                                        <span className="sr-only">
                                            {item.name}
                                        </span>
                                        <item.icon
                                            aria-hidden="true"
                                            className="h-6 w-6"
                                        />
                                    </a>
                                ))}
                            </div>
                        </div>
                        <div className="xl:mx-auto ml-0">
                            <h3 className="text-sm font-semibold leading-6 text-gray-900">
                                Company
                            </h3>
                            <ul role="list" className="mt-6 space-y-4">
                                {navigation.company.map((item) => (
                                    <li key={item.name}>
                                        <a
                                            href={item.href}
                                            className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                                        >
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="xl:mx-auto ml-0">
                            <h3 className="text-sm font-semibold leading-6 text-gray-900">
                                Services
                            </h3>
                            <ul role="list" className="mt-6 space-y-4">
                                {navigation.services.map((item) => (
                                    <li key={item.name}>
                                        <a
                                            href={item.href}
                                            className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                                        >
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 lg:mt-24">
                    <p className="text-xs leading-5 text-gray-500">
                        &copy; {currentYear} URVA Freight, Inc. All rights
                        reserved.
                    </p>
                </div>
            </div>
        </footer>
    );
}
