import { NavigateFunction } from "react-router-dom";
import Cookies from "js-cookie";
import { setAdminType } from "../../../utils/adminTypes/actions";
import { setClientType } from "../../../utils/clientTypes/actions";

export const loginRequest = async (
    email: string,
    password: string,
    client_type: string,
    navigate: NavigateFunction,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setFailed: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    setLoading(true)
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + "/api/v1/login",
            {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({email, password, client_type})
            }
        )

        const data = await response.json();

        if (data.status === "success") {
            setLoading(false)

            Cookies.set("jwt", data.data.jwt_token, {
                expires: 30,
                secure: true,
            });

            if (data.data.client_type === "admin") {
                setAdminType(data.data.admin_type)
                navigate("/admin/clients")
            } else {
                setClientType(data.data.client_type)
                navigate("/")
            }

        } else {
            setLoading(false)
            setFailed(true)
        }
    } catch(error) {
        setFailed(true)
        setLoading(false)
    }
}

export const signupRequest = async (
    email: string,
    firstName: string,
    lastName: string,
    phone: string,
    password: string,
    client_type: string,
    navigate: NavigateFunction,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setFailed: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    setLoading(true)
    try {
        const jsonBody = {
            "email": email,
            "first_name": firstName,
            "last_name": lastName,
            "phone_number": phone,
            "password": password,
            "client_type": client_type

        }
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + "/api/v1/signup",
            {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(jsonBody)
            }
        )

        const data = await response.json();

        if (data.status === "success") {
            setLoading(false)

            Cookies.set("jwt", data.data.jwt_token, {
                expires: 30,
                secure: true,
            });

            navigate("/")

        } else {
            setLoading(false)
            setFailed(true)
        }
    } catch(error) {
        setFailed(true)
        setLoading(false)
    }
}

export const forgotPasswordRequest = async (
    email: string,
    navigate: NavigateFunction,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    setLoading(true)
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + "/api/v1/forgot-password",
            {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ email })
            }
        )

        const data = await response.json();

        if (data.status === "success") {
            setLoading(false)

            navigate(`/reset-password?email=${encodeURIComponent(email)}`)

        } else {
            setLoading(false)
        }
    } catch(error) {
        setLoading(false)
    }
}

export const resetPasswordRequest = async (
    email: string | null,
    password: string,
    code: string,
    navigate: NavigateFunction,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setVerificationCodeIncorrect: React.Dispatch<React.SetStateAction<boolean>>,
    showNotification: (title: string, subtitle?: string, type?: "success" | "error") => void
) => {
    setLoading(true)
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + "/api/v1/reset-password",
            {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ email, password, code })
            }
        )

        const data = await response.json();

        if (data.status === "success") {
            setLoading(false)

            navigate(`/reset-password/success`)

        } else if (data.message === "verification code is incorrect") {
            setLoading(false)
            setVerificationCodeIncorrect(true)
        } else {
            showNotification("Failed to change password", data.message, "error")
            setLoading(false)
        }
    } catch(error) {
        setLoading(false)
        showNotification("Failed to change password", String(error), "error")
    }
}