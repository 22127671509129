/** @format */

import { useSearchParams } from "react-router-dom";
import LoadingWheel, { SortLoading } from "../../../../components/loadingWheel";
import Results from "../../../../models/results";
import {
    calculateOffset,
    getOffset,
} from "../../../../utils/pagination/calculateOffset";
import { EmptyTrackingsTable } from "../../../../components/emptyStates";
import { convertDateToEST } from "../../../../utils/date/date_formatter";
import "../../../../index.css";
import { PaginationLink } from "../../../../utils/pagination/paginationLink";
import { PaginationResults } from "../../../../utils/pagination/paginationResults";
import { TrackingArrived } from "../../../../components/trackingArrived";
import LimitSelector from "../../../../utils/pagination/limitSelector";
import useScrollTranslation from "../../../../hooks/useScrollTranslation";
import ColumnSort from "../../../../utils/sort/columnSort";
import { sortableColumns } from "../../../../hooks/useSort";

export default function TrackingsArrivedTable({
    trackings,
    results,
    loading,
    handleRefetch,
    sortConfig,
    toggleSort,
    sortLoading,
    type = "inventory",
}: {
    trackings: TrackingArrived[];
    results: Results;
    loading: boolean;
    handleRefetch: () => void;
    sortConfig: {
        key: string;
        order: string;
    };
    toggleSort: (key: string) => void;
    sortLoading: boolean;
    type?: string;
}) {
    const [searchParams] = useSearchParams();

    const tableHeaders = [
        {
            key: type === "inventory" || !type ? "created_at" : "created_at",
            label: type === "inventory" || !type ? "Date Arrived" : "Date Sent",
            className: "sm:min-w-32 min-w-32",
        },
        {
            key: "tracking",
            label: "Tracking number",
            className: "sm:min-w-32 min-w-32",
        },
        {
            key: "carrier",
            label: "Detected Carrier",
            className: "sm:min-w-32 min-w-32",
        },
        {
            key: "tracking_url",
            label: "URL",
            className: "sm:min-w-32 min-w-28",
        },
    ];

    const { scrollContainerRef, scrollOffset } = useScrollTranslation();

    return (
        <div>
            {!loading && trackings && trackings.length > 0 ? (
                <>
                    <div
                        ref={scrollContainerRef}
                        className={`max-h-[650px] overflow-y-auto overflow-x-scroll relative scrollbar ${
                            sortLoading
                                ? "pointer-events-none overflow-hidden"
                                : ""
                        }`}
                    >
                        <SortLoading
                            sortLoading={sortLoading}
                            scrollOffset={scrollOffset}
                        />
                        <table className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full">
                            <thead className="sticky top-0 bg-white z-10">
                                <tr className="text-left text-sm">
                                    {tableHeaders.map((header) => (
                                        <th
                                            key={header.key}
                                            onClick={() =>
                                                sortableColumns.includes(
                                                    header.key
                                                ) && toggleSort(header.key)
                                            }
                                            className={`group py-2 cursor-pointer ${
                                                sortableColumns.includes(
                                                    header.key
                                                )
                                                    ? "hover:bg-gray-100"
                                                    : ""
                                            } ${header.className}`}
                                        >
                                            {header.label}
                                            <ColumnSort
                                                header={header}
                                                sortConfig={sortConfig}
                                            />
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {trackings.map((tracking, index) => (
                                    <tr
                                        key={index}
                                        className="relative border-t border-gray-200 text-sm text-gray-500"
                                    >
                                        <td className="py-2.5">
                                            {convertDateToEST(tracking.date)}
                                        </td>
                                        <td className="">
                                            {tracking.tracking}
                                        </td>
                                        <td className="">{tracking.carrier}</td>
                                        {tracking.tracking_url !== "Unknown" ? (
                                            <td className="text-accent cursor-pointer">
                                                <a
                                                    href={tracking.tracking_url}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    View tracking
                                                </a>
                                            </td>
                                        ) : (
                                            <td>Unknown</td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <nav
                        aria-label="Pagination"
                        className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                    >
                        <div className="hidden sm:block">
                            <div className="flex flex-row items-center space-x-3">
                                <PaginationResults results={results} />
                                <LimitSelector />
                            </div>
                        </div>
                        <div className="flex flex-1 justify-between sm:justify-end">
                            <PaginationLink
                                href={(function () {
                                    const params = new URLSearchParams(
                                        searchParams
                                    );
                                    // Set the previous page offset
                                    params.set(
                                        "offset",
                                        calculateOffset(
                                            searchParams,
                                            "back"
                                        ).toString()
                                    );
                                    return `/${type}/trackings?${params.toString()}`;
                                })()}
                                disabled={getOffset(searchParams) === 0}
                                text="Previous"
                            />
                            <PaginationLink
                                href={(function () {
                                    const params = new URLSearchParams(
                                        searchParams
                                    );
                                    // Set the next page offset
                                    params.set(
                                        "offset",
                                        calculateOffset(
                                            searchParams,
                                            "forward"
                                        ).toString()
                                    );
                                    return `/${type}/trackings?${params.toString()}`;
                                })()}
                                disabled={
                                    results.showing_to >= results.total_results
                                }
                                text="Next"
                                className="ml-3"
                            />
                        </div>
                    </nav>
                </>
            ) : (
                <div className="flex flex-grow items-center pt-44 justify-center">
                    {loading ? <LoadingWheel /> : <EmptyTrackingsTable />}
                </div>
            )}
        </div>
    );
}
