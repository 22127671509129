/** @format */

import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
    calculateOffset,
    getOffset,
} from "../../../utils/pagination/calculateOffset";
import { formatDateFromDb } from "../../../utils/date/date_formatter";
import Shipment from "../../../models/shipments";
import LoadingWheel, { SortLoading } from "../../../components/loadingWheel";
import { EmptyOutboundTable } from "../../../components/emptyStates";
import OutboundOrdersModal from "./outboundOrdersModal";
import Results from "../../../models/results";
import { PaginationLink } from "../../../utils/pagination/paginationLink";
import { PaginationResults } from "../../../utils/pagination/paginationResults";
import { ShipmentStatusTag } from "../../../components/statusTags";
import { ShipmentPageSelector } from "../../../models/pageSelectors";
import LimitSelector from "../../../utils/pagination/limitSelector";
import { ValidPageTypes } from "../../../hooks/usePage";
import useScrollTranslation from "../../../hooks/useScrollTranslation";
import ColumnSort from "../../../utils/sort/columnSort";
import { sortableColumns } from "../../../hooks/useSort";
import { downloadShipmentPdf } from "../../admin/3plclients/api/labelsApi";
import { useNotification } from "../../../utils/notification/notificationContext";
import { PrinterIcon } from "@heroicons/react/24/outline";
import useCellSelection from "../../../hooks/useCellSelection";
import { formatMoney } from "../../../utils/price/formatMoney";

const tableHeaders = [
    {
        key: "status",
        label: "Status",
        className: "sm:min-w-32 min-w-32",
    },
    { key: "created_at", label: "Date", className: "sm:min-w-32 min-w-28" },
    {
        key: "name",
        label: "Shipment Name",
        className: "sm:min-w-44 min-w-44",
    },
    {
        key: "total_quantity",
        label: "Total Quantity",
        className: "sm:min-w-44 min-w-44",
    },
    {
        key: "shipment_value",
        label: "Shipment value",
        className: "sm:min-w-32 min-w-28",
    },
    { key: "notes", label: "Notes", className: "sm:min-w-32 min-w-28" },
];

const tableHeaders2 = [
    {
        key: "status",
        label: "Status",
        className: "sm:min-w-32 min-w-32",
    },
    { key: "created_at", label: "Date", className: "sm:min-w-32 min-w-28" },
    {
        key: "name",
        label: "Shipment Name",
        className: "sm:min-w-44 min-w-44",
    },
    {
        key: "total_quantity",
        label: "Total Quantity",
        className: "sm:min-w-44 min-w-44",
    },
    {
        key: "shipment_value",
        label: "Shipment value",
        className: "sm:min-w-32 min-w-28",
    },
    { key: "notes", label: "Notes", className: "sm:min-w-32 min-w-28" },
    { key: "actions", label: "Actions", className: "text-right" },
];

function ShipmentsTable({
    shipments,
    results,
    loading,
    pageSelected,
    sortConfig,
    toggleSort,
    sortLoading,
    handleRefetch,
}: {
    shipments: Shipment[];
    results: Results;
    loading: boolean;
    pageSelected: ValidPageTypes;
    sortConfig: {
        key: string;
        order: string;
    };
    toggleSort: (key: string) => void;
    sortLoading: boolean;
    handleRefetch: () => void;
}) {
    const { showNotification } = useNotification();
    const [searchParams] = useSearchParams();

    const [openOutboundOrders, setOpenOutboundOrders] = useState(false);

    const [selectedShipment, setSelectedShipment] = useState<Shipment | null>(
        null
    );

    useEffect(() => {
        if (selectedShipment) {
            setOpenOutboundOrders(true);
        }
    }, [selectedShipment]);

    useEffect(() => {
        if (!openOutboundOrders) {
            setTimeout(() => {
                setSelectedShipment(null);
            }, 200);
        }
    }, [openOutboundOrders]);

    const { scrollContainerRef, scrollOffset } = useScrollTranslation();

    const headers = pageSelected === "shipped" ? tableHeaders : tableHeaders2;

    const handleDownload = async (
        event: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>,
        shipment_id: string
    ) => {
        event.stopPropagation();
        const success = await downloadShipmentPdf(shipment_id);
        if (success) {
        } else {
            showNotification("Failed to download pdf");
        }
    };

    const getCellValue = (
        rowIndex: number,
        columnIndex: number,
        data: any[]
    ) => {
        const shipment = data[rowIndex];
        switch (columnIndex) {
            case 3:
                return shipment.total_quantity_shipped;
            case 4:
                return shipment.shipment_value;
            default:
                return 0;
        }
    };

    const {
        selectedCells,
        handleSingleClick,
        handleMouseDown,
        handleMouseEnter,
        handleMouseUp,
        sumSelectedQuantities,
    } = useCellSelection([], getCellValue, [3, 4]);

    return (
        <div className="flex flex-col">
            {!loading && shipments && shipments.length > 0 ? (
                <>
                    <div
                        ref={scrollContainerRef}
                        className={`max-h-[650px] overflow-y-auto overflow-x-scroll relative scrollbar ${
                            sortLoading
                                ? "pointer-events-none overflow-hidden"
                                : ""
                        }`}
                    >
                        <SortLoading
                            sortLoading={sortLoading}
                            scrollOffset={scrollOffset}
                        />
                        <table
                            onMouseUp={handleMouseUp}
                            className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full"
                        >
                            <thead className="sticky top-0 bg-white z-10">
                                <tr className="text-left text-sm">
                                    {headers.map((header) => (
                                        <th
                                            key={header.key}
                                            onClick={() =>
                                                sortableColumns.includes(
                                                    header.key
                                                ) && toggleSort(header.key)
                                            }
                                            className={`group py-2 cursor-pointer ${
                                                sortableColumns.includes(
                                                    header.key
                                                )
                                                    ? "hover:bg-gray-100"
                                                    : ""
                                            } ${header.className}`}
                                        >
                                            {header.label}
                                            <ColumnSort
                                                header={header}
                                                sortConfig={sortConfig}
                                            />
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {shipments.map((shipment, rowIndex) => (
                                    <tr
                                        onClick={() =>
                                            setSelectedShipment(shipment)
                                        }
                                        key={shipment.shipment_id}
                                        className="relative border-t border-gray-200 text-sm text-gray-500 hover:bg-gray-100 cursor-pointer"
                                    >
                                        {pageSelected === "shipped" ? (
                                            <>
                                                <td className="py-2.5">
                                                    <ShipmentStatusTag
                                                        status={
                                                            shipment.admin_status
                                                                ? shipment.admin_status
                                                                : shipment.status
                                                        }
                                                    />
                                                </td>
                                                <td className="py-2.5">
                                                    {formatDateFromDb(
                                                        shipment.date
                                                    )}
                                                </td>
                                                <td className="py-2.5">
                                                    {shipment.name}
                                                </td>
                                                <td
                                                    onMouseDown={() =>
                                                        handleMouseDown(
                                                            rowIndex,
                                                            3,
                                                            shipments
                                                        )
                                                    }
                                                    onMouseEnter={() =>
                                                        handleMouseEnter(
                                                            rowIndex,
                                                            3,
                                                            shipments
                                                        )
                                                    }
                                                    onClick={() =>
                                                        handleSingleClick(
                                                            rowIndex,
                                                            3,
                                                            shipments
                                                        )
                                                    }
                                                    className={`${
                                                        selectedCells.some(
                                                            (cell) =>
                                                                cell.rowIndex ===
                                                                    rowIndex &&
                                                                cell.columnIndex ===
                                                                    3
                                                        )
                                                            ? "bg-blue-200 select-none"
                                                            : "select-none"
                                                    }`}
                                                >
                                                    {
                                                        shipment.total_quantity_shipped
                                                    }
                                                </td>
                                                <td
                                                    onMouseDown={() =>
                                                        handleMouseDown(
                                                            rowIndex,
                                                            4,
                                                            shipments
                                                        )
                                                    }
                                                    onMouseEnter={() =>
                                                        handleMouseEnter(
                                                            rowIndex,
                                                            4,
                                                            shipments
                                                        )
                                                    }
                                                    onClick={() =>
                                                        handleSingleClick(
                                                            rowIndex,
                                                            4,
                                                            shipments
                                                        )
                                                    }
                                                    className={`${
                                                        selectedCells.some(
                                                            (cell) =>
                                                                cell.rowIndex ===
                                                                    rowIndex &&
                                                                cell.columnIndex ===
                                                                    4
                                                        )
                                                            ? "bg-blue-200 select-none"
                                                            : "select-none"
                                                    }`}
                                                >
                                                    {formatMoney(
                                                        shipment.shipment_value
                                                    )}
                                                </td>
                                                <td className="py-2.5">
                                                    {shipment.notes}
                                                </td>
                                            </>
                                        ) : (
                                            <>
                                                <td className="py-2.5">
                                                    <ShipmentStatusTag
                                                        status={
                                                            shipment.admin_status
                                                                ? shipment.admin_status
                                                                : shipment.status
                                                        }
                                                    />
                                                </td>
                                                <td className="py-2.5">
                                                    {formatDateFromDb(
                                                        shipment.date
                                                    )}
                                                </td>
                                                <td className="py-2.5">
                                                    {shipment.name}
                                                </td>
                                                <td
                                                    onMouseDown={() =>
                                                        handleMouseDown(
                                                            rowIndex,
                                                            3,
                                                            shipments
                                                        )
                                                    }
                                                    onMouseEnter={() =>
                                                        handleMouseEnter(
                                                            rowIndex,
                                                            3,
                                                            shipments
                                                        )
                                                    }
                                                    onClick={() =>
                                                        handleSingleClick(
                                                            rowIndex,
                                                            3,
                                                            shipments
                                                        )
                                                    }
                                                    className={`${
                                                        selectedCells.some(
                                                            (cell) =>
                                                                cell.rowIndex ===
                                                                    rowIndex &&
                                                                cell.columnIndex ===
                                                                    3
                                                        )
                                                            ? "bg-blue-200 select-none"
                                                            : "select-none"
                                                    }`}
                                                >
                                                    {
                                                        shipment.total_quantity_shipped
                                                    }
                                                </td>
                                                <td
                                                    onMouseDown={() =>
                                                        handleMouseDown(
                                                            rowIndex,
                                                            4,
                                                            shipments
                                                        )
                                                    }
                                                    onMouseEnter={() =>
                                                        handleMouseEnter(
                                                            rowIndex,
                                                            4,
                                                            shipments
                                                        )
                                                    }
                                                    onClick={() =>
                                                        handleSingleClick(
                                                            rowIndex,
                                                            4,
                                                            shipments
                                                        )
                                                    }
                                                    className={`${
                                                        selectedCells.some(
                                                            (cell) =>
                                                                cell.rowIndex ===
                                                                    rowIndex &&
                                                                cell.columnIndex ===
                                                                    4
                                                        )
                                                            ? "bg-blue-200 select-none"
                                                            : "select-none"
                                                    }`}
                                                >
                                                    {formatMoney(
                                                        shipment.shipment_value
                                                    )}
                                                </td>
                                                <td className="py-2.5">
                                                    {shipment.notes}
                                                </td>
                                                <td
                                                    onClick={(e) =>
                                                        handleDownload(
                                                            e,
                                                            shipment.shipment_id
                                                        )
                                                    }
                                                    className="text-right whitespace-nowrap pr-2 cursor-pointer space-x-2"
                                                >
                                                    <PrinterIcon className="w-5 h-5 text-accent inline-block" />
                                                </td>
                                            </>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <nav
                        aria-label="Pagination"
                        className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                    >
                        <div className="hidden sm:block">
                            <div className="flex flex-row items-center space-x-3">
                                <PaginationResults results={results} />
                                <LimitSelector />
                            </div>
                        </div>
                        <div className="flex flex-1 justify-between sm:justify-end">
                            <PaginationLink
                                href={(function () {
                                    const params = new URLSearchParams(
                                        searchParams
                                    );
                                    // Set the previous page offset
                                    params.set(
                                        "offset",
                                        calculateOffset(
                                            searchParams,
                                            "back"
                                        ).toString()
                                    );
                                    return `/outbound/${pageSelected}?${params.toString()}`;
                                })()}
                                disabled={getOffset(searchParams) === 0}
                                text="Previous"
                            />
                            <PaginationLink
                                href={(function () {
                                    const params = new URLSearchParams(
                                        searchParams
                                    );
                                    // Set the next page offset
                                    params.set(
                                        "offset",
                                        calculateOffset(
                                            searchParams,
                                            "forward"
                                        ).toString()
                                    );
                                    return `/outbound/${pageSelected}?${params.toString()}`;
                                })()}
                                disabled={
                                    results.showing_to >= results.total_results
                                }
                                text="Next"
                                className="ml-3"
                            />
                        </div>
                    </nav>
                    {selectedCells.length > 0 && (
                        <span className="text-sm text-gray-900 flex flex-row items-center">
                            {"Sum: "}
                            {sumSelectedQuantities.map(
                                ({ columnIndex, sum }) => (
                                    <>
                                        {sum > 0 && (
                                            <p
                                                key={columnIndex}
                                                className="pl-1"
                                            >
                                                {columnIndex !== 4
                                                    ? sum
                                                    : formatMoney(sum)}
                                            </p>
                                        )}
                                    </>
                                )
                            )}
                        </span>
                    )}
                </>
            ) : (
                <div className="flex flex-grow items-center pt-44 justify-center">
                    {loading ? <LoadingWheel /> : <EmptyOutboundTable />}
                </div>
            )}

            <OutboundOrdersModal
                shipmentLoaded={selectedShipment}
                openOutboundOrders={openOutboundOrders}
                setOpenOutboundOrders={setOpenOutboundOrders}
                handleRefetch={handleRefetch}
            />
        </div>
    );
}

export default ShipmentsTable;
