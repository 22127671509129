/** @format */

import { useEffect, useState } from "react";
import { fetchSettings } from "./api/settingsApi";
import {
    Box,
    EmailNotification,
    WarehouseAddress,
} from "../../../models/adminDashboard";
import ManageAdminAccounts from "./settings/manageAdminAccount";
import { AdminAccountData } from "../../../models/accountData";
import LoadingWheel from "../../../components/loadingWheel";
import ManageAddress from "./settings/manageAddress";
import ManageBoxes from "./settings/manageBoxes";
import ManageNotifications from "./settings/manageNotifications";

function ClientSettings() {
    const [loading, setLoading] = useState(false);
    const [adminAccounts, setAdminAccounts] = useState<AdminAccountData[]>([]);
    const [warehouseAddress, setWarehouseAddress] =
        useState<WarehouseAddress | null>(null);
    const [boxes, setBoxes] = useState<Box[]>([]);
    const [emailNotifications, setEmailNotifications] = useState<
        EmailNotification[]
    >([]);

    const [refetchData, setRefetchData] = useState(0);
    const handleRefetch = () => {
        setRefetchData((prev) => prev + 1);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const data = await fetchSettings();
            if (data.status === "success") {
                setAdminAccounts(data.data.accounts);
                setWarehouseAddress(data.data.address);
                setBoxes(data.data.boxes);
                setEmailNotifications(data.data.email_notifications);
            } else {
            }
            setLoading(false);
        };
        fetchData();
    }, [refetchData]);

    return (
        <div className="flex pb-10">
            {!loading ? (
                <div className="flex flex-col space-y-4">
                    <div className="flex flex-col lg:flex-row lg:space-x-10 lg:space-y-0 space-y-4">
                        <ManageAdminAccounts
                            adminAccounts={adminAccounts}
                            handleRefetch={handleRefetch}
                        />
                        <ManageBoxes
                            boxes={boxes}
                            handleRefetch={handleRefetch}
                        />
                    </div>
                    <div className="flex flex-col lg:flex-row lg:space-x-10 lg:space-y-0 space-y-4">
                        <ManageAddress
                            warehouseAddress={warehouseAddress}
                            handleRefetch={handleRefetch}
                        />
                        <ManageNotifications
                            emailNotifications={emailNotifications}
                            setEmailNotifications={setEmailNotifications}
                            handleRefetch={handleRefetch}
                        />
                    </div>
                </div>
            ) : (
                <div className="flex flex-col flex-grow items-center justify-center pt-44">
                    <LoadingWheel />
                </div>
            )}
        </div>
    );
}

export default ClientSettings;
