/** @format */

import { useState, useRef, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import { Order } from "../../models/order";
import Shipment from "../../models/shipments";
import { useLocation, useParams } from "react-router-dom";
import { TrackingArrived } from "../trackingArrived";
import { searchData } from "../../pages/client/dashboard/api/dashboardApi";

interface HeaderSearchBarProps {
    setData:
        | React.Dispatch<React.SetStateAction<Order[]>>
        | React.Dispatch<React.SetStateAction<Shipment[]>>
        | React.Dispatch<React.SetStateAction<TrackingArrived[]>>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    handleRefetch: () => void;
    page: string;
    sub_page: string;
}

export default function ClientSearchBar({
    setData,
    setLoading,
    handleRefetch,
    page,
    sub_page,
}: HeaderSearchBarProps) {
    const { user_id } = useParams();
    const [searchTerm, setSearchTerm] = useState<string>("");
    const inputRef = useRef<HTMLInputElement>(null);

    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        searchData(
            searchTerm,
            user_id,
            page,
            sub_page,
            setData,
            setLoading,
            handleRefetch
        );
        return () => searchData.cancel();
    }, [searchTerm]);

    return (
        <input
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="outline-none px-0 py-0 text-xs border border-gray-300 h-8 w-full rounded-md pl-2 focus:border-gray-400 focus:ring-0"
            ref={inputRef}
        />
    );
}
