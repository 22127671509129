/** @format */

import { useState } from "react";
import DashboardFeatures from "../../components/website/dashboardFeatures";
import Footer from "../../components/website/footer";
import Header from "../../components/website/header";
import Services from "../../components/website/services";
import { useExitIntent } from "use-exit-intent";
import { InputField } from "../../components/inputField";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { sendClaimedDiscount } from "./api/contactApi";
import LoadingWheel from "../../components/loadingWheel";
import { useNotification } from "../../utils/notification/notificationContext";

function EmailPopup({ show, onClose }: { show: any; onClose: any }) {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [loading, setLoading] = useState(false);

    const { showNotification } = useNotification();

    if (!show) {
        return null;
    }

    const handleSend = async () => {
        setLoading(true);
        const data = await sendClaimedDiscount(name, email, phone);
        if (data.status === "success") {
            onClose();
            showNotification(
                "Offer successfully claimed",
                "You will receive a follow up email shortly.",
                "success"
            );
        } else {
            console.log("failed to send email");
        }
        setLoading(false);
    };

    return (
        <div>
            {show && (
                <div
                    className={`fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 transition-opacity duration-500 ${
                        show ? "opacity-100" : "opacity-0"
                    }`}
                >
                    <div className="bg-white w-96 p-6 rounded-lg shadow-lg text-center transition-transform transform duration-500 ease-in-out scale-100">
                        <div className="absolute top-4 right-4">
                            <button
                                type="button"
                                onClick={() => onClose()}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-5 w-5"
                                />
                            </button>
                        </div>
                        <h2 className="text-xl font-semibold mb-4">
                            Wait! Don't leave yet
                        </h2>
                        <p className="mb-4 text-lg">
                            Get your first{" "}
                            <span className="text-accent font-medium">
                                100 units
                            </span>{" "}
                            fulfilled for{" "}
                            <span className="text-accent font-medium">
                                free
                            </span>
                        </p>
                        <div className="flex flex-col space-y-3">
                            <InputField
                                label="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <InputField
                                label="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <InputField
                                label="Phone number"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </div>
                        <div className="flex justify-center space-x-4 w-full pt-10">
                            <button
                                onClick={() => handleSend()}
                                className="bg-accent w-full text-white py-2 px-4 rounded-lg hover:bg-accenthighlight"
                            >
                                {loading ? (
                                    <LoadingWheel color="white" />
                                ) : (
                                    <>Send</>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default function HomePage() {
    const { registerHandler } = useExitIntent();

    const [showPopup, setShowPopup] = useState(false);

    registerHandler({
        id: "openModal",
        handler: () => setShowPopup(true),
    });

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const scrollToSection = (sectionId: string) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <div className="scroll-smooth">
            <img
                alt=""
                src="/homebg.png"
                className="absolute inset-0 -z-10 h-full w-full object-cover"
            />
            <div className="absolute inset-0 bg-black opacity-55"></div>
            <Header />
            <div className="absolute bottom-36" id="learn"></div>
            <div>
                <div className="relative isolate px-6 lg:px-8 h-screen flex items-center">
                    <div className="mx-auto max-w-2xl">
                        <div className="text-center">
                            {/* <h1 className="text-5xl font-bold tracking-tight text-gray-900 sm:text-6xl"> */}
                            <h1 className="text-5xl font-bold tracking-tight text-white sm:text-6xl">
                                Focus on your business while we handle the rest
                            </h1>
                            {/* <p className="mt-6 text-lg leading-8 text-gray-600"> */}
                            <p className="mt-6 text-lg leading-8 text-white">
                                At Urva we provide seamless order fulfillment
                                and ecommerce solutions, letting you focus on
                                growing your business while we handle the
                                logistics.
                            </p>
                            <div className="mt-10 flex items-center justify-center gap-x-6">
                                <button
                                    onClick={() => scrollToSection("learn")}
                                    className="rounded-md bg-accent px-3.5
                                    py-2.5 text-sm font-semibold text-white
                                    shadow-sm hover:bg-accenthighlight"
                                >
                                    Learn more
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="learn"></div>
            <Services />
            <div className="flex flex-col items-center mx-auto px-6 lg:px-8 space-y-10">
                <div className="flex-col flex pt-36">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">
                        Dashboard Management
                    </h2>
                    <p className="max-w-3xl mt-6 text-lg leading-8 text-gray-600 text-center">
                        Welcome to your all-in-one dashboard, built to optimize
                        your business operations. With effortless order
                        tracking, streamlined shipment management, and automated
                        invoicing, our dashboard gives you full control and
                        real-time insights into every part of your workflow.
                    </p>
                </div>
                <div className="shadow-md rounded-xl border border-gray-200 overflow-hidden max-w-6xl">
                    <img src="/img-dashboard.png" alt="Dashboard" />
                </div>
            </div>
            <DashboardFeatures />
            <Footer />

            <EmailPopup show={showPopup} onClose={handleClosePopup} />
        </div>
    );
}
