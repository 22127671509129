/** @format */

import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/authentication/login/page";
import PageNotFound from "./pages/notfound/page";
import ClientDataPage from "./pages/admin/3plclients/client-data/page";
import ClientsPage from "./pages/admin/3plclients/page";
import PrivateRoute from "./utils/auth/privateRoute";
import SignUpPage from "./pages/authentication/sign-up/page";
import UnauthorizedPage from "./pages/unauthorized/page";
import SettingsPage from "./pages/client/settings/page";
import DashboardPage from "./pages/client/dashboard/page";
import AdminDashboardPage from "./pages/admin/dashboard/page";
import ForgotPasswordPage from "./pages/authentication/forgot-password/page";
import ResetPasswordPage from "./pages/authentication/forgot-password/reset-password/page";
import ResetPasswordSuccessPage from "./pages/authentication/forgot-password/reset-password/resetPasswordSuccess";
import { NotificationProvider } from "./utils/notification/notificationContext";
import { StatusNotification } from "./components/successNotification";
import BillingPage from "./pages/client/billing/page";
import HomePage from "./pages/website/home";
import ContactPage from "./pages/website/contact";
import OrderFulfillmentPage from "./pages/website/orderFulfillment";
import EcommerceManagementPage from "./pages/website/ecommerceManagement";
import InvoiceDataPage from "./pages/client/billing/invoice-data/page";
import ClientInvoiceDataPage from "./pages/admin/invoices/page";
import InboundUnitsPage from "./pages/client/inbound/units/page";
import InboundTrackingsPage from "./pages/client/inbound/trackings/page";
import InventoryUnitsPage from "./pages/client/inventory/units/page";
import InventoryTrackingsPage from "./pages/client/inventory/trackings/page";
import OutboundShipmentsPage from "./pages/client/outbound/shipments/page";
import OutboundRequestedPage from "./pages/client/outbound/requested/page";
import OutboundTrackingsPage from "./pages/client/outbound/trackings/page";
import AcceptInvitationPage from "./pages/client/team_invitation/accept/page";

function DashboardRoutes() {
    return (
        <BrowserRouter>
            <Routes>
                {/* CLIENT ROUTES Addition */}
                <Route
                    path="/"
                    element={
                        <PrivateRoute
                            element={DashboardPage}
                            allowedRoles={["client", "client_team_member"]}
                        />
                    }
                />
                <Route
                    path="/inbound/units"
                    element={
                        <PrivateRoute
                            element={InboundUnitsPage}
                            allowedRoles={["client", "client_team_member"]}
                        />
                    }
                />
                <Route
                    path="/inbound/trackings"
                    element={
                        <PrivateRoute
                            element={InboundTrackingsPage}
                            allowedRoles={["client", "client_team_member"]}
                        />
                    }
                />

                <Route
                    path="/inventory/units"
                    element={
                        <PrivateRoute
                            element={InventoryUnitsPage}
                            allowedRoles={["client", "client_team_member"]}
                        />
                    }
                />
                <Route
                    path="/inventory/trackings"
                    element={
                        <PrivateRoute
                            element={InventoryTrackingsPage}
                            allowedRoles={["client", "client_team_member"]}
                        />
                    }
                />

                <Route
                    path="/outbound/shipped"
                    element={
                        <PrivateRoute
                            element={OutboundShipmentsPage}
                            allowedRoles={["client", "client_team_member"]}
                        />
                    }
                />

                <Route
                    path="/outbound/requested"
                    element={
                        <PrivateRoute
                            element={OutboundRequestedPage}
                            allowedRoles={["client", "client_team_member"]}
                        />
                    }
                />

                <Route
                    path="/outbound/trackings"
                    element={
                        <PrivateRoute
                            element={OutboundTrackingsPage}
                            allowedRoles={["client", "client_team_member"]}
                        />
                    }
                />

                <Route
                    path="/account"
                    element={
                        <PrivateRoute
                            element={SettingsPage}
                            allowedRoles={["client", "client_team_member"]}
                        />
                    }
                />

                <Route
                    path="/billing"
                    element={
                        <PrivateRoute
                            element={BillingPage}
                            allowedRoles={["client", "client_team_member"]}
                        />
                    }
                />
                <Route
                    path="/billing/:invoice_id"
                    element={
                        <PrivateRoute
                            element={InvoiceDataPage}
                            allowedRoles={["client", "client_team_member"]}
                        />
                    }
                />

                {/* ADMIN ROUTES  */}
                <Route
                    path="/admin"
                    element={
                        <PrivateRoute
                            element={AdminDashboardPage}
                            allowedRoles={["admin"]}
                        />
                    }
                />
                <Route
                    path="/admin/clients"
                    element={
                        <PrivateRoute
                            element={ClientsPage}
                            allowedRoles={["admin"]}
                        />
                    }
                />
                <Route
                    path="/admin/clients/:user_id"
                    element={
                        <PrivateRoute
                            element={ClientDataPage}
                            allowedRoles={["admin"]}
                        />
                    }
                />
                <Route
                    path="/admin/clients/:user_id/invoices/:invoice_id"
                    element={
                        <PrivateRoute
                            element={ClientInvoiceDataPage}
                            allowedRoles={["admin"]}
                        />
                    }
                />

                {/* AUTHENTICATION ROUTES  */}
                <Route path="/login" element={<LoginPage />} />
                <Route path="/signup" element={<SignUpPage />} />
                <Route
                    path="/forgot-password"
                    element={<ForgotPasswordPage />}
                />
                <Route path="/reset-password" element={<ResetPasswordPage />} />
                <Route
                    path="/reset-password/success"
                    element={<ResetPasswordSuccessPage />}
                />

                <Route
                    path="/team/accept-invitation"
                    element={<AcceptInvitationPage />}
                />

                {/* ERROR ROUTES  */}
                <Route path="*" element={<PageNotFound />} />
                <Route path="/unauthorized" element={<UnauthorizedPage />} />
            </Routes>
        </BrowserRouter>
    );
}

function WebsiteRoutes() {
    return (
        <BrowserRouter>
            <Routes>
                {/* WEBSITE ROUTES */}
                <Route path="/" element={<HomePage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route
                    path="/order-fulfillment"
                    element={<OrderFulfillmentPage />}
                />
                <Route
                    path="/ecommerce-management"
                    element={<EcommerceManagementPage />}
                />

                {/* ERROR ROUTES  */}
                <Route path="*" element={<PageNotFound />} />
                <Route path="/unauthorized" element={<UnauthorizedPage />} />
            </Routes>
        </BrowserRouter>
    );
}

function App() {
    const isDashboardDomain =
        window.location.hostname === "dashboard.urvafreight.com" ||
        window.location.hostname === "localhost";

    return (
        <NotificationProvider>
            {isDashboardDomain ? <DashboardRoutes /> : <WebsiteRoutes />}
            <StatusNotification />
        </NotificationProvider>
    );
}

export default App;
