/** @format */

import { useEffect, useRef, useState } from "react";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import {
    PlusCircleIcon,
    TrashIcon,
    XCircleIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import { fetchClientOrders } from "../../../api/unitsApi";
import { UNSAFE_DataRouterStateContext, useParams } from "react-router-dom";
import LoadingWheel from "../../../../../../components/loadingWheel";
import ClientOutboundTable from "../client-orders-data/clientOutboundTable";
import Shipment, { ShipmentTracking } from "../../../../../../models/shipments";
import {
    convertToDayjsFromString,
    formatDateFromDb,
    formatDateFromObject,
} from "../../../../../../utils/date/date_formatter";
import { Order } from "../../../../../../models/order";
import {
    deleteShipment,
    updateRequestedShipmentStatus,
    updateRequestedShipmentStatusToPending,
    updateShipment,
} from "../../../api/shipmentsApi";
import { useNotification } from "../../../../../../utils/notification/notificationContext";
import ShipmentTrackingsTable from "../../../../../../components/tables/shipmentTrackings";
import { downloadShipmentPdf, getShipmentPdfUrl } from "../../../api/labelsApi";
import Results from "../../../../../../models/results";
import SingleCalendar from "../../../../../../components/singleCalendar";
import dayjs, { Dayjs } from "dayjs";
import { Box } from "../../../../../../models/adminDashboard";
import { fetchBoxes } from "../../../api/settingsApi";
import { formatMoney } from "../../../../../../utils/price/formatMoney";
import { LabelModal } from "../../../../../client/inventory/inventory-data/request-shipment/labelModal";
import { Label } from "../../../../../../models/label";

export default function OutboundOrdersModal({
    shipment,
    openOutboundOrders,
    setOpenOutboundOrders,
    handleFullRefetch,
    setShipments,
}: {
    shipment: Shipment | null;
    openOutboundOrders: boolean;
    setOpenOutboundOrders: React.Dispatch<React.SetStateAction<boolean>>;
    handleFullRefetch: () => void;
    setShipments?: React.Dispatch<React.SetStateAction<Shipment[]>>;
}) {
    const { user_id } = useParams();
    const { showNotification } = useNotification();
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [statusLoading, setStatusLoading] = useState(false);

    const [outboundOrders, setOutboundOrders] = useState<Order[]>([]);
    const [shipmentTrackings, setShipmentTrackings] = useState<
        ShipmentTracking[]
    >([]);
    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });
    const [offset, setOffset] = useState(0);

    const [downloadLoading, setDownloadLoading] = useState(false);

    const [openLabelModal, setOpenLabelModal] = useState(false);
    const [selectedViewingLabel, setSelectedViewingLabel] =
        useState<Label | null>(null);

    const updateOrderStatus = async () => {
        const data = await updateRequestedShipmentStatusToPending(
            shipment?.shipment_id
        );
        if (data.status === "success") {
            showNotification(
                "Shipment marked as pending",
                "Client can no longer unrequest this shipment.",
                "success"
            );
        } else {
            showNotification(
                "Failed to update to pending status",
                "Please leave and enter this shipment again.",
                "error"
            );
        }
    };

    const fetchData = async () => {
        const data = await fetchClientOrders(
            user_id,
            "outbound",
            shipment?.shipment_id,
            offset
        );
        if (data.status === "success") {
            setOutboundOrders(data.data.orders);
            setShipmentTrackings(data.data.trackings);
            setResults(data.data.results);
        }
        setLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        setEditedName(shipment?.name || "");
        setEditedDate(convertToDayjsFromString(shipment?.date || ""));
        setEditedNotes(shipment?.notes || "");
        setEditedBoxes(shipment?.boxes || []);
        setSelectedViewingLabel(null);
        if (openOutboundOrders || offset) {
            fetchData();
        }
        if (shipment && openOutboundOrders && setShipments) {
            if (
                shipment.admin_status !== "pending" &&
                shipment.admin_status !== "shipped" &&
                shipment.status === "requested"
            ) {
                updateOrderStatus();
                setShipments((prevShipments) =>
                    prevShipments.map((s) =>
                        s.shipment_id === shipment.shipment_id
                            ? { ...s, admin_status: "pending" }
                            : s
                    )
                );
            }
        }
        if (!openOutboundOrders) {
            setEditingShipment(false);
        }
    }, [openOutboundOrders, offset]);

    const handleDeleteShipment = async () => {
        setDeleteLoading(true);
        const data = await deleteShipment(shipment?.shipment_id);
        if (data.status === "success") {
            setOpenOutboundOrders(false);
            showNotification(
                "Deleted shipment",
                "Orders returned to inventory",
                "success"
            );
            handleFullRefetch();
        } else {
            showNotification(
                "Failed to deleted shipment",
                data.message,
                "error"
            );
        }
        setDeleteLoading(false);
    };

    const handleDownload = async () => {
        setDownloadLoading(true);
        const success = await downloadShipmentPdf(shipment?.shipment_id);
        if (success) {
        } else {
            showNotification("Failed to download pdf", "", "error");
        }
        setDownloadLoading(false);
    };

    const [viewingLabelLoading, setViewingLabelLoading] = useState(false);
    const handleViewShipmentPdf = async () => {
        if (!selectedViewingLabel) {
            setViewingLabelLoading(true);
            const pdfUrl = await getShipmentPdfUrl(shipment?.shipment_id);
            if (pdfUrl) {
                setSelectedViewingLabel({
                    label_id: "",
                    public_url: pdfUrl,
                    name: "",
                    used_pages: [],
                });
            }
            setViewingLabelLoading(false);
        } else {
            setOpenLabelModal(true);
        }
    };

    useEffect(() => {
        if (selectedViewingLabel) {
            setOpenLabelModal(true);
        }
    }, [selectedViewingLabel]);

    const handleToggleStatus = async () => {
        setStatusLoading(true);

        const addedBoxes = selectedBoxes.map((box) => ({
            quantity: quantities[box.box_id],
            name: box.name,
            price: box.price,
        }));

        const data = await updateRequestedShipmentStatus(
            shipment?.shipment_id,
            addedBoxes,
            deletedBoxes
        );
        if (data.status === "success") {
            showNotification("Updated status of shipment", "", "success");
            setOpenOutboundOrders(false);
            handleFullRefetch();
        } else {
            showNotification("Failed to update status", data.message, "error");
        }
        setStatusLoading(false);
    };

    const [editingShipment, setEditingShipment] = useState(false);
    const [editedName, setEditedName] = useState("");
    const [editedDate, setEditedDate] = useState<Dayjs>(dayjs());
    const [editedNotes, setEditedNotes] = useState("");
    const [editedBoxes, setEditedBoxes] = useState<Box[]>([]);
    const [deletedBoxes, setDeletedBoxes] = useState<string[]>([]);

    const [editLoading, setEditLoading] = useState(false);
    const handleUpdate = async () => {
        setEditLoading(true);

        const addedBoxes = selectedBoxes.map((box) => ({
            quantity: quantities[box.box_id],
            name: box.name,
            price: box.price,
        }));

        const data = await updateShipment(
            shipment?.shipment_id,
            editedName,
            formatDateFromObject(editedDate),
            editedNotes,
            deletedBoxes,
            addedBoxes
        );
        if (data.status === "success") {
            showNotification("Updated shipment", "", "success");
            setOpenOutboundOrders(false);
            handleFullRefetch();
        } else {
            showNotification(
                "Failed to update shipment",
                data.message,
                "error"
            );
        }
        setEditLoading(false);
    };

    useEffect(() => {
        setEditedName(shipment?.name || "");
        setEditedDate(convertToDayjsFromString(shipment?.date || ""));
        setEditedNotes(shipment?.notes || "");
        setEditedBoxes(shipment?.boxes || []);
        setQuantities({});
        setSelectedBoxes([]);
    }, [editingShipment]);

    const removeBoxByName = (nameToRemove: string) => {
        setEditedBoxes((prevBoxes) =>
            prevBoxes.filter((box) => box.name !== nameToRemove)
        );
        setDeletedBoxes((prevDeleted) => [...prevDeleted, nameToRemove]);
        console.log(deletedBoxes);
    };

    const [boxesLoading, setBoxesLoading] = useState(false);
    const [boxes, setBoxes] = useState<Box[]>([]);
    const [selectedBoxes, setSelectedBoxes] = useState<Box[]>([]);
    const [quantities, setQuantities] = useState<{ [key: string]: number }>({});

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const buttonRef = useRef<HTMLDivElement | null>(null);

    const toggleDropdown = () => {
        setIsOpen((prevState) => !prevState);
    };

    const handleBoxClick = (box: Box) => {
        if (!selectedBoxes.some((b) => b.box_id === box.box_id)) {
            setSelectedBoxes([...selectedBoxes, box]);
            setQuantities({
                ...quantities,
                [box.box_id]: 1, // Initialize the quantity for the selected box to 1
            });
            setIsOpen(false);
        }
    };

    const handleBoxQuantityChange = (boxId: string, quantity: number) => {
        if (quantity < 1) return; // Prevent quantity from being set to less than 1
        setQuantities({
            ...quantities,
            [boxId]: quantity,
        });
    };

    const handleRemoveBox = (boxId: string) => {
        // Remove box from the selectedBoxes array
        setSelectedBoxes(selectedBoxes.filter((box) => box.box_id !== boxId));
        const updatedQuantities = { ...quantities };
        delete updatedQuantities[boxId]; // Remove the quantity associated with the removed box
        setQuantities(updatedQuantities);
    };

    useEffect(() => {
        const fetchData = async () => {
            setBoxesLoading(true);
            const data = await fetchBoxes();
            if (data.status === "success") {
                setBoxes(data.data.boxes);
            } else {
                showNotification(
                    "Failed to fetch boxes",
                    data.message,
                    "error"
                );
            }
            setBoxesLoading(false);
        };
        fetchData();
    }, []);

    const availableBoxes = boxes.filter(
        (box) =>
            !selectedBoxes.some(
                (selectedBox) => selectedBox.box_id === box.box_id
            )
    );

    return (
        <Dialog
            open={openOutboundOrders}
            onClose={() => setOpenOutboundOrders(false)}
            className="relative z-10"
        >
            <DialogBackdrop
                transition
                className="overflow-y-visible fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen">
                <div className="h-[90%] flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-[90%] w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-7xl sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() => setOpenOutboundOrders(false)}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-5"
                        >
                            Shipment information
                        </DialogTitle>
                        <div className="w-full h-px bg-gray-200"></div>

                        <div className="pt-4"></div>

                        <div className="flex-1 mb-4 overflow-y-auto">
                            <dl className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 pb-4">
                                <div className="border-b border-gray-100 backdrop: py-4 sm:col-span-1 sm:px-0">
                                    <dt className="text-sm inline-flex items-center gap-x-2 font-medium leading-6 text-gray-900">
                                        Shipment Name
                                    </dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                                        {!editingShipment ? (
                                            <>{shipment?.name}</>
                                        ) : (
                                            <input
                                                value={editedName}
                                                onChange={(e) =>
                                                    setEditedName(
                                                        e.target.value
                                                    )
                                                }
                                                className="block w-44 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                            />
                                        )}
                                    </dd>
                                </div>
                                <div className="border-b border-gray-100 backdrop: py-4 sm:col-span-1 sm:px-0">
                                    <dt className="text-sm inline-flex items-center gap-x-2 font-medium leading-6 text-gray-900">
                                        Date
                                    </dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                                        {!editingShipment ? (
                                            <>
                                                {formatDateFromDb(
                                                    shipment?.date || ""
                                                )}
                                            </>
                                        ) : (
                                            <div className="w-44">
                                                <SingleCalendar
                                                    selectedDate={editedDate}
                                                    setSelectedDate={
                                                        setEditedDate
                                                    }
                                                    label={null}
                                                />
                                            </div>
                                        )}
                                    </dd>
                                </div>
                                <div className="border-b border-gray-100 backdrop: py-4 sm:col-span-1 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900">
                                        Notes
                                    </dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                                        {!editingShipment ? (
                                            <>{shipment?.notes}</>
                                        ) : (
                                            <input
                                                value={editedNotes}
                                                onChange={(e) =>
                                                    setEditedNotes(
                                                        e.target.value
                                                    )
                                                }
                                                className="block w-44 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                            />
                                        )}
                                    </dd>
                                </div>
                                {shipment ? (
                                    <div className="border-b border-gray-100 backdrop: py-4 sm:col-span-1 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">
                                            Boxes
                                        </dt>
                                        <>
                                            {editedBoxes &&
                                                editedBoxes.map((box) => (
                                                    <dd className="mt-0.5 text-sm leading-6 text-gray-700 sm:mt-0.5 flex flex-row items-center justify-between">
                                                        <div>
                                                            {box.name}
                                                            {": "}
                                                            {box.quantity}
                                                        </div>
                                                        <div>
                                                            <TrashIcon
                                                                onClick={() =>
                                                                    removeBoxByName(
                                                                        box.name
                                                                    )
                                                                }
                                                                className="w-3 h-3 text-red-600 hover:text-red-500"
                                                            />
                                                        </div>
                                                    </dd>
                                                ))}
                                            <div className="flex flex-col items-start space-x-0 space-y-2">
                                                <div className="w-full">
                                                    <div className="flex flex-col space-y-3 w-full">
                                                        {selectedBoxes.length >
                                                            0 &&
                                                            selectedBoxes.map(
                                                                (box) => (
                                                                    <div
                                                                        key={
                                                                            box.box_id
                                                                        }
                                                                        className="flex items-center space-x-2"
                                                                    >
                                                                        <div className="flex flex-col text-sm justify-between w-full">
                                                                            <span className="font-medium">
                                                                                {
                                                                                    box.name
                                                                                }
                                                                            </span>
                                                                            <span className="text-gray-700">
                                                                                {formatMoney(
                                                                                    box.price
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                        <div className="flex flex-row items-center space-x-2">
                                                                            <input
                                                                                type="number"
                                                                                className="block w-32 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                                                                min="1"
                                                                                value={
                                                                                    quantities[
                                                                                        box
                                                                                            .box_id
                                                                                    ]
                                                                                }
                                                                                onChange={(
                                                                                    e
                                                                                ) =>
                                                                                    handleBoxQuantityChange(
                                                                                        box.box_id,
                                                                                        parseInt(
                                                                                            e
                                                                                                .target
                                                                                                .value
                                                                                        )
                                                                                    )
                                                                                }
                                                                            />
                                                                            <TrashIcon
                                                                                onClick={() =>
                                                                                    handleRemoveBox(
                                                                                        box.box_id
                                                                                    )
                                                                                }
                                                                                className="w-5 h-5 text-red-600 hover:text-red-500 cursor-pointer"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="relative inline-block text-left whitespace-nowrap">
                                                    {/* Button to toggle the dropdown */}
                                                    <div
                                                        ref={buttonRef}
                                                        onClick={toggleDropdown}
                                                        className="flex items-center cursor-pointer"
                                                    >
                                                        <span className="font-medium text-sm">
                                                            Add Boxes
                                                        </span>
                                                        <PlusCircleIcon className="w-6 h-6 text-accent cursor-pointer hover:text-accenthighlight ml-2" />
                                                    </div>
                                                    {isOpen && (
                                                        <div
                                                            ref={dropdownRef}
                                                            className="absolute max-h-64 overflow-auto pt-1 left-0 w-48 bg-white border border-gray-300 shadow-lg rounded-md z-50"
                                                        >
                                                            {availableBoxes.map(
                                                                (box) => (
                                                                    <div
                                                                        key={
                                                                            box.box_id
                                                                        }
                                                                        onClick={() =>
                                                                            handleBoxClick(
                                                                                box
                                                                            )
                                                                        }
                                                                        className="text-sm flex flex-col py-2 space-y-0.5 pl-2 hover:bg-gray-100 transition-all cursor-pointer"
                                                                    >
                                                                        <span className="font-medium">
                                                                            {
                                                                                box.name
                                                                            }
                                                                        </span>
                                                                        <span className="text-gray-700">
                                                                            {formatMoney(
                                                                                box.price
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                        {/* )} */}
                                    </div>
                                ) : null}
                                {shipment?.status === "requested" && (
                                    <div className="border-b border-gray-100 backdrop: py-4 sm:col-span-1 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">
                                            Attached labels
                                        </dt>
                                        <dd className="mt-1 text-sm leading-6 sm:mt-1 text-accent cursor-pointer">
                                            <div className="flex flex-row space-x-4">
                                                {downloadLoading ? (
                                                    <LoadingWheel size="small" />
                                                ) : (
                                                    <span
                                                        onClick={() =>
                                                            handleDownload()
                                                        }
                                                    >
                                                        Download
                                                    </span>
                                                )}
                                                {viewingLabelLoading ? (
                                                    <LoadingWheel size="small" />
                                                ) : (
                                                    <span
                                                        onClick={() =>
                                                            handleViewShipmentPdf()
                                                        }
                                                    >
                                                        View
                                                    </span>
                                                )}
                                            </div>
                                        </dd>
                                    </div>
                                )}
                            </dl>
                            <span className="font-semibold text-base text-gray-900">
                                Shipment contents
                            </span>
                            <div className="pb-3"></div>
                            {!loading ? (
                                <>
                                    <ClientOutboundTable
                                        outboundOrders={outboundOrders}
                                        results={results}
                                        offset={offset}
                                        setOffset={setOffset}
                                    />
                                    {shipment?.status !== "requested" && (
                                        <>
                                            <div className="py-2">
                                                <div className="h-px w-full bg-gray-300"></div>
                                            </div>
                                            {shipmentTrackings &&
                                            shipmentTrackings.length > 0 ? (
                                                <ShipmentTrackingsTable
                                                    trackings={
                                                        shipmentTrackings
                                                    }
                                                />
                                            ) : (
                                                <span className="text-sm text-gray-600">
                                                    No trackings on this
                                                    shipment
                                                </span>
                                            )}
                                        </>
                                    )}
                                </>
                            ) : (
                                <div className="flex flex-grow items-center pt-44 justify-center">
                                    <LoadingWheel />
                                </div>
                            )}
                        </div>

                        {/* Fixed buttons at the bottom */}
                        <div className="pt-4 sm:pt-0 sm:flex justify-between">
                            <div className="flex flex-row items-center space-x-4">
                                <button
                                    onClick={() => handleDeleteShipment()}
                                    type="button"
                                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto items-center"
                                >
                                    {deleteLoading ? (
                                        <span className="pr-2 items-center flex flex-row justify-center">
                                            <LoadingWheel
                                                size="small"
                                                color="white"
                                            />
                                        </span>
                                    ) : (
                                        <></>
                                    )}
                                    Delete
                                </button>
                                {editingShipment ? (
                                    <button
                                        onClick={() => handleUpdate()}
                                        type="button"
                                        className="flex flex-row w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:mt-0 sm:w-auto items-center"
                                    >
                                        {editLoading ? (
                                            <span className="pr-2 items-center flex flex-row justify-center">
                                                <LoadingWheel
                                                    color="white"
                                                    size="small"
                                                />
                                            </span>
                                        ) : (
                                            <></>
                                        )}
                                        Save
                                    </button>
                                ) : (
                                    <button
                                        onClick={() =>
                                            setEditingShipment(!editingShipment)
                                        }
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto items-center"
                                    >
                                        {!editingShipment
                                            ? "Edit"
                                            : "Cancel editing"}
                                    </button>
                                )}
                            </div>
                            <div className="sm:space-x-3 flex flex-row">
                                {/* <button
                                    onClick={() =>
                                        setEditingShipment(!editingShipment)
                                    }
                                    type="button"
                                    className="inline-flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto items-center"
                                >
                                    {!editingShipment
                                        ? "Edit"
                                        : "Cancel editing"}
                                </button> */}
                                {shipment?.status === "requested" ? (
                                    <>
                                        {!shipment?.admin_status ||
                                        shipment?.admin_status === "pending" ? (
                                            <button
                                                onClick={() =>
                                                    handleToggleStatus()
                                                }
                                                type="button"
                                                className="inline-flex w-full justify-center rounded-md bg-accent px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-accenthighlight sm:ml-3 sm:w-auto items-center"
                                            >
                                                {statusLoading ? (
                                                    <span className="pr-2 items-center flex flex-row justify-center">
                                                        <LoadingWheel
                                                            size="small"
                                                            color="white"
                                                        />
                                                    </span>
                                                ) : (
                                                    <></>
                                                )}
                                                Mark shipped
                                            </button>
                                        ) : (
                                            <button
                                                onClick={() =>
                                                    handleToggleStatus()
                                                }
                                                type="button"
                                                className="inline-flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto items-center"
                                            >
                                                {statusLoading ? (
                                                    <span className="pr-2 items-center flex flex-row justify-center">
                                                        <LoadingWheel size="small" />
                                                    </span>
                                                ) : (
                                                    <></>
                                                )}
                                                Mark unshipped
                                            </button>
                                        )}
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </DialogPanel>
                </div>
            </div>
            <LabelModal
                selectedViewingLabel={selectedViewingLabel}
                openLabelModal={openLabelModal}
                setOpenLabelModal={setOpenLabelModal}
                selectedPages={[]}
            />
        </Dialog>
    );
}
