/** @format */

import { useState } from "react";
import { InputField } from "../../../../components/inputField";
import LoadingWheel from "../../../../components/loadingWheel";
import { TrashIcon } from "@heroicons/react/24/outline";
import { addNewBox, deleteBox } from "../api/settingsApi";
import { useNotification } from "../../../../utils/notification/notificationContext";
import { concat } from "lodash";
import { Box } from "../../../../models/adminDashboard";
import { formatMoney } from "../../../../utils/price/formatMoney";

function ManageBoxes({
    boxes,
    handleRefetch,
}: {
    boxes: Box[];
    handleRefetch: () => void;
}) {
    const { showNotification } = useNotification();
    const [loading, setLoading] = useState(false);
    const [deleteLoadings, setDeleteLoadings] = useState<string[]>([]);

    const [name, setName] = useState("");
    const [price, setPrice] = useState("");

    const handleAdd = async () => {
        setLoading(true);
        const data = await addNewBox(name, price);
        if (data.status === "success") {
            showNotification("Added new box", "", "success");
            handleRefetch();
        } else {
            showNotification("Failed to add new box", data.message, "error");
        }
        setLoading(false);
    };

    const handleDelete = async (box_id: string) => {
        setDeleteLoadings((prev) => concat(prev, [box_id]));
        const data = await deleteBox(box_id);
        if (data.status === "success") {
            showNotification("Deleted box", "", "success");
            handleRefetch();
        } else {
            showNotification("Failed to delete box", data.message, "error");
        }
        setDeleteLoadings((prev) => prev.filter((box_id) => box_id !== box_id));
    };

    return (
        <div className="bg-white border border-gray-200 shadow-sm sm:rounded-lg lg:w-3/5">
            <div className="px-4 py-5 sm:p-6">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                    Manage boxes
                </h3>
                <div className="flex flex-col lg:flex-row lg:items-end lg:space-x-4 pt-3">
                    <div className="flex flex-row items-center space-x-4 w-full">
                        <InputField
                            label="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder=""
                        />
                        <InputField
                            label="Price"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            placeholder=""
                        />
                    </div>
                    <button
                        disabled={loading}
                        onClick={() => handleAdd()}
                        className="mt-3 lg:w-44 w-full items-center justify-center rounded-md bg-accent px-3 h-9 text-sm font-semibold text-white shadow-sm hover:bg-accenthighlight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
                    >
                        {loading ? (
                            <LoadingWheel color="white" size="small" />
                        ) : (
                            <>Add box</>
                        )}
                    </button>
                </div>
                <h3 className="text-sm font-semibold leading-6 text-gray-900 pt-5">
                    Manage boxes
                </h3>

                <div className="grid grid-cols-2 gap-x-6 gap-y-2">
                    {boxes.map((box) => (
                        <div className="flex flex-row justify-between items-center">
                            <div className="flex flex-col">
                                <span>{box.name}</span>
                                <span className="text-gray-600 text-sm">
                                    {formatMoney(box.price)}
                                </span>
                            </div>
                            {deleteLoadings.includes(box.box_id) ? (
                                <LoadingWheel size="small" />
                            ) : (
                                <TrashIcon
                                    onClick={() => handleDelete(box.box_id)}
                                    className="w-5 h-5 text-red-500 hover:text-red-400"
                                />
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
export default ManageBoxes;
