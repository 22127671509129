/** @format */

import { useEffect, useState } from "react";
import { InputField } from "../../../../components/inputField";
import LoadingWheel from "../../../../components/loadingWheel";
import { updateAddress } from "../api/settingsApi";
import { useNotification } from "../../../../utils/notification/notificationContext";
import { WarehouseAddress } from "../../../../models/adminDashboard";

interface ManageAddressProps {
    warehouseAddress: WarehouseAddress | null;
    handleRefetch: () => void;
}

function ManageAddress({
    warehouseAddress,
    handleRefetch,
}: ManageAddressProps) {
    const { showNotification } = useNotification();
    const [loading, setLoading] = useState(false);

    const [street1, setStreet1] = useState("");
    const [street2, setStreet2] = useState("");
    const [city, setCity] = useState("");
    const [zip, setZip] = useState("");
    const [state, setState] = useState("");

    const handleUpdate = async () => {
        setLoading(true);
        const data = await updateAddress(street1, street2, city, zip, state);
        if (data.status === "success") {
            showNotification("Updated address", "", "success");
            handleRefetch();
        } else {
            showNotification("Failed to update address", data.message, "error");
        }
        setLoading(false);
    };

    useEffect(() => {
        setStreet1(warehouseAddress?.street1 || "");
        setStreet2(warehouseAddress?.street2 || "");
        setCity(warehouseAddress?.city || "");
        setZip(warehouseAddress?.zip || "");
        setState(warehouseAddress?.state || "");
    }, [warehouseAddress]);

    return (
        <div className="bg-white border border-gray-200 shadow-sm sm:rounded-lg w-96">
            <div className="px-4 py-5 sm:p-6">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                    Update warehouse address
                </h3>
                <div className="mt-2 max-w-xl text-sm text-gray-500">
                    <p>Address will be displayed on user dashboard.</p>
                </div>
                <div className="space-y-4 pt-3">
                    <div className="flex flex-row items-center space-x-4">
                        <InputField
                            label="Street 1"
                            value={street1}
                            onChange={(e) => setStreet1(e.target.value)}
                            placeholder=""
                        />
                        <InputField
                            label="Street 2"
                            value={street2}
                            onChange={(e) => setStreet2(e.target.value)}
                            placeholder=""
                        />
                    </div>
                    <div className="flex flex-row items-center space-x-4">
                        <InputField
                            label="City"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            placeholder=""
                        />
                        <InputField
                            label="Zip"
                            value={zip}
                            onChange={(e) => setZip(e.target.value)}
                            placeholder=""
                        />
                    </div>
                    <div className="flex flex-row items-center space-x-4">
                        <InputField
                            label="State"
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                            placeholder=""
                        />
                    </div>
                    <button
                        onClick={() => handleUpdate()}
                        className="mt-3 w-full items-center justify-center rounded-md bg-accent px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accenthighlight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
                    >
                        {loading ? (
                            <LoadingWheel color="white" size="small" />
                        ) : (
                            <>Save</>
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ManageAddress;
