/** @format */
import { useEffect, useState } from "react";
import { PaymentStatusTag } from "../../../../../components/statusTags";
import { useParams } from "react-router-dom";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { Invoice } from "../../../../../models/invoices";
import Results from "../../../../../models/results";
import { fetchInvoices } from "../../api/billingApi";
import { formatDateFromDb } from "../../../../../utils/date/date_formatter";
import { formatMoney } from "../../../../../utils/price/formatMoney";
import Shipment from "../../../../../models/shipments";
import ClientShipmentsTable from "../shipments/client-orders-data/clientShipmentsTable";
import LoadingWheel from "../../../../../components/loadingWheel";
import CloseOpenInvoiceModal from "./modals/closeOpenInvoiceModal";
import AdditionalCostsModal from "./modals/additionalCostsModal";
import EditPastInvoiceModal from "./modals/editPastInvoiceModal";
import ClientPastInvoicesTable from "./pastInvoicesTable";
import DiscountsModal from "./modals/discountsModal";

function OpenInvoice({
    openInvoice,
    openShipments,
}: {
    openInvoice: Invoice | null;
    openShipments: Shipment[];
}) {
    const tableHeaders = [
        {
            header: "Payment Status",
            value: <PaymentStatusTag status={openInvoice?.status || ""} />,
        },
        { header: "Invoice date", value: "None" },
        {
            header: "Invoice period",
            value:
                formatDateFromDb(openInvoice?.start_date || "") +
                " - " +
                getDate(),
        },
        { header: "Total saved", value: formatMoney(openInvoice?.total_saved) },
        { header: "Current total", value: formatMoney(openInvoice?.total) },
    ];

    const [showInvoice, setShowInvoice] = useState(false);

    function getDate() {
        const today = new Date();
        const month = String(today.getMonth() + 1).padStart(2, "0");
        const date = String(today.getDate()).padStart(2, "0");
        const year = today.getFullYear();
        return `${month}/${date}/${year}`;
    }

    return (
        <div className="flex flex-col pt-3">
            <div className="w-full h-px bg-gray-200"></div>
            <div className="flex flex-col-reverse sm:flex-row justify-between sm:items-center pt-7 pb-7">
                <div className="flex flex-row items-center text-sm">
                    <button
                        onClick={() => setShowInvoice(!showInvoice)}
                        className="pr-6"
                    >
                        {!showInvoice ? (
                            <ChevronDownIcon className="h-5 w-5 hover:text-accent" />
                        ) : (
                            <ChevronUpIcon className="h-5 w-5 hover:text-accent" />
                        )}
                    </button>
                    {tableHeaders.map((item) => (
                        <div className="flex flex-col space-y-3 min-w-52">
                            <span className="text-xs font-bold">
                                {item.header}
                            </span>
                            <span className="h-8">{item.value}</span>
                        </div>
                    ))}
                </div>
                <div className="flex flex-row items-center space-x-10">
                    <button
                        onClick={() => setShowInvoice(!showInvoice)}
                        className="text-sm font-semibold text-gray-600"
                    >
                        View
                    </button>
                </div>
            </div>
            {showInvoice ? (
                <ClientShipmentsTable shipments={openShipments} />
            ) : (
                <></>
            )}

            <div className="w-full h-px bg-gray-200"></div>
        </div>
    );
}

function AccountBilling() {
    const { user_id } = useParams();
    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [loading, setLoading] = useState(true);

    const [openInvoice, setOpenInvoice] = useState<Invoice | null>(null);
    const [openShipments, setOpenShipments] = useState<Shipment[]>([]);

    const [pastInvoices, setPastInvoices] = useState<Invoice[]>([]);

    const [openViewOpenInvoiceModal, setOpenViewOpenInvoiceModal] =
        useState(false);

    const [openAdditionalCostsModal, setOpenAdditionalCostsModal] =
        useState(false);
    const [openDiscountsModal, setOpenDiscountsModal] = useState(false);

    const [openEditPastInvoiceModal, setOpenEditPastInvoiceModal] =
        useState(false);
    const [selectedPastInvoice, setSelectedPastInvoice] =
        useState<Invoice | null>(null);

    const [refetchData, setRefetchData] = useState(0);
    const handleRefetch = () => {
        setRefetchData((prevValue) => prevValue + 1);
    };

    useEffect(() => {
        if (selectedPastInvoice) {
            setOpenEditPastInvoiceModal(true);
        }
    }, [selectedPastInvoice]);

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const data = await fetchInvoices(user_id);
            if (data.status === "success") {
                setOpenInvoice(data.data.open_invoice);
                setPastInvoices(data.data.past_invoices);
                setOpenShipments(data.data.open_shipments);
            }
            setLoading(false);
        };
        fetchData();
    }, [refetchData]);

    return (
        <div className="flex flex-col">
            {!loading ? (
                <div>
                    {openInvoice ? (
                        <>
                            <div className="pt-10"></div>
                            <div className="flex flex-row justify-between">
                                <span className="text-lg font-semibold">
                                    Current Invoice Tab
                                </span>
                                <div className="space-x-4 flex flex-row">
                                    <button
                                        onClick={() =>
                                            setOpenDiscountsModal(true)
                                        }
                                        className="flex flex-row text-sm items-center border border-gray-300 hover:border-gray-300 rounded-md px-2 h-8 hover:bg-gray-50"
                                    >
                                        Manage discounts
                                    </button>
                                    <button
                                        onClick={() =>
                                            setOpenAdditionalCostsModal(true)
                                        }
                                        className="flex flex-row text-sm items-center border border-gray-300 hover:border-gray-300 rounded-md px-2 h-8 hover:bg-gray-50"
                                    >
                                        Manage additional costs
                                    </button>
                                    <button
                                        onClick={() =>
                                            setOpenViewOpenInvoiceModal(true)
                                        }
                                        className="bg-accent text-white font-semibold text-sm px-3 h-8 rounded-md hover:bg-accenthighlight"
                                    >
                                        End period
                                    </button>
                                </div>
                            </div>

                            <OpenInvoice
                                openInvoice={openInvoice}
                                openShipments={openShipments}
                            />
                        </>
                    ) : (
                        <></>
                    )}

                    <div className="pt-10"></div>
                    <span className="text-lg font-semibold pb-4">
                        Invoice History
                    </span>
                    <div className="pt-2">
                        <ClientPastInvoicesTable
                            invoices={pastInvoices}
                            results={results}
                            setSelectedPastInvoice={setSelectedPastInvoice}
                        />
                    </div>
                </div>
            ) : (
                <div className="flex flex-grow items-center h-screen justify-center pb-64">
                    <LoadingWheel />
                </div>
            )}

            <CloseOpenInvoiceModal
                openViewOpenInvoiceModal={openViewOpenInvoiceModal}
                setOpenViewOpenInvoiceModal={setOpenViewOpenInvoiceModal}
                invoice_id={openInvoice?.invoice_id}
                handleRefetch={handleRefetch}
            />

            <AdditionalCostsModal
                openAdditionalCostsModal={openAdditionalCostsModal}
                setOpenAdditionalCostsModal={setOpenAdditionalCostsModal}
                invoice_id={openInvoice?.invoice_id}
                handleRefetch={handleRefetch}
            />

            <DiscountsModal
                openDiscountsModal={openDiscountsModal}
                setOpenDiscountsModal={setOpenDiscountsModal}
                invoice_id={openInvoice?.invoice_id}
                handleRefetch={handleRefetch}
            />

            <EditPastInvoiceModal
                openEditPastInvoiceModal={openEditPastInvoiceModal}
                setOpenEditPastInvoiceModal={setOpenEditPastInvoiceModal}
                selectedPastInvoice={selectedPastInvoice}
                setSelectedPastInvoice={setSelectedPastInvoice}
                handleRefetch={handleRefetch}
            />
        </div>
    );
}

export default AccountBilling;
