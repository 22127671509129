/** @format */

import { useSearchParams } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import LoadingWheel, { SortLoading } from "../../../../components/loadingWheel";
import Results from "../../../../models/results";
import {
    calculateOffset,
    getOffset,
} from "../../../../utils/pagination/calculateOffset";
import { EditOrderModal } from "../inbound-data/editOrderModal";
import { EmptyInboundTable } from "../../../../components/emptyStates";
import { StatusTag } from "../../../../components/statusTags";
import { formatDateFromDb } from "../../../../utils/date/date_formatter";
import { formatMoney } from "../../../../utils/price/formatMoney";
import { PaginationLink } from "../../../../utils/pagination/paginationLink";
import { PaginationResults } from "../../../../utils/pagination/paginationResults";
import { useCheckInDatesModal } from "../../../../hooks/useCheckInDates";
import CheckInDatesModal from "../../../../components/modals/checkInDatesModal";
import { useTrackingNumbersModal } from "../../../../hooks/useTrackingNumbers";
import TrackingNumbersModal from "../../../../components/modals/trackingNumbersModal";
import { Order } from "../../../../models/order";
import { capitalizeFirstLetter } from "../../../../utils/strings/string";
import LimitSelector from "../../../../utils/pagination/limitSelector";
import useScrollTranslation from "../../../../hooks/useScrollTranslation";
import ColumnSort from "../../../../utils/sort/columnSort";
import useCellSelection from "../../../../hooks/useCellSelection";
import { useSort } from "../../../../hooks/useSort";
import { fetchInboundOrders } from "../api/inboundApi";
import { useNotification } from "../../../../utils/notification/notificationContext";
import { PlusIcon } from "@heroicons/react/24/outline";
import { MdOutlineFileUpload } from "react-icons/md";
import ClientSideBar from "../../../../components/client/sideBar";
import Header from "../../../../components/header";
import { AddBulkOrderModal, AddOrderModal } from "../addOrderModal";
import ClientSearchBar from "../../../../components/client/searchBar";
import DeleteOrderModal from "../inbound-data/deleteOrderModal";
import { BulkEditOrderModal } from "../inbound-data/bulkEditOrderModal";

const tableHeaders = [
    { key: "status", label: "Status", className: "sm:min-w-32 min-w-32" },
    {
        key: "fulfilled_by",
        label: "Fulfilled by",
        className: "sm:min-w-32 min-w-32",
    },
    { key: "created_at", label: "Date", className: "sm:min-w-32 min-w-28" },
    { key: "retailer", label: "Retailer", className: "min-w-32" },
    { key: "order_number", label: "Order #", className: "min-w-32" },
    {
        key: "item_name",
        label: "Item Name",
        className: "sm:min-w-48 min-w-48",
    },
    {
        key: "brand_sku",
        label: "Brand SKU",
        className: "sm:min-w-32 min-w-28",
    },
    { key: "size", label: "Size", className: "sm:min-w-32 min-w-28" },
    {
        key: "quantity",
        label: "Quantity",
        className: "sm:min-w-32 min-w-28",
    },
    { key: "color", label: "Color", className: "sm:min-w-32 min-w-28" },
    { key: "asin", label: "ASIN", className: "sm:min-w-32 min-w-28" },
    {
        key: "amazon_sku",
        label: "Amazon SKU",
        className: "sm:min-w-32 min-w-28",
    },
    {
        key: "unit_cost",
        label: "Unit cost",
        className: "sm:min-w-32 min-w-28",
    },
    {
        key: "list_price",
        label: "List price",
        className: "sm:min-w-32 min-w-28",
    },
    {
        key: "trackings",
        label: "Trackings",
        className: "sm:min-w-32 min-w-28",
    },
    {
        key: "shipment_value",
        label: "Shipment value",
        className: "sm:min-w-32 min-w-28",
    },
    { key: "notes", label: "Notes", className: "sm:min-w-32 min-w-28" },
];

export default function InboundUnitsPage() {
    const [searchParams] = useSearchParams();
    const { showNotification } = useNotification();

    const [selectedOrders, setSelectedOrders] = useState<Order[]>([]);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const [openEditModal, setOpenEditModal] = useState(false);
    const [openBulkEditModal, setOpenBulkEditModal] = useState(false);

    const [selectedInboundOrder, setSelectedInboundOrder] =
        useState<Order | null>(null);

    useEffect(() => {
        if (selectedInboundOrder) {
            setOpenEditModal(true);
        }
    }, [selectedInboundOrder]);

    useEffect(() => {
        if (!openEditModal) {
            setSelectedInboundOrder(null);
        }
    }, [openEditModal]);

    const handleCheckboxChange = (order: Order) => {
        const orderId = order.order_id;
        if (selectedOrders && setSelectedOrders) {
            if (selectedOrders.some((o) => o.order_id === orderId)) {
                // Remove the order by its id
                setSelectedOrders(
                    selectedOrders.filter((o) => o.order_id !== orderId)
                );
            } else {
                // Add the order using its id
                setSelectedOrders([...selectedOrders, order]);
            }
        }
    };

    const {
        openCheckInDatesModal,
        setOpenCheckInDatesModal,
        selectedOrder,
        handleViewDates,
    } = useCheckInDatesModal();

    const {
        openTrackingNumbersModal,
        setOpenTrackingNumbersModal,
        selectedTrackingNumbers,
        handleViewTrackings,
    } = useTrackingNumbersModal();

    const { scrollContainerRef, scrollOffset } = useScrollTranslation();

    const getCellValue = (
        rowIndex: number,
        columnIndex: number,
        data: any[]
    ) => {
        const order = data[rowIndex];
        switch (columnIndex) {
            case 8:
                return order.quantity;
            case 12:
                return order.unit_cost;
            case 13:
                return order.list_price;
            case 15:
                return order.shipment_value;
            default:
                return 0;
        }
    };

    const {
        selectedCells,
        handleSingleClick,
        handleMouseDown,
        handleMouseEnter,
        handleMouseUp,
        sumSelectedQuantities,
    } = useCellSelection([], getCellValue, [8, 12, 13, 15]);

    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [openAddOrderModal, setOpenAddOrderModal] = useState(false);
    const [openAddBulkOrderModal, setOpenAddBulkOrderModal] = useState(false);

    const [dataLoading, setDataLoading] = useState(false);
    const [inboundOrders, setInboundOrders] = useState<Order[]>([]);
    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [refetchData, setRefetchData] = useState<number>(0);
    const handleRefetch = () => {
        setRefetchData((prevValue) => prevValue + 1);
    };

    const hasFetchedOrders = useRef(false);
    const fetchData = async () => {
        const data = await fetchInboundOrders(searchParams, sortConfig);
        if (data.status === "success") {
            setInboundOrders(data.data.inbound);
            setResults(data.data.results);
        } else {
            showNotification("Failed fetching data", data.message, "error");
        }
        setDataLoading(false);
        setSortLoading(false);
        hasFetchedOrders.current = true;
    };

    useEffect(() => {
        if (!hasFetchedOrders.current || refetchData != 0) {
            setDataLoading(true);
            fetchData();
        }
    }, [refetchData]);

    useEffect(() => {
        if (hasFetchedOrders.current) {
            setSortLoading(true);
            fetchData();
        }
    }, [sortConfig]);

    return (
        <div className="flex flex-row">
            <ClientSideBar />
            <div className="flex flex-col flex-grow overflow-x-auto pb-4">
                <div className="sm:px-10 px-4">
                    <Header />
                </div>
                <div className="mx-4 sm:mx-10 pt-10 flex flex-col flex-grow overflow-x-auto">
                    <div className="flex flex-col sm:flex-row justify-between sm:items-center">
                        <span className="text-2xl font-bold">Inbound</span>
                        <div className="flex flex-row items-center space-x-4 pt-4 sm:pt-0">
                            <button
                                onClick={() => setOpenAddOrderModal(true)}
                                className="bg-accent flex flex-row items-center text-white font-medium px-5 text-sm rounded-md h-8 hover:bg-accenthighlight"
                            >
                                <PlusIcon className="w-5 h-5" />
                                <span className="pl-2 text-sm">
                                    New Inbound Shipment
                                </span>
                            </button>
                            <button
                                onClick={() => setOpenAddBulkOrderModal(true)}
                                className="flex flex-row items-center border border-gray-300 hover:border-gray-300 rounded-md px-2 h-8 hover:bg-gray-50"
                            >
                                <MdOutlineFileUpload />
                                <span className="pl-2 text-sm">Upload CSV</span>
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-row items-center py-4 space-x-4">
                        <ClientSearchBar
                            setData={setInboundOrders}
                            setLoading={setDataLoading}
                            handleRefetch={handleRefetch}
                            page="inbound"
                            sub_page="units"
                        />
                        <button
                            onClick={() => setOpenBulkEditModal(true)}
                            disabled={selectedOrders.length < 1}
                            className={`flex h-8 flex-row items-center text-xs px-4 shadow-sm rounded-md text-black ${
                                selectedOrders.length > 0
                                    ? "hover:bg-gray-50 bg-white border border-gray-400"
                                    : "bg-gray-50 border border-gray-200"
                            }`}
                        >
                            Edit
                        </button>
                        <button
                            disabled={selectedOrders.length < 1}
                            onClick={() => setOpenDeleteModal(true)}
                            className={`rounded-md text-sm text-white px-4 h-8 ${
                                selectedOrders.length > 0
                                    ? "hover:bg-red-500 bg-red-600"
                                    : "bg-red-300"
                            }`}
                        >
                            Delete
                        </button>
                    </div>
                    {!dataLoading ? (
                        <div>
                            {inboundOrders && inboundOrders.length > 0 ? (
                                <>
                                    <div
                                        ref={scrollContainerRef}
                                        className={`max-h-[650px] overflow-y-auto overflow-x-scroll relative scrollbar ${
                                            sortLoading
                                                ? "pointer-events-none overflow-hidden"
                                                : ""
                                        }`}
                                    >
                                        <SortLoading
                                            sortLoading={sortLoading}
                                            scrollOffset={scrollOffset}
                                        />
                                        <table
                                            onMouseUp={handleMouseUp}
                                            className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full"
                                        >
                                            <thead className="sticky top-0 bg-white z-10">
                                                <tr className="text-left text-sm">
                                                    <th
                                                        key={"checkbox"}
                                                        className={`py-2 min-w-10`}
                                                    ></th>
                                                    {tableHeaders.map(
                                                        (header) => (
                                                            <th
                                                                onClick={() =>
                                                                    toggleSort(
                                                                        header.key
                                                                    )
                                                                }
                                                                key={header.key}
                                                                className={`group hover:bg-gray-100 py-2 cursor-pointer ${header.className}`}
                                                            >
                                                                {header.label}{" "}
                                                                <ColumnSort
                                                                    header={
                                                                        header
                                                                    }
                                                                    sortConfig={
                                                                        sortConfig
                                                                    }
                                                                />
                                                            </th>
                                                        )
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody className="relative">
                                                {inboundOrders.map(
                                                    (order, rowIndex) => (
                                                        <tr
                                                            key={order.order_id}
                                                            onClick={() =>
                                                                setSelectedInboundOrder(
                                                                    order
                                                                )
                                                            }
                                                            className="relative border-t border-gray-200 text-sm text-gray-500 hover:bg-gray-100 cursor-pointer"
                                                        >
                                                            <td className="py-3 flex items-center">
                                                                <input
                                                                    type="checkbox"
                                                                    className="h-4 w-4 rounded border-gray-300 text-accent focus:ring-0 focus:ring-transparent ring-0 focus:ring-offset-0 focus:outline transition-all"
                                                                    onChange={() =>
                                                                        handleCheckboxChange(
                                                                            order
                                                                        )
                                                                    }
                                                                    checked={
                                                                        selectedOrders &&
                                                                        selectedOrders.some(
                                                                            (
                                                                                o
                                                                            ) =>
                                                                                o.order_id ===
                                                                                order.order_id
                                                                        )
                                                                    }
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        e.stopPropagation()
                                                                    }
                                                                />
                                                            </td>
                                                            <td className="py-2.5 pr-4 whitespace-nowrap">
                                                                <StatusTag
                                                                    status={
                                                                        order.unit_status
                                                                    }
                                                                    quantity_received={
                                                                        order.quantity_received
                                                                    }
                                                                    quantity={
                                                                        order.quantity
                                                                    }
                                                                    handleClick={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                        handleViewDates(
                                                                            order
                                                                        );
                                                                    }}
                                                                />
                                                            </td>
                                                            <td className="">
                                                                {sortLoading ? (
                                                                    <div className="w-full h-4 bg-gray-300 shimmer animate-shimmer rounded mb-2"></div>
                                                                ) : (
                                                                    <>
                                                                        {capitalizeFirstLetter(
                                                                            order.fulfilled_by
                                                                        )}
                                                                    </>
                                                                )}
                                                            </td>
                                                            <td className="">
                                                                {formatDateFromDb(
                                                                    order.date
                                                                )}
                                                            </td>
                                                            <td className="">
                                                                {order.retailer}
                                                            </td>
                                                            <td className="">
                                                                {
                                                                    order.order_number
                                                                }
                                                            </td>
                                                            <td className="">
                                                                {
                                                                    order.item_name
                                                                }
                                                            </td>
                                                            <td className="">
                                                                {
                                                                    order.brand_sku
                                                                }
                                                            </td>
                                                            <td className="">
                                                                {order.size}
                                                            </td>
                                                            <td
                                                                onMouseDown={() =>
                                                                    handleMouseDown(
                                                                        rowIndex,
                                                                        8,
                                                                        inboundOrders
                                                                    )
                                                                }
                                                                onMouseEnter={() =>
                                                                    handleMouseEnter(
                                                                        rowIndex,
                                                                        8,
                                                                        inboundOrders
                                                                    )
                                                                }
                                                                onClick={() =>
                                                                    handleSingleClick(
                                                                        rowIndex,
                                                                        8,
                                                                        inboundOrders
                                                                    )
                                                                }
                                                                className={`${
                                                                    selectedCells.some(
                                                                        (
                                                                            cell
                                                                        ) =>
                                                                            cell.rowIndex ===
                                                                                rowIndex &&
                                                                            cell.columnIndex ===
                                                                                8
                                                                    )
                                                                        ? "bg-blue-200 select-none"
                                                                        : "select-none"
                                                                }`}
                                                            >
                                                                {order.quantity}
                                                            </td>
                                                            <td className="">
                                                                {order.color}
                                                            </td>
                                                            <td className="">
                                                                {order.asin}
                                                            </td>
                                                            <td className="">
                                                                {
                                                                    order.amazon_sku
                                                                }
                                                            </td>
                                                            <td
                                                                onMouseDown={() =>
                                                                    handleMouseDown(
                                                                        rowIndex,
                                                                        12,
                                                                        inboundOrders
                                                                    )
                                                                }
                                                                onMouseEnter={() =>
                                                                    handleMouseEnter(
                                                                        rowIndex,
                                                                        12,
                                                                        inboundOrders
                                                                    )
                                                                }
                                                                onClick={() =>
                                                                    handleSingleClick(
                                                                        rowIndex,
                                                                        12,
                                                                        inboundOrders
                                                                    )
                                                                }
                                                                className={`${
                                                                    selectedCells.some(
                                                                        (
                                                                            cell
                                                                        ) =>
                                                                            cell.rowIndex ===
                                                                                rowIndex &&
                                                                            cell.columnIndex ===
                                                                                12
                                                                    )
                                                                        ? "bg-blue-200 select-none"
                                                                        : "select-none"
                                                                }`}
                                                            >
                                                                {formatMoney(
                                                                    order.unit_cost
                                                                )}
                                                            </td>
                                                            <td
                                                                onMouseDown={() =>
                                                                    handleMouseDown(
                                                                        rowIndex,
                                                                        13,
                                                                        inboundOrders
                                                                    )
                                                                }
                                                                onMouseEnter={() =>
                                                                    handleMouseEnter(
                                                                        rowIndex,
                                                                        13,
                                                                        inboundOrders
                                                                    )
                                                                }
                                                                onClick={() =>
                                                                    handleSingleClick(
                                                                        rowIndex,
                                                                        13,
                                                                        inboundOrders
                                                                    )
                                                                }
                                                                className={`${
                                                                    selectedCells.some(
                                                                        (
                                                                            cell
                                                                        ) =>
                                                                            cell.rowIndex ===
                                                                                rowIndex &&
                                                                            cell.columnIndex ===
                                                                                13
                                                                    )
                                                                        ? "bg-blue-200 select-none"
                                                                        : "select-none"
                                                                }`}
                                                            >
                                                                {formatMoney(
                                                                    order.list_price
                                                                )}
                                                            </td>
                                                            <td
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.stopPropagation();
                                                                    handleViewTrackings(
                                                                        order.trackings
                                                                    );
                                                                }}
                                                                className="text-accent"
                                                            >
                                                                View
                                                            </td>
                                                            <td
                                                                onMouseDown={() =>
                                                                    handleMouseDown(
                                                                        rowIndex,
                                                                        15,
                                                                        inboundOrders
                                                                    )
                                                                }
                                                                onMouseEnter={() =>
                                                                    handleMouseEnter(
                                                                        rowIndex,
                                                                        15,
                                                                        inboundOrders
                                                                    )
                                                                }
                                                                onClick={() =>
                                                                    handleSingleClick(
                                                                        rowIndex,
                                                                        15,
                                                                        inboundOrders
                                                                    )
                                                                }
                                                                className={`${
                                                                    selectedCells.some(
                                                                        (
                                                                            cell
                                                                        ) =>
                                                                            cell.rowIndex ===
                                                                                rowIndex &&
                                                                            cell.columnIndex ===
                                                                                15
                                                                    )
                                                                        ? "bg-blue-200 select-none"
                                                                        : "select-none"
                                                                }`}
                                                            >
                                                                {formatMoney(
                                                                    order.shipment_value
                                                                )}
                                                            </td>
                                                            <td className="">
                                                                {
                                                                    order.user_notes
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <nav
                                        aria-label="Pagination"
                                        className="flex items-center justify-between border-t border-gray-200 bg-white pt-3 pb-1"
                                    >
                                        <div className="hidden sm:block">
                                            <div className="flex flex-row items-center space-x-2">
                                                <PaginationResults
                                                    results={results}
                                                />
                                                <LimitSelector />
                                            </div>
                                        </div>
                                        <div className="flex flex-1 justify-between sm:justify-end">
                                            <PaginationLink
                                                href={(function () {
                                                    const params =
                                                        new URLSearchParams(
                                                            searchParams
                                                        );
                                                    // Set the previous page offset
                                                    params.set(
                                                        "offset",
                                                        calculateOffset(
                                                            searchParams,
                                                            "back"
                                                        ).toString()
                                                    );
                                                    return `/inbound/units?${params.toString()}`;
                                                })()}
                                                disabled={
                                                    getOffset(searchParams) ===
                                                    0
                                                }
                                                text="Previous"
                                            />
                                            <PaginationLink
                                                href={(function () {
                                                    const params =
                                                        new URLSearchParams(
                                                            searchParams
                                                        );
                                                    // Set the next page offset
                                                    params.set(
                                                        "offset",
                                                        calculateOffset(
                                                            searchParams,
                                                            "forward"
                                                        ).toString()
                                                    );
                                                    return `/inbound/units?${params.toString()}`;
                                                })()}
                                                disabled={
                                                    results.showing_to >=
                                                    results.total_results
                                                }
                                                text="Next"
                                                className="ml-3"
                                            />
                                        </div>
                                    </nav>
                                    {selectedOrders &&
                                        selectedOrders?.length > 0 && (
                                            <span className="text-gray-700 text-sm">
                                                {selectedOrders.length} shipment
                                                {selectedOrders.length > 1 &&
                                                    "s"}{" "}
                                                selected
                                            </span>
                                        )}
                                    {selectedCells.length > 0 && (
                                        <span className="text-sm text-gray-900 flex flex-row items-center">
                                            {"Sum: "}
                                            {sumSelectedQuantities.map(
                                                ({ columnIndex, sum }) => (
                                                    <>
                                                        {sum > 0 && (
                                                            <p
                                                                key={
                                                                    columnIndex
                                                                }
                                                                className="pl-1"
                                                            >
                                                                {columnIndex !==
                                                                    12 &&
                                                                columnIndex !==
                                                                    13 &&
                                                                columnIndex !==
                                                                    15
                                                                    ? sum
                                                                    : formatMoney(
                                                                          sum
                                                                      )}
                                                            </p>
                                                        )}
                                                    </>
                                                )
                                            )}
                                        </span>
                                    )}
                                </>
                            ) : (
                                <div className="flex flex-grow items-center pt-44 justify-center">
                                    <EmptyInboundTable
                                        setOpenAddOrderModal={
                                            setOpenAddOrderModal
                                        }
                                    />
                                </div>
                            )}
                            <EditOrderModal
                                inboundId={selectedInboundOrder?.order_id}
                                openEditModal={openEditModal}
                                setOpenEditModal={setOpenEditModal}
                                handleRefetch={handleRefetch}
                            />

                            <BulkEditOrderModal
                                inboundIds={selectedOrders.map(
                                    (order) => order.order_id
                                )}
                                openEditModal={openBulkEditModal}
                                setOpenEditModal={setOpenBulkEditModal}
                                handleRefetch={handleRefetch}
                            />

                            <CheckInDatesModal
                                openCheckInDatesModal={openCheckInDatesModal}
                                setOpenCheckInDatesModal={
                                    setOpenCheckInDatesModal
                                }
                                selectedOrder={selectedOrder}
                                client_type="client"
                            />

                            <TrackingNumbersModal
                                openTrackingNumbersModal={
                                    openTrackingNumbersModal
                                }
                                setOpenTrackingNumbersModal={
                                    setOpenTrackingNumbersModal
                                }
                                trackingNumbers={selectedTrackingNumbers}
                            />
                        </div>
                    ) : (
                        <div className="flex flex-grow items-center pt-44 justify-center">
                            <LoadingWheel />
                        </div>
                    )}
                </div>
            </div>
            <AddOrderModal
                openAddOrderModal={openAddOrderModal}
                setOpenAddOrderModal={setOpenAddOrderModal}
                handleRefetch={handleRefetch}
            />

            <AddBulkOrderModal
                openAddBulkOrderModal={openAddBulkOrderModal}
                setOpenAddBulkOrderModal={setOpenAddBulkOrderModal}
                handleRefetch={handleRefetch}
            />

            <DeleteOrderModal
                openDeleteModal={openDeleteModal}
                setOpenDeleteModal={setOpenDeleteModal}
                order_ids={selectedOrders.map((order) => order.order_id)}
                setOpenEditModal={setOpenEditModal}
                handleRefetch={handleRefetch}
                setSelectedOrders={setSelectedOrders}
            />
        </div>
    );
}
