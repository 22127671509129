/** @format */

import ClientSideBar from "../../../../components/client/sideBar";
import Header from "../../../../components/header";
import OutboundTrackingsTable from "../outboundTrackingsTable";

function OutboundTrackingsPage() {
    return (
        <div className="flex flex-row">
            <ClientSideBar />
            <div className="flex flex-col flex-grow overflow-x-auto sm:px-10 px-4">
                <div className="">
                    <Header />
                </div>

                <div className="pt-10 flex flex-col overflow-x-auto">
                    <span className="text-2xl font-bold">Outbound</span>

                    {/* <div className="py-4">
                        <ClientSearchBar
                            setData={setShipments}
                            setLoading={setLoading}
                            handleRefetch={handleRefetch}
                            page="inventory"
                            sub_page="units"
                        />
                    </div> */}
                    <OutboundTrackingsTable />
                </div>
            </div>
        </div>
    );
}

export default OutboundTrackingsPage;
