/** @format */

import { useEffect, useRef, useState } from "react";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/react";
import {
    ChevronUpDownIcon,
    PlusCircleIcon,
    TrashIcon,
    XCircleIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import {
    InputField,
    InputNumberField,
} from "../../../../../../components/inputField";
import SingleCalendar from "../../../../../../components/singleCalendar";
import dayjs, { Dayjs } from "dayjs";
import ClientInventoryTable from "../client-orders-data/clientInventoryTable";
import { createShipment } from "../../../api/shipmentsApi";
import { useParams } from "react-router-dom";
import { formatDateFromObject } from "../../../../../../utils/date/date_formatter";
import LoadingWheel from "../../../../../../components/loadingWheel";
import { useNotification } from "../../../../../../utils/notification/notificationContext";
import { Order, ShipmentQuantities } from "../../../../../../models/order";
import React, { ChangeEvent, KeyboardEvent } from "react";
import { ScannedBarcode } from "../../../../../../models/scannedBarcode";
import { v4 as uuidv4 } from "uuid";
import { fetchBoxes } from "../../../api/settingsApi";
import { Box } from "../../../../../../models/adminDashboard";
import { formatMoney } from "../../../../../../utils/price/formatMoney";

const teamMemberOptions = [
    { id: 1, label: "Sneakers - $0.50/unit", value: "sneakers" },
    // { id: 2, label: "Can view billing", value: "can_view_billing" },
];

export default function CreateOutboundShipmentModal({
    selectedInventoryOrders,
    setSelectedInventoryOrders,
    openCreateOutbountShipment,
    setOpenCreateOutbountShipment,
    handleFullRefetch,
}: {
    selectedInventoryOrders: Order[];
    setSelectedInventoryOrders: React.Dispatch<React.SetStateAction<Order[]>>;
    openCreateOutbountShipment: boolean;
    setOpenCreateOutbountShipment: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    handleFullRefetch: () => void;
}) {
    const { showNotification } = useNotification();
    const { user_id } = useParams();
    const [loading, setLoading] = useState(false);

    const [shipmentName, setShipmentName] = useState("");
    const [notes, setNotes] = useState("");

    const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());

    const handleCreateShipment = async () => {
        if (!validateQuantities()) {
            return; // Validation failed, prevent submission
        }
        setLoading(true);

        const inventory_orders = Object.keys(shipmentQuantities).map(
            (orderId) => ({
                orderId,
                quantityShipped: shipmentQuantities[orderId],
            })
        );

        const boxes = selectedBoxes.map((box) => ({
            quantity: quantities[box.box_id],
            name: box.name,
            price: box.price,
        }));

        const data = await createShipment(
            user_id,
            shipmentName,
            formatDateFromObject(selectedDate),
            notes,
            boxes,
            inventory_orders,
            scannedBarcodes
        );
        if (data.status === "success") {
            handleFullRefetch();
            setOpenCreateOutbountShipment(false);
            setSelectedInventoryOrders([]);
            showNotification("Created outbound shipment", "", "success");
        } else {
            showNotification(
                "Failed creating outbound shipment",
                data.message,
                "error"
            );
        }
        setLoading(false);
    };

    const [shipmentQuantities, setShipmentQuantities] =
        useState<ShipmentQuantities>({});

    const handleQuantityChange = (orderId: string, value: string) => {
        setShipmentQuantities((prevQuantities) => ({
            ...prevQuantities,
            [orderId]: value,
        }));
    };

    const validateQuantities = (): boolean => {
        let isValid = true;

        selectedInventoryOrders.forEach((order) => {
            const quantity = shipmentQuantities[order.order_id];

            if (!quantity || parseInt(quantity) <= 0) {
                showNotification(
                    "Fill out all quantity shipped fields",
                    "",
                    "error"
                );
                isValid = false;
            }
        });

        return isValid;
    };

    const [scannedBarcodes, setScannedBarcodes] = useState<ScannedBarcode[]>(
        []
    );

    const [currentInput, setCurrentInput] = useState<string>("");

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCurrentInput(e.target.value);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && currentInput.trim()) {
            e.preventDefault();

            const newScannedBarcode: ScannedBarcode = {
                id: uuidv4(), // Generate a unique ID
                barcode: String(currentInput.trim()),
                dateScanned: new Date().toLocaleString(),
                isLoading: false, // Set loading to true initially
            };
            setScannedBarcodes((prev) => [...prev, newScannedBarcode]);

            setCurrentInput("");
        }
    };

    const removeBarcode = (id: string) => {
        setScannedBarcodes((prev) => prev.filter((item) => item.id !== id));
    };

    useEffect(() => {
        if (!openCreateOutbountShipment) {
            setScannedBarcodes([]);
            setShipmentQuantities({});
        }
    }, [openCreateOutbountShipment]);

    const [boxesLoading, setBoxesLoading] = useState(false);
    const [boxes, setBoxes] = useState<Box[]>([]);
    useEffect(() => {
        const fetchData = async () => {
            setBoxesLoading(true);
            const data = await fetchBoxes();
            if (data.status === "success") {
                setBoxes(data.data.boxes);
            } else {
                showNotification(
                    "Failed to fetch boxes",
                    data.message,
                    "error"
                );
            }
            setBoxesLoading(false);
        };
        fetchData();
    }, []);

    const [selectedBoxes, setSelectedBoxes] = useState<Box[]>([]);
    const [quantities, setQuantities] = useState<{ [key: string]: number }>({});

    const handleBoxClick = (box: Box) => {
        if (!selectedBoxes.some((b) => b.box_id === box.box_id)) {
            setSelectedBoxes([...selectedBoxes, box]);
            setQuantities({
                ...quantities,
                [box.box_id]: 1, // Initialize the quantity for the selected box to 1
            });
            setIsOpen(false);
        }
    };

    const handleBoxQuantityChange = (boxId: string, quantity: number) => {
        if (quantity < 1) return; // Prevent quantity from being set to less than 1
        setQuantities({
            ...quantities,
            [boxId]: quantity,
        });
    };

    const handleRemoveBox = (boxId: string) => {
        // Remove box from the selectedBoxes array
        setSelectedBoxes(selectedBoxes.filter((box) => box.box_id !== boxId));
        const updatedQuantities = { ...quantities };
        delete updatedQuantities[boxId]; // Remove the quantity associated with the removed box
        setQuantities(updatedQuantities);
    };

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const buttonRef = useRef<HTMLDivElement | null>(null);

    const toggleDropdown = () => {
        setIsOpen((prevState) => !prevState);
    };

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node) &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target as Node)
            ) {
                setIsOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const availableBoxes = boxes.filter(
        (box) =>
            !selectedBoxes.some(
                (selectedBox) => selectedBox.box_id === box.box_id
            )
    );

    const [selectedServices, setSelectedServices] = useState([]);

    return (
        <Dialog
            open={openCreateOutbountShipment}
            onClose={() => setOpenCreateOutbountShipment(false)}
            className="relative z-10"
        >
            <DialogBackdrop
                transition
                className="overflow-y-visible fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen">
                <div className="h-[90%] flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-[90%] w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-7xl sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() =>
                                    setOpenCreateOutbountShipment(false)
                                }
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-5"
                        >
                            Create outbound shipment
                        </DialogTitle>
                        <div className="w-full h-px bg-gray-200"></div>

                        <div className="flex-1 mb-4 overflow-y-auto">
                            <div className="pt-4"></div>

                            <div className="text-sm pt-2">
                                <span>
                                    {selectedInventoryOrders.length} order
                                    selected
                                </span>
                            </div>

                            <ClientInventoryTable
                                inventoryOrders={selectedInventoryOrders}
                                shipmentQuantities={shipmentQuantities}
                                handleQuantityChange={handleQuantityChange}
                            />
                            <div className="flex flex-col space-y-4">
                                <div className="flex flex-row w-full space-x-4">
                                    <div className="pt-0.5 w-full">
                                        <InputField
                                            label="Shipment name"
                                            value={shipmentName}
                                            onChange={(e) =>
                                                setShipmentName(e.target.value)
                                            }
                                            placeholder=""
                                        />
                                    </div>
                                    <div className="w-full">
                                        <SingleCalendar
                                            selectedDate={selectedDate}
                                            setSelectedDate={setSelectedDate}
                                        />
                                    </div>
                                    <div className="pt-0.5 w-full">
                                        <InputField
                                            label="Notes"
                                            value={notes}
                                            onChange={(e) =>
                                                setNotes(e.target.value)
                                            }
                                            placeholder=""
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row w-full space-x-4">
                                    <div className="flex flex-col items-start space-x-0 space-y-2">
                                        <div className="relative inline-block text-left whitespace-nowrap">
                                            {/* Button to toggle the dropdown */}
                                            <div
                                                ref={buttonRef}
                                                onClick={toggleDropdown}
                                                className="flex items-center cursor-pointer"
                                            >
                                                <span className="font-medium text-sm">
                                                    Add Boxes
                                                </span>
                                                <PlusCircleIcon className="w-6 h-6 text-accent cursor-pointer hover:text-accenthighlight ml-2" />
                                            </div>
                                            {isOpen && (
                                                <div
                                                    ref={dropdownRef}
                                                    className="absolute max-h-64 overflow-auto pt-1 left-0 w-48 bg-white border border-gray-300 shadow-lg rounded-md z-10"
                                                >
                                                    {availableBoxes.map(
                                                        (box) => (
                                                            <div
                                                                key={box.box_id}
                                                                onClick={() =>
                                                                    handleBoxClick(
                                                                        box
                                                                    )
                                                                }
                                                                className="text-sm flex flex-col py-2 space-y-0.5 pl-2 hover:bg-gray-100 transition-all cursor-pointer"
                                                            >
                                                                <span className="font-medium">
                                                                    {box.name}
                                                                </span>
                                                                <span className="text-gray-700">
                                                                    {formatMoney(
                                                                        box.price
                                                                    )}
                                                                </span>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        <div className="w-full">
                                            <div className="flex flex-col space-y-3 w-full">
                                                {selectedBoxes.length > 0 &&
                                                    selectedBoxes.map((box) => (
                                                        <div
                                                            key={box.box_id}
                                                            className="flex items-center space-x-2"
                                                        >
                                                            <div className="flex flex-col text-sm min-w-44">
                                                                <span className="font-medium">
                                                                    {box.name}
                                                                </span>
                                                                <span className="text-gray-700">
                                                                    {formatMoney(
                                                                        box.price
                                                                    )}
                                                                </span>
                                                            </div>
                                                            <input
                                                                type="number"
                                                                className="block w-32 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                                                min="1"
                                                                value={
                                                                    quantities[
                                                                        box
                                                                            .box_id
                                                                    ]
                                                                }
                                                                onChange={(e) =>
                                                                    handleBoxQuantityChange(
                                                                        box.box_id,
                                                                        parseInt(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    )
                                                                }
                                                            />
                                                            <TrashIcon
                                                                onClick={() =>
                                                                    handleRemoveBox(
                                                                        box.box_id
                                                                    )
                                                                }
                                                                className="w-5 h-5 text-red-600 hover:text-red-500 cursor-pointer"
                                                            />
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                    <Listbox
                                        value={selectedServices}
                                        onChange={setSelectedServices}
                                    >
                                        <div className="relative w-full">
                                            {/* <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-accent sm:text-sm sm:leading-6"> */}
                                            <ListboxButton className="relative cursor-default flex flex-row pt-1">
                                                {/* <span className="block truncate">
                                                    Select permissions:{" "}
                                                </span>
                                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                    <ChevronUpDownIcon
                                                        aria-hidden="true"
                                                        className="h-5 w-5 text-gray-400"
                                                    />
                                                </span> */}
                                                <span className="font-medium text-sm">
                                                    Add services
                                                </span>
                                                <PlusCircleIcon className="w-6 h-6 text-accent cursor-pointer hover:text-accenthighlight ml-2" />
                                            </ListboxButton>

                                            <ListboxOptions
                                                transition
                                                className="absolute z-10 mt-1 max-h-60 w-64 overflow-auto rounded-md bg-white border border-gray-300 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                                            >
                                                {teamMemberOptions &&
                                                    teamMemberOptions.map(
                                                        (option) => (
                                                            <ListboxOption
                                                                key={option.id}
                                                                value={option}
                                                                className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900"
                                                            >
                                                                <div className="flex flex-row items-center space-x-2">
                                                                    <input
                                                                        id={
                                                                            option.value
                                                                        }
                                                                        type="checkbox"
                                                                        // onClick={(
                                                                        //     e
                                                                        // ) => {
                                                                        //     e.stopPropagation();
                                                                        //     togglePermission(
                                                                        //         option.value as keyof ClientTeamPermissions
                                                                        //     );
                                                                        // }}
                                                                        // checked={
                                                                        //     selectedTeamPermissions[
                                                                        //         option.value as keyof ClientTeamPermissions
                                                                        //     ]
                                                                        // }
                                                                        className="h-4 w-4 rounded border-gray-300 text-accent focus:ring-0"
                                                                    />
                                                                    <span className="block truncate font-normal">
                                                                        {
                                                                            option.label
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </ListboxOption>
                                                        )
                                                    )}
                                            </ListboxOptions>
                                        </div>
                                    </Listbox>
                                </div>
                            </div>

                            <div className="space-y-3">
                                <span className="font-semibold text-sm">
                                    Trackings
                                </span>
                                <input
                                    type="text"
                                    id="tracking"
                                    value={currentInput}
                                    onChange={handleInputChange}
                                    onKeyDown={handleKeyDown}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                    placeholder="Press here and start scanning."
                                />

                                <div className="grid grid-cols-2 gap-x-5 gap-y-3">
                                    {scannedBarcodes.map((barcode, index) => (
                                        <div className="flex flex-row w-full justify-between items-center">
                                            <div className="flex flex-col">
                                                <span className="text-sm">
                                                    {barcode.barcode}
                                                </span>
                                                <span className="text-sm text-gray-600">
                                                    {barcode.dateScanned}
                                                </span>
                                                {barcode.error ? (
                                                    <span className="text-xs text-red-500">
                                                        {barcode.error}
                                                    </span>
                                                ) : null}
                                            </div>
                                            {barcode.isLoading ? (
                                                <div className="w-5 h-5">
                                                    <LoadingWheel />{" "}
                                                </div>
                                            ) : (
                                                <TrashIcon
                                                    onClick={() =>
                                                        removeBarcode(
                                                            barcode.id
                                                        )
                                                    }
                                                    className="w-5 h-5 text-red-500"
                                                />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {/* Fixed buttons at the bottom */}
                        <div className="pt-4 sm:pt-0 sm:flex sm:flex-row-reverse">
                            <button
                                onClick={() => handleCreateShipment()}
                                type="button"
                                className="inline-flex w-full justify-center rounded-md bg-accent px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-accenthighlight sm:ml-3 sm:w-auto items-center"
                            >
                                {loading ? (
                                    <span className="pr-2 items-center flex flex-row justify-center">
                                        <LoadingWheel
                                            size="small"
                                            color="white"
                                        />
                                    </span>
                                ) : (
                                    <></>
                                )}
                                Create shipment
                            </button>
                            <button
                                type="button"
                                onClick={() =>
                                    setOpenCreateOutbountShipment(false)
                                }
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            >
                                Cancel
                            </button>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}
