import Cookies from "js-cookie";
import { NavigateFunction, useNavigate } from "react-router-dom";


const STORAGE_KEY = 'auth_data';

export function handleLogout(navigate: NavigateFunction) {
    Cookies.remove("jwt")
    localStorage.removeItem(STORAGE_KEY);
    localStorage.removeItem("adminType")
    localStorage.removeItem("clientType")
    localStorage.removeItem("sidebarMinimized")
    localStorage.removeItem("sidebarOpenItems")
    navigate("/login")
}

export function clearAllData() {
    Cookies.remove("jwt")
    localStorage.removeItem(STORAGE_KEY)
    localStorage.removeItem("adminType")
    localStorage.removeItem("clientType")
    localStorage.removeItem("sidebarMinimized")
    localStorage.removeItem("sidebarOpenItems")
}