/** @format */

import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Results from "../../../../../../models/results";
import {
    calculateOffset,
    getOffset,
} from "../../../../../../utils/pagination/calculateOffset";
import { formatMoney } from "../../../../../../utils/price/formatMoney";
import { formatDateFromDb } from "../../../../../../utils/date/date_formatter";
import { Order } from "../../../../../../models/order";
import { PaginationResults } from "../../../../../../utils/pagination/paginationResults";

interface ClientOutboundTableProps {
    outboundOrders: Order[];
    results: Results;
    offset: number;
    setOffset: React.Dispatch<React.SetStateAction<number>>;
}

function ClientOutboundTable({
    outboundOrders,
    results,
    offset,
    setOffset,
}: ClientOutboundTableProps) {
    const [searchParams] = useSearchParams();

    const [loading] = useState(false);

    return (
        <div className="flex flex-col">
            {!loading && outboundOrders && outboundOrders.length > 0 ? (
                <>
                    <div className="max-h-[520px] overflow-y-auto overflow-x-scroll relative scrollbar">
                        <table className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full">
                            <thead className="sticky top-0 bg-white z-10">
                                <tr className="text-left text-sm">
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Date
                                    </th>
                                    <th className="py-2 min-w-36">Retailer</th>
                                    <th className="py-2 min-w-36">Order #</th>
                                    <th className="py-2 sm:min-w-48 min-w-48">
                                        Item Name
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Brand SKU
                                    </th>
                                    <th className="py-2 sm:min-w-24 min-w-20">
                                        Size
                                    </th>
                                    <th className="py-2 sm:min-w-44 min-w-32">
                                        Quantity shipped
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Color
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        ASIN
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Amazon SKU
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Unit price
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        List price
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Shipment value
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Notes
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {outboundOrders.map((order) => (
                                    <tr
                                        key={order.order_id}
                                        className="relative border-t border-gray-200 text-sm text-gray-500 hover:bg-gray-100 cursor-pointer"
                                    >
                                        <td className="py-2.5">
                                            {formatDateFromDb(order.date)}
                                        </td>
                                        <td className="">{order.retailer}</td>
                                        <td className="">
                                            {order.order_number}
                                        </td>
                                        <td className="">{order.item_name}</td>
                                        <td className="">{order.brand_sku}</td>
                                        <td className="">{order.size}</td>
                                        <td className="">
                                            {order.quantity_shipped}
                                        </td>
                                        <td className="">{order.color}</td>
                                        <td className="">{order.asin}</td>
                                        <td className="">{order.amazon_sku}</td>
                                        <td className="">
                                            {formatMoney(order.unit_cost)}
                                        </td>
                                        <td className="">
                                            {formatMoney(order.list_price)}
                                        </td>
                                        <td className="">
                                            {formatMoney(order.shipment_value)}
                                        </td>
                                        <td className="">{order.user_notes}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <nav
                        aria-label="Pagination"
                        className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                    >
                        <div className="hidden sm:block">
                            <PaginationResults results={results} />
                        </div>
                        <div className="flex flex-1 justify-between sm:justify-end">
                            <button
                                onClick={() =>
                                    setOffset((prevOffset) => prevOffset - 1)
                                }
                                disabled={offset === 0}
                                className={`${
                                    offset === 0
                                        ? "pointer-events-none text-gray-400 ring-gray-200"
                                        : "text-gray-900 ring-gray-300"
                                } relative inline-flex items-center rounded-md bg-white px-3 py-1.5 text-xs font-semibold ring-1 ring-inset hover:bg-gray-50 focus-visible:outline-offset-0`}
                            >
                                Previous
                            </button>
                            <button
                                onClick={() =>
                                    setOffset((prevOffset) => prevOffset + 1)
                                }
                                disabled={
                                    results.showing_to >= results.total_results
                                }
                                className={`${
                                    results.showing_to >= results.total_results
                                        ? "pointer-events-none text-gray-400 ring-gray-200"
                                        : "text-gray-900 ring-gray-300"
                                } relative inline-flex items-center rounded-md bg-white px-3 py-1.5 text-xs font-semibold ring-1 ring-inset hover:bg-gray-50 focus-visible:outline-offset-0 ml-3`}
                            >
                                Next
                            </button>
                        </div>
                    </nav>
                </>
            ) : (
                <div className="flex flex-grow items-center pt-10 justify-center">
                    <span className="text-sm text-gray-500">
                        User has no outbound shipments
                    </span>
                </div>
            )}
        </div>
    );
}

export default ClientOutboundTable;
