/** @format */

import { useState, useEffect } from "react";
import ClientSideBar from "../../../components/client/sideBar";
import Header from "../../../components/header";
import DashboardCards from "./dashboardCards";
import OverviewCards from "./overviewCards";
import DashboardCardsModel from "../../../models/dashboardCards";
import { fetchDashboardData } from "./api/dashboardApi";
import LoadingWheel from "../../../components/loadingWheel";
import DashboardStats from "../../../models/dashboardStats";
import {
    AdminNotification,
    WarehouseAddress,
} from "../../../models/adminDashboard";

export default function DashboardPage() {
    const handleRefetch = () => {
        setRefetchData((prevValue) => prevValue + 1);
    };
    const [refetchData, setRefetchData] = useState<number>(0);
    const [loading, setLoading] = useState(true);
    const [dashboardCardsData, setDashboardCardsData] =
        useState<DashboardCardsModel | null>(null);

    const [dashboardOverviewStats, setDashboardOverviewStats] =
        useState<DashboardStats | null>(null);

    const [warehouseAddress, setWarehouseAddress] =
        useState<WarehouseAddress | null>(null);

    const [notifications, setNotifications] = useState<AdminNotification[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const data = await fetchDashboardData();
            if (data.status === "success") {
                setDashboardCardsData(data.data.orders);
                setDashboardOverviewStats(data.data.spenditure);
                setWarehouseAddress(data.data.address);
                setNotifications(data.data.notifications);
            }
            setLoading(false);
        };
        fetchData();
    }, [refetchData]);

    return (
        <div className="flex flex-row">
            <ClientSideBar />
            <div className="flex flex-col flex-grow overflow-x-auto">
                <div className="sm:px-10 px-4">
                    <Header />
                </div>
                <div className="sm:mx-10 mx-4 pt-10 flex flex-col">
                    <span className="text-2xl font-bold">Dashboard</span>

                    {!loading ? (
                        <div>
                            <DashboardCards
                                dashboardCardsData={dashboardCardsData}
                            />

                            {/* <span className="text-xl font-bold">Overview</span> */}

                            <OverviewCards
                                dashboardOverviewStats={dashboardOverviewStats}
                                warehouseAddress={warehouseAddress}
                                notificationsData={notifications}
                                setNotificationsData={setNotifications}
                                handleRefetch={handleRefetch}
                            />
                        </div>
                    ) : (
                        <div className="flex flex-grow items-center pt-44 justify-center">
                            <LoadingWheel />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
