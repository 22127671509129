/** @format */

import { useEffect, useState } from "react";
import { Order, OrderStatus } from "../../../../../models/order";
import { AdminClientTypesRefs } from "../../../../../models/refsModels";
import Results from "../../../../../models/results";
import ClientInventoryTable from "./inventoryOrdersTable";
import LoadingWheel from "../../../../../components/loadingWheel";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { InventoryPageType } from "../../../../../models/pageSelectors";
import { deleteClientOrders } from "../../api/unitsApi";
import { useNotification } from "../../../../../utils/notification/notificationContext";
import AccountTrackings from "../trackings/accountTrackings";
import CreateOutboundShipmentModal from "../shipments/outbound-shipment/createOutboundShipment";
import { AddInventoryOrderModal } from "./addInventoryOrderModal";
import { getAdminType } from "../../../../../utils/adminTypes/actions";
import { TrackingArrived } from "../../../../../components/trackingArrived";

const selectorsData = [
    { label: "Units", value: "units" },
    { label: "Trackings", value: "trackings" },
];

interface AdminInboundPageProps {
    pageRefs: AdminClientTypesRefs;
    handleClientRefetch: () => void;
    inventoryOrders: Order[];
    setInventoryOrders: React.Dispatch<React.SetStateAction<Order[]>>;
    inventoryResults: Results;
    setInventoryResults: React.Dispatch<React.SetStateAction<Results>>;
    inventoryTrackings: TrackingArrived[];
    setInventoryTrackings: React.Dispatch<
        React.SetStateAction<TrackingArrived[]>
    >;
}

function AdminInventoryPage({
    pageRefs,
    handleClientRefetch,
    inventoryOrders,
    setInventoryOrders,
    inventoryResults,
    setInventoryResults,
    inventoryTrackings,
    setInventoryTrackings,
}: AdminInboundPageProps) {
    const isOwner = getAdminType() === "owner";
    const { user_id } = useParams();
    const { showNotification } = useNotification();
    const [selectedOrders, setSelectedOrders] = useState<Order[]>([]);

    const navigate = useNavigate();
    const location = useLocation();

    const [deleteLoading, setDeleteLoading] = useState(false);

    const [openCreateOutboundShipment, setOpenCreateOutboundShipment] =
        useState(false);

    const [openAddInventoryOrderModal, setOpenAddInventoryOrderModal] =
        useState(false);

    const getQueryParams = () => {
        const queryParams = new URLSearchParams(location.search);
        return (queryParams.get("sub_page") as InventoryPageType) || "units";
    };

    const [pageSelected, setPageSelected] = useState<InventoryPageType>(
        getQueryParams()
    );

    useEffect(() => {
        const page = getQueryParams();
        setPageSelected(page);
        // Navigate to the correct page if URL is invalid
        if (page && ["units", "trackings"].indexOf(page) === -1) {
            navigate(`/admin/clients/${user_id}?page=inventory`, {
                replace: true,
            });
        }
    }, [location.search, navigate]);

    const handlePageChange = (newPage: InventoryPageType) => {
        setPageSelected(newPage);
        navigate(
            `/admin/clients/${user_id}?page=inventory&sub_page=${newPage}`
        );
        setSelectedOrders([]);
    };

    const handleDelete = async () => {
        setDeleteLoading(true);
        const data = await deleteClientOrders(user_id, selectedOrders);
        if (data.status === "success") {
            handleClientRefetch();

            showNotification("Deleted order.", "", "success");
        } else {
            showNotification("Failed to delete orders", data.message, "error");
        }
        setDeleteLoading(false);
    };

    return (
        <div className="flex flex-col">
            <div className="flex flex-row items-start justify-between pt-8 relative">
                <div className="flex flex-row font-semibold relative pb-2">
                    {selectorsData.map((item) => (
                        <button
                            className={`relative text-sm ${
                                pageSelected === item.value
                                    ? "text-accent after:bg-purple-500"
                                    : "text-gray-400 after:bg-transparent"
                            } w-28 after:absolute after:left-0 after:bottom-[-10px] after:h-0.5 after:w-full`}
                            onClick={() =>
                                handlePageChange(
                                    item.value as InventoryPageType
                                )
                            }
                        >
                            {item.label}
                        </button>
                    ))}
                </div>
                <div className="absolute right-0 bottom-2 flex space-x-2">
                    {isOwner ? (
                        <>
                            {selectedOrders ? (
                                <div
                                    className={`transition-opacity duration-300 ease-in-out ${
                                        selectedOrders.length > 0
                                            ? "opacity-100"
                                            : "opacity-0 pointer-events-none"
                                    }`}
                                >
                                    <button
                                        onClick={() => handleDelete()}
                                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 h-7 text-xs font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto items-center"
                                    >
                                        {deleteLoading ? (
                                            <LoadingWheel
                                                color="white"
                                                size="small"
                                            />
                                        ) : (
                                            <>Delete</>
                                        )}
                                    </button>
                                </div>
                            ) : null}
                        </>
                    ) : null}

                    <button
                        disabled={
                            selectedOrders.length === 0 ||
                            selectedOrders.some(
                                (order) => order.fulfilled_by === "merchant"
                            )
                        }
                        onClick={() => setOpenCreateOutboundShipment(true)}
                        className={`${
                            selectedOrders.length === 0 ||
                            selectedOrders.some(
                                (order) => order.fulfilled_by === "merchant"
                            )
                                ? "bg-accentdim"
                                : "bg-accent hover:bg-accenthighlight"
                        } text-white font-semibold px-5 text-xs rounded-md h-7`}
                    >
                        New Outbound Shipment
                    </button>
                    <button
                        onClick={() => setOpenAddInventoryOrderModal(true)}
                        className={`bg-accent hover:bg-accenthighlight text-white font-semibold px-5 text-xs rounded-md h-7`}
                    >
                        Add Inventory
                    </button>
                </div>
            </div>
            <div className="w-full h-0.5 bg-gray-300"></div>

            {pageSelected === "units" ? (
                <ClientInventoryTable
                    inventoryOrders={inventoryOrders}
                    setInventoryOrders={setInventoryOrders}
                    inventoryResults={inventoryResults}
                    setInventoryResults={setInventoryResults}
                    selectedOrders={selectedOrders}
                    setSelectedOrders={setSelectedOrders}
                    pageRefs={pageRefs}
                    handleFullRefetch={handleClientRefetch}
                />
            ) : (
                <AccountTrackings
                    trackingsArrived={inventoryTrackings}
                    setTrackingsArrived={setInventoryTrackings}
                />
            )}

            <CreateOutboundShipmentModal
                selectedInventoryOrders={selectedOrders}
                setSelectedInventoryOrders={setSelectedOrders}
                openCreateOutbountShipment={openCreateOutboundShipment}
                setOpenCreateOutbountShipment={setOpenCreateOutboundShipment}
                handleFullRefetch={handleClientRefetch}
            />

            <AddInventoryOrderModal
                openAddInventoryOrderModal={openAddInventoryOrderModal}
                setOpenAddInventoryOrderModal={setOpenAddInventoryOrderModal}
                handleRefetch={handleClientRefetch}
            />
        </div>
    );
}

export default AdminInventoryPage;
