/** @format */

import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Results from "../../../../../../models/results";
import {
    calculateOffset,
    getOffset,
} from "../../../../../../utils/pagination/calculateOffset";
import { formatMoney } from "../../../../../../utils/price/formatMoney";
import { StatusTag } from "../../../../../../components/statusTags";
import { formatDateFromDb } from "../../../../../../utils/date/date_formatter";
import CheckInDatesModal from "../../../../../../components/modals/checkInDatesModal";
import { CheckInDate } from "../../../../../../models/checkInDate";
import { useCheckInDatesModal } from "../../../../../../hooks/useCheckInDates";
import { Order, ShipmentQuantities } from "../../../../../../models/order";
import useCellSelection from "../../../../../../hooks/useCellSelection";

interface ClientInboundTableProps {
    inventoryOrders: Order[];
    selectedInventoryOrders?: Order[];
    setSelectedInventoryOrders?: React.Dispatch<React.SetStateAction<Order[]>>;
    shipmentQuantities?: ShipmentQuantities;
    handleQuantityChange?: (orderId: string, value: string) => void;
}

function ClientInventoryTable({
    inventoryOrders,
    selectedInventoryOrders,
    setSelectedInventoryOrders,
    shipmentQuantities,
    handleQuantityChange,
}: ClientInboundTableProps) {
    const [searchParams] = useSearchParams();

    const [results] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const {
        openCheckInDatesModal,
        setOpenCheckInDatesModal,
        selectedOrder,
        handleViewDates,
    } = useCheckInDatesModal();

    const handleCheckboxChange = (order: Order) => {
        const orderId = order.order_id;
        if (selectedInventoryOrders && setSelectedInventoryOrders) {
            if (selectedInventoryOrders.some((o) => o.order_id === orderId)) {
                // Remove the order by its id
                setSelectedInventoryOrders(
                    selectedInventoryOrders.filter(
                        (o) => o.order_id !== orderId
                    )
                );
            } else {
                // Add the order using its id
                setSelectedInventoryOrders([...selectedInventoryOrders, order]);
            }
        }
    };

    const getCellValue = (
        rowIndex: number,
        columnIndex: number,
        data: any[]
    ) => {
        const order = data[rowIndex];
        switch (columnIndex) {
            case 1:
                return order.quantity_remaining;
            case 9:
                return order.quantity;
            case 13:
                return order.unit_cost;
            case 14:
                return order.list_price;
            default:
                return 0;
        }
    };

    const {
        selectedCells,
        handleSingleClick,
        handleMouseDown,
        handleMouseEnter,
        handleMouseUp,
        sumSelectedQuantities,
    } = useCellSelection([], getCellValue, [1, 9, 12, 14]);

    return (
        <div className="flex flex-col">
            {inventoryOrders && inventoryOrders.length > 0 ? (
                <>
                    <div className="overflow-x-scroll scrollbar">
                        <table
                            onMouseUp={handleMouseUp}
                            className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full"
                        >
                            <thead>
                                <tr className="text-left text-sm">
                                    {selectedInventoryOrders ? (
                                        <th className="py-2 min-w-10"></th>
                                    ) : (
                                        <></>
                                    )}
                                    <th className="py-2 sm:min-w-40 min-w-32">
                                        Shipping quantity
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-32 ">
                                        Remaining units
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-32 ">
                                        Status
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Date
                                    </th>
                                    <th className="py-2 min-w-32">Retailer</th>
                                    <th className="py-2 min-w-32">Order #</th>
                                    <th className="py-2 sm:min-w-48 min-w-48">
                                        Item Name
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Brand SKU
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Size
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Quantity
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Color
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        ASIN
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Amazon SKU
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Unit cost
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        List price
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Notes
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {inventoryOrders.map((order, rowIndex) => (
                                    <tr
                                        key={order.order_id}
                                        className="relative border-t border-gray-200 text-sm text-gray-500 cursor-pointer"
                                    >
                                        {selectedInventoryOrders ? (
                                            <td className="py-3 flex items-center">
                                                <input
                                                    type="checkbox"
                                                    className="h-4 w-4 rounded border-gray-300 text-accent focus:ring-0 focus:ring-transparent ring-0 focus:ring-offset-0 focus:outline transition-all"
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            order
                                                        )
                                                    }
                                                    checked={selectedInventoryOrders.some(
                                                        (o) =>
                                                            o.order_id ===
                                                            order.order_id
                                                    )}
                                                />
                                            </td>
                                        ) : (
                                            <></>
                                        )}
                                        {shipmentQuantities &&
                                        handleQuantityChange ? (
                                            <td>
                                                <input
                                                    className="block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                                    type="number"
                                                    value={
                                                        shipmentQuantities[
                                                            order.order_id
                                                        ] || ""
                                                    }
                                                    onChange={(e) =>
                                                        handleQuantityChange(
                                                            order.order_id,
                                                            e.target.value
                                                        )
                                                    }
                                                ></input>
                                            </td>
                                        ) : null}
                                        <td
                                            onMouseDown={() =>
                                                handleMouseDown(
                                                    rowIndex,
                                                    1,
                                                    inventoryOrders
                                                )
                                            }
                                            onMouseEnter={() =>
                                                handleMouseEnter(
                                                    rowIndex,
                                                    1,
                                                    inventoryOrders
                                                )
                                            }
                                            onClick={() =>
                                                handleSingleClick(
                                                    rowIndex,
                                                    1,
                                                    inventoryOrders
                                                )
                                            }
                                            className={`${
                                                selectedCells.some(
                                                    (cell) =>
                                                        cell.rowIndex ===
                                                            rowIndex &&
                                                        cell.columnIndex === 1
                                                )
                                                    ? "bg-blue-200 select-none"
                                                    : "select-none"
                                            }`}
                                        >
                                            {order.quantity_remaining}
                                        </td>
                                        <td className="py-2.5">
                                            <StatusTag
                                                status={order.unit_status}
                                                quantity_received={
                                                    order.quantity_received
                                                }
                                                quantity={order.quantity}
                                                handleClick={(e) => {
                                                    e.stopPropagation();
                                                    handleViewDates(order);
                                                }}
                                            />
                                        </td>
                                        <td className="">
                                            {formatDateFromDb(order.date)}
                                        </td>
                                        <td className="">{order.retailer}</td>
                                        <td className="">
                                            {order.order_number}
                                        </td>
                                        <td className="">{order.item_name}</td>
                                        <td className="">{order.brand_sku}</td>
                                        <td className="">{order.size}</td>
                                        <td
                                            onMouseDown={() =>
                                                handleMouseDown(
                                                    rowIndex,
                                                    9,
                                                    inventoryOrders
                                                )
                                            }
                                            onMouseEnter={() =>
                                                handleMouseEnter(
                                                    rowIndex,
                                                    9,
                                                    inventoryOrders
                                                )
                                            }
                                            onClick={() =>
                                                handleSingleClick(
                                                    rowIndex,
                                                    9,
                                                    inventoryOrders
                                                )
                                            }
                                            className={`${
                                                selectedCells.some(
                                                    (cell) =>
                                                        cell.rowIndex ===
                                                            rowIndex &&
                                                        cell.columnIndex === 9
                                                )
                                                    ? "bg-blue-200 select-none"
                                                    : "select-none"
                                            }`}
                                        >
                                            {order.quantity}
                                        </td>
                                        <td className="">{order.color}</td>
                                        <td className="">{order.asin}</td>
                                        <td className="">{order.amazon_sku}</td>
                                        <td
                                            onMouseDown={() =>
                                                handleMouseDown(
                                                    rowIndex,
                                                    13,
                                                    inventoryOrders
                                                )
                                            }
                                            onMouseEnter={() =>
                                                handleMouseEnter(
                                                    rowIndex,
                                                    13,
                                                    inventoryOrders
                                                )
                                            }
                                            onClick={() =>
                                                handleSingleClick(
                                                    rowIndex,
                                                    13,
                                                    inventoryOrders
                                                )
                                            }
                                            className={`${
                                                selectedCells.some(
                                                    (cell) =>
                                                        cell.rowIndex ===
                                                            rowIndex &&
                                                        cell.columnIndex === 13
                                                )
                                                    ? "bg-blue-200 select-none"
                                                    : "select-none"
                                            }`}
                                        >
                                            {formatMoney(order.unit_cost)}
                                        </td>
                                        <td
                                            onMouseDown={() =>
                                                handleMouseDown(
                                                    rowIndex,
                                                    14,
                                                    inventoryOrders
                                                )
                                            }
                                            onMouseEnter={() =>
                                                handleMouseEnter(
                                                    rowIndex,
                                                    14,
                                                    inventoryOrders
                                                )
                                            }
                                            onClick={() =>
                                                handleSingleClick(
                                                    rowIndex,
                                                    14,
                                                    inventoryOrders
                                                )
                                            }
                                            className={`${
                                                selectedCells.some(
                                                    (cell) =>
                                                        cell.rowIndex ===
                                                            rowIndex &&
                                                        cell.columnIndex === 14
                                                )
                                                    ? "bg-blue-200 select-none"
                                                    : "select-none"
                                            }`}
                                        >
                                            {formatMoney(order.list_price)}
                                        </td>
                                        <td className="">{order.user_notes}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <nav
                        aria-label="Pagination"
                        className="flex items-center justify-between border-t border-gray-200 bg-white pt-3 pb-1"
                    >
                        <div className="hidden sm:block">
                            <p className="text-sm text-gray-700">
                                Showing all results
                            </p>
                        </div>
                        <div className="flex flex-1 justify-between sm:justify-end">
                            <a
                                href={`/admin/clients?offset=${calculateOffset(
                                    searchParams,
                                    "back"
                                )}`}
                                className={`${
                                    getOffset(searchParams) === 0
                                        ? "pointer-events-none text-gray-400 ring-gray-200"
                                        : "text-gray-900 ring-gray-300"
                                } relative inline-flex items-center rounded-md bg-white px-3 py-1.5 text-xs font-semibold ring-1 ring-inset hover:bg-gray-50 focus-visible:outline-offset-0`}
                            >
                                Previous
                            </a>
                            <a
                                href={`/admin/clients?offset=${calculateOffset(
                                    searchParams,
                                    "forward"
                                )}`}
                                className={`${
                                    results.showing_to >= results.total_results
                                        ? "pointer-events-none text-gray-400 ring-gray-200"
                                        : "text-gray-900 ring-gray-300"
                                } relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-1.5 text-xs font-semibold ring-1 ring-inset hover:bg-gray-50 focus-visible:outline-offset-0`}
                            >
                                Next
                            </a>
                        </div>
                    </nav>
                    {selectedCells.length > 0 && (
                        <span className="text-sm text-gray-900 flex flex-row items-center">
                            {"Sum: "}
                            {sumSelectedQuantities.map(
                                ({ columnIndex, sum }) => (
                                    <>
                                        {sum > 0 && (
                                            <p
                                                key={columnIndex}
                                                className="pl-1"
                                            >
                                                {columnIndex !== 14 &&
                                                columnIndex !== 13
                                                    ? sum
                                                    : formatMoney(sum)}
                                            </p>
                                        )}
                                    </>
                                )
                            )}
                        </span>
                    )}
                </>
            ) : (
                <div className="flex flex-grow items-center pt-10 justify-center">
                    <span className="text-sm text-gray-500">
                        User has no inventory orders
                    </span>
                </div>
            )}

            <CheckInDatesModal
                openCheckInDatesModal={openCheckInDatesModal}
                setOpenCheckInDatesModal={setOpenCheckInDatesModal}
                selectedOrder={selectedOrder}
            />
        </div>
    );
}

export default ClientInventoryTable;
