
export const shipmentFrequencyOptions = [
    {
        id: 1,
        label: "As soon as possible",
        description: "Ship the items as soon as they are ready for dispatch.",
        value: "as_soon_as_possible",
    },
    {
        id: 2,
        label: "On client request",
        description: "Items will be shipped only when requested by you.",
        value: "on_client_request",
    },
    {
        id: 3,
        label: "Minimum number of units",
        description: "Enter the minimum unit threshold for shipment",
        value: "minimum_number_of_units",
    },
];