import Footer from "../../components/website/footer";
import Header from "../../components/website/header";
import ServicesEcommerce from "../../components/website/servicesEcommerce";

export default function EcommerceManagementPage() {
    return (
        <div>
            <Header />
            <div className="my-36 lg:my-36 max-w-7xl mx-auto px-6 lg:px-8 flex flex-col md:flex-row justify-between md:space-x-20">
                <div className="max-w-xl">
                    <h1 className="text-5xl font-bold tracking-tight text-gray-900 sm:text-6xl mr-0">
                        Ecommerce Management
                    </h1>
                    <p className="relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
                        At Urva, we provide ecommerce management
                        services to ensure your brand's success. Our team is
                        dedicated to maintaining the integrity of your brand and
                        enhancing customer experiences.
                        <br></br>
                        <br></br>
                        Partner with Urva for reliable and effective
                        ecommerce management solutions.
                    </p>
                </div>
                <div>
                    <img
                        src="/img-laptop.jpg"
                        className="object-cover rounded-lg h-96 w-full md:h-[36rem] mt-10 md:mt-0"
                    ></img>
                </div>
            </div>
            <ServicesEcommerce />
            <Footer />
        </div>
    );
}
