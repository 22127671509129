/** @format */

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Shipment from "../../../../../models/shipments";
import Results from "../../../../../models/results";
import { PaginationResults } from "../../../../../utils/pagination/paginationResults";
import { formatDateFromDb } from "../../../../../utils/date/date_formatter";
import OutboundOrdersModal from "../shipments/outbound-shipment/outboundOrders";
import { useNotification } from "../../../../../utils/notification/notificationContext";
import { fetchClientOrders } from "../../api/unitsApi";
import LoadingWheel, {
    SortLoading,
} from "../../../../../components/loadingWheel";
import { AdminClientTypesRefs } from "../../../../../models/refsModels";
import { ShipmentPageSelector } from "../../../../../models/pageSelectors";
import { PrinterIcon } from "@heroicons/react/24/outline";
import { downloadShipmentPdf } from "../../api/labelsApi";
import ShipmentTrackingsTable from "./shipmentTrackingsTable";
import { ShipmentStatusTag } from "../../../../../components/statusTags";
import { AdminLimitSelector } from "../../../../../utils/pagination/limitSelector";
import { sortableColumns, useSort } from "../../../../../hooks/useSort";
import useScrollTranslation from "../../../../../hooks/useScrollTranslation";
import ColumnSort from "../../../../../utils/sort/columnSort";
import useCellSelection from "../../../../../hooks/useCellSelection";
import { formatMoney } from "../../../../../utils/price/formatMoney";

const tableHeaders = [
    {
        key: "status",
        label: "Status",
        className: "sm:min-w-32 min-w-32",
    },
    { key: "created_at", label: "Date", className: "sm:min-w-32 min-w-28" },
    {
        key: "name",
        label: "Shipment Name",
        className: "sm:min-w-44 min-w-44",
    },
    {
        key: "total_quantity",
        label: "Total Quantity",
        className: "sm:min-w-32 min-w-28",
    },
    {
        key: "shipment_value",
        label: "Shipment Value",
        className: "sm:min-w-32 min-w-28",
    },
    { key: "notes", label: "Notes", className: "sm:min-w-32 min-w-28" },
];

const tableHeaders2 = [
    {
        key: "status",
        label: "Status",
        className: "sm:min-w-32 min-w-32",
    },
    { key: "created_at", label: "Date", className: "sm:min-w-32 min-w-28" },
    {
        key: "name",
        label: "Shipment Name",
        className: "sm:min-w-44 min-w-44",
    },
    {
        key: "total_quantity",
        label: "Total Quantity",
        className: "sm:min-w-32 min-w-28",
    },
    {
        key: "shipment_value",
        label: "Shipment Value",
        className: "sm:min-w-32 min-w-28",
    },
    { key: "notes", label: "Notes", className: "sm:min-w-32 min-w-28" },
    { key: "actions", label: "Actions", className: "text-right" },
];

interface ClientShipmentsTableProps {
    shipments: Shipment[];
    setShipments: React.Dispatch<React.SetStateAction<Shipment[]>>;
    shipmentResults: Results;
    setShipmentResults: React.Dispatch<React.SetStateAction<Results>>;
    pageRefs: AdminClientTypesRefs;
    handleFullRefetch: () => void;
    pageSelected: ShipmentPageSelector;
}

function ClientShipmentsTable({
    shipments,
    setShipments,
    shipmentResults,
    setShipmentResults,
    pageRefs,
    handleFullRefetch,
    pageSelected,
}: ClientShipmentsTableProps) {
    const { user_id } = useParams();
    const [loading, setLoading] = useState(false);
    const { showNotification } = useNotification();

    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [openOutboundOrdersModal, setOpenOutboundOrdersModal] =
        useState(false);

    const [selectedShipment, setSelectedShipment] = useState<Shipment | null>(
        null
    );

    useEffect(() => {
        if (selectedShipment) {
            setOpenOutboundOrdersModal(true);
        }
    }, [selectedShipment]);

    useEffect(() => {
        if (!openOutboundOrdersModal) {
            setTimeout(() => {
                setSelectedShipment(null);
            }, 200);
        }
    }, [openOutboundOrdersModal]);

    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(0);

    const fetchData = async () => {
        const data = await fetchClientOrders(
            user_id,
            "shipments",
            undefined,
            offset,
            limit,
            sortConfig,
            pageSelected
        );
        if (data.status === "success") {
            setShipments(data.data.orders);
            setShipmentResults(data.data.results);
        } else {
            showNotification("Failed fetching orders", data.message, "error");
        }
        setSortLoading(false);
        setLoading(false);
        pageRefs.shipmentRef.current = true;
    };

    useEffect(() => {
        if (offset !== 0 || limit !== 0) {
            setLoading(true);
        }
        fetchData();
    }, [offset, limit, sortConfig]);

    useEffect(() => {
        if (pageSelected !== "trackings") {
            setLoading(true);
            fetchData();
        }
    }, [pageSelected]);

    const handleDownload = async (
        event: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>,
        shipment_id: string
    ) => {
        event.stopPropagation();
        const success = await downloadShipmentPdf(shipment_id);
        if (success) {
        } else {
            showNotification("Failed to download pdf");
        }
    };

    const [selectedShipments, setSelectedShipments] = useState<Shipment[]>([]);
    const handleCheckboxChange = (shipment: Shipment) => {
        const shipmentId = shipment.shipment_id;
        if (selectedShipments && setSelectedShipments) {
            if (selectedShipments.some((s) => s.shipment_id === shipmentId)) {
                // Remove the order by its id
                setSelectedShipments(
                    selectedShipments.filter(
                        (s) => s.shipment_id !== shipmentId
                    )
                );
            } else {
                // Add the order using its id
                setSelectedShipments([...selectedShipments, shipment]);
            }
        }
    };
    const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (setSelectedShipments) {
            if (e.target.checked) {
                setSelectedShipments(shipments);
            } else {
                setSelectedShipments([]);
            }
        }
    };

    const { scrollContainerRef, scrollOffset } = useScrollTranslation();

    const headers = pageSelected === "shipped" ? tableHeaders : tableHeaders2;

    const getCellValue = (
        rowIndex: number,
        columnIndex: number,
        data: any[]
    ) => {
        const shipment = data[rowIndex];
        switch (columnIndex) {
            case 3:
                return shipment.total_quantity_shipped;
            case 4:
                return shipment.shipment_value;
            default:
                return 0;
        }
    };

    const {
        selectedCells,
        handleSingleClick,
        handleMouseDown,
        handleMouseEnter,
        handleMouseUp,
        sumSelectedQuantities,
    } = useCellSelection([], getCellValue, [3, 4]);

    return (
        <div className="flex flex-col pt-3">
            <>
                {!loading && shipments && shipments.length > 0 ? (
                    <>
                        <div
                            ref={scrollContainerRef}
                            className={`max-h-[650px] overflow-y-auto overflow-x-scroll relative scrollbar ${
                                sortLoading
                                    ? "pointer-events-none overflow-hidden"
                                    : ""
                            }`}
                        >
                            <SortLoading
                                sortLoading={sortLoading}
                                scrollOffset={scrollOffset}
                            />
                            <table
                                onMouseUp={handleMouseUp}
                                className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full"
                            >
                                <thead className="sticky top-0 bg-white z-10">
                                    <tr className="text-left text-sm">
                                        {headers.map((header) => (
                                            <th
                                                key={header.key}
                                                onClick={() =>
                                                    sortableColumns.includes(
                                                        header.key
                                                    ) && toggleSort(header.key)
                                                }
                                                className={`group py-2 cursor-pointer ${
                                                    sortableColumns.includes(
                                                        header.key
                                                    )
                                                        ? "hover:bg-gray-100"
                                                        : ""
                                                } ${header.className}`}
                                            >
                                                {header.label}
                                                <ColumnSort
                                                    header={header}
                                                    sortConfig={sortConfig}
                                                />
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {shipments.map((shipment, rowIndex) => (
                                        <tr
                                            onClick={() =>
                                                setSelectedShipment(shipment)
                                            }
                                            key={shipment.shipment_id}
                                            className="relative border-t border-gray-200 text-sm text-gray-500 hover:bg-gray-100 cursor-pointer"
                                        >
                                            {/* {pageSelected === "requested" ? (
                                                <td className="py-3 flex items-center">
                                                    <input
                                                        type="checkbox"
                                                        className="h-4 w-4 rounded border-gray-300 text-accent focus:ring-0 focus:ring-transparent ring-0 focus:ring-offset-0 focus:outline transition-all"
                                                        onClick={(e) =>
                                                            e.stopPropagation()
                                                        }
                                                        onChange={() =>
                                                            handleCheckboxChange(
                                                                shipment
                                                            )
                                                        }
                                                        checked={selectedShipments.some(
                                                            (s) =>
                                                                s.shipment_id ===
                                                                shipment.shipment_id
                                                        )}
                                                    />
                                                </td>
                                            ) : (
                                                <></>
                                            )} */}
                                            <td className="py-2.5">
                                                <ShipmentStatusTag
                                                    status={
                                                        shipment.admin_status
                                                            ? shipment.admin_status
                                                            : shipment.status
                                                    }
                                                />
                                            </td>
                                            {pageSelected === "shipped" ? (
                                                <>
                                                    <td className="py-2.5">
                                                        {formatDateFromDb(
                                                            shipment.date
                                                        )}
                                                    </td>
                                                    <td className="py-2.5">
                                                        {shipment.name}
                                                    </td>
                                                    <td
                                                        onMouseDown={() =>
                                                            handleMouseDown(
                                                                rowIndex,
                                                                3,
                                                                shipments
                                                            )
                                                        }
                                                        onMouseEnter={() =>
                                                            handleMouseEnter(
                                                                rowIndex,
                                                                3,
                                                                shipments
                                                            )
                                                        }
                                                        onClick={() =>
                                                            handleSingleClick(
                                                                rowIndex,
                                                                3,
                                                                shipments
                                                            )
                                                        }
                                                        className={`${
                                                            selectedCells.some(
                                                                (cell) =>
                                                                    cell.rowIndex ===
                                                                        rowIndex &&
                                                                    cell.columnIndex ===
                                                                        3
                                                            )
                                                                ? "bg-blue-200 select-none"
                                                                : "select-none"
                                                        }`}
                                                    >
                                                        {
                                                            shipment.total_quantity_shipped
                                                        }
                                                    </td>
                                                    <td
                                                        onMouseDown={() =>
                                                            handleMouseDown(
                                                                rowIndex,
                                                                4,
                                                                shipments
                                                            )
                                                        }
                                                        onMouseEnter={() =>
                                                            handleMouseEnter(
                                                                rowIndex,
                                                                4,
                                                                shipments
                                                            )
                                                        }
                                                        onClick={() =>
                                                            handleSingleClick(
                                                                rowIndex,
                                                                4,
                                                                shipments
                                                            )
                                                        }
                                                        className={`${
                                                            selectedCells.some(
                                                                (cell) =>
                                                                    cell.rowIndex ===
                                                                        rowIndex &&
                                                                    cell.columnIndex ===
                                                                        4
                                                            )
                                                                ? "bg-blue-200 select-none"
                                                                : "select-none"
                                                        }`}
                                                    >
                                                        {formatMoney(
                                                            shipment.shipment_value
                                                        )}
                                                    </td>
                                                    <td className="py-2.5">
                                                        {shipment.notes}
                                                    </td>
                                                </>
                                            ) : (
                                                <>
                                                    <td className="py-2.5">
                                                        {formatDateFromDb(
                                                            shipment.date
                                                        )}
                                                    </td>
                                                    <td className="py-2.5">
                                                        {shipment.name}
                                                    </td>
                                                    <td
                                                        onMouseDown={() =>
                                                            handleMouseDown(
                                                                rowIndex,
                                                                3,
                                                                shipments
                                                            )
                                                        }
                                                        onMouseEnter={() =>
                                                            handleMouseEnter(
                                                                rowIndex,
                                                                3,
                                                                shipments
                                                            )
                                                        }
                                                        onClick={() =>
                                                            handleSingleClick(
                                                                rowIndex,
                                                                3,
                                                                shipments
                                                            )
                                                        }
                                                        className={`${
                                                            selectedCells.some(
                                                                (cell) =>
                                                                    cell.rowIndex ===
                                                                        rowIndex &&
                                                                    cell.columnIndex ===
                                                                        3
                                                            )
                                                                ? "bg-blue-200 select-none"
                                                                : "select-none"
                                                        }`}
                                                    >
                                                        {
                                                            shipment.total_quantity_shipped
                                                        }
                                                    </td>
                                                    <td
                                                        onMouseDown={() =>
                                                            handleMouseDown(
                                                                rowIndex,
                                                                4,
                                                                shipments
                                                            )
                                                        }
                                                        onMouseEnter={() =>
                                                            handleMouseEnter(
                                                                rowIndex,
                                                                4,
                                                                shipments
                                                            )
                                                        }
                                                        onClick={() =>
                                                            handleSingleClick(
                                                                rowIndex,
                                                                4,
                                                                shipments
                                                            )
                                                        }
                                                        className={`${
                                                            selectedCells.some(
                                                                (cell) =>
                                                                    cell.rowIndex ===
                                                                        rowIndex &&
                                                                    cell.columnIndex ===
                                                                        4
                                                            )
                                                                ? "bg-blue-200 select-none"
                                                                : "select-none"
                                                        }`}
                                                    >
                                                        {formatMoney(
                                                            shipment.shipment_value
                                                        )}
                                                    </td>
                                                    <td className="py-2.5">
                                                        {shipment.notes}
                                                    </td>
                                                    <td
                                                        onClick={(e) =>
                                                            handleDownload(
                                                                e,
                                                                shipment.shipment_id
                                                            )
                                                        }
                                                        className="text-right whitespace-nowrap pr-2 cursor-pointer space-x-2"
                                                    >
                                                        <PrinterIcon className="w-5 h-5 text-accent inline-block" />
                                                    </td>
                                                </>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {shipmentResults && (
                            <nav
                                aria-label="Pagination"
                                className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                            >
                                <div className="hidden sm:block">
                                    <div className="flex flex-row items-center space-x-3">
                                        <PaginationResults
                                            results={shipmentResults}
                                        />
                                        <AdminLimitSelector
                                            limit={limit}
                                            setLimit={setLimit}
                                            setOffset={setOffset}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-1 justify-between sm:justify-end">
                                    <button
                                        onClick={() =>
                                            setOffset(
                                                (prevOffset) => prevOffset - 1
                                            )
                                        }
                                        disabled={offset === 0}
                                        className={`${
                                            offset === 0
                                                ? "pointer-events-none text-gray-400 ring-gray-200"
                                                : "text-gray-900 ring-gray-300"
                                        } relative inline-flex items-center rounded-md bg-white px-3 py-1.5 text-xs font-semibold ring-1 ring-inset hover:bg-gray-50 focus-visible:outline-offset-0`}
                                    >
                                        Previous
                                    </button>
                                    <button
                                        onClick={() =>
                                            setOffset(
                                                (prevOffset) => prevOffset + 1
                                            )
                                        }
                                        disabled={
                                            shipmentResults.showing_to >=
                                            shipmentResults.total_results
                                        }
                                        className={`${
                                            shipmentResults.showing_to >=
                                            shipmentResults.total_results
                                                ? "pointer-events-none text-gray-400 ring-gray-200"
                                                : "text-gray-900 ring-gray-300"
                                        } relative inline-flex items-center rounded-md bg-white px-3 py-1.5 text-xs font-semibold ring-1 ring-inset hover:bg-gray-50 focus-visible:outline-offset-0 ml-3`}
                                    >
                                        Next
                                    </button>
                                </div>
                            </nav>
                        )}
                        {selectedCells.length > 0 && (
                            <span className="text-sm text-gray-900 flex flex-row items-center">
                                {"Sum: "}
                                {sumSelectedQuantities.map(
                                    ({ columnIndex, sum }) => (
                                        <>
                                            {sum > 0 && (
                                                <p
                                                    key={columnIndex}
                                                    className="pl-1"
                                                >
                                                    {columnIndex !== 4
                                                        ? sum
                                                        : formatMoney(sum)}
                                                </p>
                                            )}
                                        </>
                                    )
                                )}
                            </span>
                        )}
                    </>
                ) : (
                    <div className="flex flex-grow items-center pt-10 justify-center">
                        {loading ? (
                            <LoadingWheel />
                        ) : (
                            <span className="text-sm text-gray-500">
                                User has no outbound shipments
                            </span>
                        )}
                    </div>
                )}
            </>

            <OutboundOrdersModal
                shipment={selectedShipment}
                openOutboundOrders={openOutboundOrdersModal}
                setOpenOutboundOrders={setOpenOutboundOrdersModal}
                handleFullRefetch={handleFullRefetch}
                setShipments={setShipments}
            />
        </div>
    );
}

export default ClientShipmentsTable;
