/** @format */
import { LuArrowUpRight } from "react-icons/lu";

import { ArrowUpRightIcon } from "@heroicons/react/24/outline";

export function StatusTag({
    status,
    quantity_received,
    quantity,
    handleClick,
}: {
    status: string;
    quantity_received: number;
    quantity: number;
    handleClick?: (e: any) => void;
}) {
    if (status === "fully_received") {
        return (
            <span
                onClick={handleClick || (() => {})}
                className="text-green-600 font-semibold bg-green-100 py-1.5 px-2 rounded-md border border-transparent hover:bg-green-200 hover:border hover:border-green-400"
            >
                Fully Received
            </span>
        );
    } else if (status === "partially_received") {
        return (
            <span
                onClick={handleClick || (() => {})}
                className="text-yellow-600 py-1.5 inline whitespace-nowrap font-semibold bg-yellow-100 px-2 rounded-md border border-transparent hover:bg-yellow-200 hover:border hover:border-yellow-400"
            >
                {quantity_received}/{quantity} received
                <span className="inline-block align-middle">
                    <LuArrowUpRight className="h-4 w-4" />
                </span>
            </span>
        );
    } else {
        return (
            <span
                onClick={handleClick || (() => {})}
                className="text-gray-600 font-semibold bg-gray-100 py-1.5 px-2 rounded-md border border-transparent hover:bg-gray-200 hover:border hover:border-gray-400"
            >
                Pending
            </span>
        );
    }
}

export function PaymentStatusTag({
    status,
    value,
}: {
    status: string;
    value?: string;
}) {
    if (status === "paid") {
        return (
            <span className="text-green-600 font-semibold bg-green-100 py-1.5 px-2 rounded-md">
                Paid
            </span>
        );
    } else if (status === "unpaid") {
        return (
            <span className="text-yellow-600 font-semibold bg-yellow-100 py-1.5 px-2 rounded-md">
                Unpaid {value ? ` - ${value}` : ""}
            </span>
        );
    } else {
        return (
            <span className="text-gray-600 font-semibold bg-gray-100 py-1.5 px-2 rounded-md">
                Open
            </span>
        );
    }
}

export function ShipmentStatusTag({
    status,
    value,
}: {
    status: string;
    value?: string;
}) {
    if (status === "shipped") {
        return (
            <span className="text-green-600 font-semibold bg-green-100 py-1.5 px-2 rounded-md">
                Shipped
            </span>
        );
    } else if (status === "requested" || status === "pending") {
        return (
            <span className="text-yellow-600 font-semibold bg-yellow-100 py-1.5 px-2 rounded-md">
                Requested
            </span>
        );
    } else {
        return (
            <span className="text-gray-600 font-semibold bg-gray-100 py-1.5 px-2 rounded-md">
                Open
            </span>
        );
    }
}
