/** @format */

import { GoPackage } from "react-icons/go";
import { IoReturnDownBack, IoStorefrontOutline } from "react-icons/io5";

const features = [
    {
        name: "Order Fulfillment",
        description:
            "We help simplify operations with complete fulfillment services, handling everything from receiving and processing to listing and shipping. We primarily work with Amazon sellers, as well as other ecommerce businesses, managing their daily operations for smooth, efficient results.",
        href: "/order-fulfillment",
        icon: GoPackage, // Replace with appropriate icon
    },
    {
        name: "Return Management",
        description:
            "We provide return management services, efficiently managing your returns. We process, store, and reship unsellable inventory, while restocking sellable inventory back into your inventory",
        href: "/order-fulfillment",
        icon: IoReturnDownBack, // Replace with appropriate icon
    },
    {
        name: "Ecommerce Management",
        description:
            "Focus on growth while we handle the rest. At Urva, our expert ecommerce team manages your brand’s presence across major marketplaces like Amazon, Walmart, and more by optimizing your product listings and ensuring smooth operations.",
        href: "/ecommerce-management",
        icon: IoStorefrontOutline, // Replace with appropriate icon
    },
];

export default function Services() {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl px-6 lg:px-8 pt-16">
                <div className="flex flex-col items-center">
                    <div className="mx-auto max-w-2xl lg:mx-0">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">
                            What we offer
                        </h2>
                    </div>
                </div>

                <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                        {features.map((feature) => (
                            <div key={feature.name} className="flex flex-col">
                                <dt className="text-base font-semibold leading-7 text-gray-900">
                                    <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-accent">
                                        <feature.icon
                                            aria-hidden="true"
                                            className="h-6 w-6 text-white"
                                        />
                                    </div>
                                    {feature.name}
                                </dt>
                                <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
                                    <p className="flex-auto">
                                        {feature.description}
                                    </p>
                                    <p className="mt-6">
                                        <a
                                            href={feature.href}
                                            className="text-sm font-semibold leading-6 text-accent"
                                        >
                                            Learn more{" "}
                                            <span aria-hidden="true">→</span>
                                        </a>
                                    </p>
                                </dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    );
}
