/** @format */

import { useState } from "react";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
    InputField,
    InputNumberField,
} from "../../../../../../components/inputField";
import LoadingWheel from "../../../../../../components/loadingWheel";
import ClientInboundTable from "../client-orders-data/clientInboundTable";
import { modifyInboundOrders } from "../../../api/unitsApi";
import { useNotification } from "../../../../../../utils/notification/notificationContext";
import dayjs, { Dayjs } from "dayjs";
import SingleCalendar from "../../../../../../components/singleCalendar";
import { formatDateFromObject } from "../../../../../../utils/date/date_formatter";
import {
    Order,
    ShelfLocations,
    ShipmentQuantities,
} from "../../../../../../models/order";
import { MultipleFileUpload } from "../../../../../../utils/files/multipleFileUpload";
import { useParams } from "react-router-dom";

export default function ModifyInboundOrderModal({
    selectedInboundOrders,
    openModifyInboundOrder,
    setOpenModifyInboundOrder,
    handleFullRefetch,
}: {
    selectedInboundOrders: Order[];
    openModifyInboundOrder: boolean;
    setOpenModifyInboundOrder: React.Dispatch<React.SetStateAction<boolean>>;
    handleFullRefetch: () => void;
}) {
    const { user_id } = useParams();
    const { showNotification } = useNotification();
    const [loading, setLoading] = useState(false);

    const [notes, setNotes] = useState("");
    const [checkinDate, setCheckinDate] = useState<Dayjs>(dayjs());
    const [filesByOrder, setFilesByOrder] = useState<{
        [orderId: string]: File[];
    }>({});

    const [shipmentQuantities, setShipmentQuantities] =
        useState<ShipmentQuantities>({});

    const [shelfs, setShelfs] = useState<ShelfLocations>({});

    const handleQuantityChange = (orderId: string, value: string) => {
        setShipmentQuantities((prevQuantities) => ({
            ...prevQuantities,
            [orderId]: value,
        }));
    };

    const handleShelfChange = (orderId: string, value: string) => {
        setShelfs((prevQuantities) => ({
            ...prevQuantities,
            [orderId]: value,
        }));
    };

    const handleFileChange = (orderId: string, newFiles: File[]) => {
        setFilesByOrder((prevFiles) => ({
            ...prevFiles,
            [orderId]: prevFiles[orderId]
                ? [...prevFiles[orderId], ...newFiles] // Append multiple files
                : [...newFiles], // Create a new array if none exist
        }));
    };

    const removeFile = (orderId: string, fileToRemove: File) => {
        setFilesByOrder((prevFiles) => ({
            ...prevFiles,
            [orderId]: prevFiles[orderId]
                ? prevFiles[orderId].filter((file) => file !== fileToRemove) // Remove the specific file
                : [], // If no files exist for that orderId, return an empty array
        }));
    };

    const handleModifyOrder = async () => {
        setLoading(true);
        const data = await modifyInboundOrders(
            user_id,
            selectedInboundOrders,
            notes,
            formatDateFromObject(checkinDate),
            shipmentQuantities,
            filesByOrder,
            shelfs
        );
        if (data.status === "success") {
            showNotification("Modified inbound order(s)", "", "success");
            setOpenModifyInboundOrder(false);
            handleFullRefetch();
        } else {
            showNotification("Error modifying order(s)", data.message, "error");
        }
        setLoading(false);
    };

    return (
        <Dialog
            open={openModifyInboundOrder}
            onClose={() => setOpenModifyInboundOrder(false)}
            className="relative z-10"
        >
            <DialogBackdrop
                transition
                className="overflow-y-visible fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen">
                <div className="h-[90%] flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-[90%] w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-7xl sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() => setOpenModifyInboundOrder(false)}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-5"
                        >
                            Modify shipment status
                        </DialogTitle>
                        <div className="w-full h-px bg-gray-200"></div>

                        <div className="pt-4"></div>

                        <div className="flex-1 mb-4 overflow-y-auto overflow-x-auto w-full">
                            <div className="flex flex-col w-full">
                                <div className="flex flex-col sm:flex-row w-full space-y-3 sm:space-y-0 sm:space-x-4 sm:items-end">
                                    <div className="w-96">
                                        <SingleCalendar
                                            selectedDate={checkinDate}
                                            setSelectedDate={setCheckinDate}
                                            label="Check in date"
                                        />
                                    </div>
                                    <div className="w-full pt-0.5">
                                        <InputField
                                            label="Notes"
                                            value={notes}
                                            onChange={(e) =>
                                                setNotes(e.target.value)
                                            }
                                            placeholder=""
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="text-sm pt-5">
                                <span>
                                    {selectedInboundOrders.length} order
                                    selected
                                </span>
                            </div>

                            <ClientInboundTable
                                inboundOrders={selectedInboundOrders}
                                shipmentQuantities={shipmentQuantities}
                                handleQuantityChange={handleQuantityChange}
                                shelfs={shelfs}
                                handleShelfChange={handleShelfChange}
                                filesByOrder={filesByOrder}
                                handleFileChange={handleFileChange}
                                removeFile={removeFile}
                            />
                        </div>

                        {/* Fixed buttons at the bottom */}
                        <div className="pt-4 sm:pt-0 sm:flex sm:flex-row-reverse">
                            <button
                                onClick={() => handleModifyOrder()}
                                type="button"
                                className="inline-flex w-full justify-center rounded-md bg-accent px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-accenthighlight sm:ml-3 sm:w-auto items-center"
                            >
                                {loading ? (
                                    <span className="pr-2 items-center flex flex-row justify-center">
                                        <LoadingWheel
                                            size="small"
                                            color="white"
                                        />
                                    </span>
                                ) : (
                                    <></>
                                )}
                                Confirm changes
                            </button>
                            <button
                                type="button"
                                onClick={() => setOpenModifyInboundOrder(false)}
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            >
                                Cancel
                            </button>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}
