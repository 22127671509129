/** @format */

import { useEffect, useState } from "react";
import LoadingWheel, {
    SortLoading,
} from "../../../../../components/loadingWheel";
import {
    convertDateToEST,
    formatDateFromDb,
} from "../../../../../utils/date/date_formatter";
import { PaginationResults } from "../../../../../utils/pagination/paginationResults";
import { fetchAllShipmentTrackings } from "../../api/shipmentsApi";
import Results from "../../../../../models/results";
import { TrackingArrived } from "../../../../../components/trackingArrived";
import { useNotification } from "../../../../../utils/notification/notificationContext";
import { useParams } from "react-router-dom";
import { TrashIcon } from "@heroicons/react/24/outline";
import { AdminLimitSelector } from "../../../../../utils/pagination/limitSelector";
import { sortableColumns, useSort } from "../../../../../hooks/useSort";
import useScrollTranslation from "../../../../../hooks/useScrollTranslation";
import ColumnSort from "../../../../../utils/sort/columnSort";

const tableHeaders = [
    {
        key: "created_at",
        label: "Date Shipped",
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "tracking",
        label: "Tracking number",
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "carrier",
        label: "Detected Carrier",
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "tracking_url",
        label: "URL",
        className: "sm:min-w-32 min-w-28",
    },
];

interface ShipmentTrackingsTableProps {
    trackingsArrived: TrackingArrived[];
    setTrackingsArrived: React.Dispatch<
        React.SetStateAction<TrackingArrived[]>
    >;
}

function ShipmentTrackingsTable({
    trackingsArrived,
    setTrackingsArrived,
}: ShipmentTrackingsTableProps) {
    const { user_id } = useParams();

    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [loading, setLoading] = useState(false);
    const { showNotification } = useNotification();

    const [trackingsArrivedResults, setTrackingsArrivedResults] =
        useState<Results>({
            showing_from: 0,
            showing_to: 0,
            total_results: 0,
            current_page: 0,
        });

    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(0);

    const [refetchData, setRefetchData] = useState<number>(0);
    const handleRefetch = () => {
        setRefetchData((prevValue) => prevValue + 1);
    };

    const fetchData = async () => {
        const data = await fetchAllShipmentTrackings(
            user_id,
            offset,
            limit,
            sortConfig
        );
        if (data.status === "success") {
            setTrackingsArrived(data.data.trackings);
            setTrackingsArrivedResults(data.data.results);
        } else {
            showNotification("Failed fetching orders", data.message, "error");
        }
        setLoading(false);
        setSortLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        fetchData();
    }, [offset, refetchData, limit]);

    useEffect(() => {
        fetchData();
    }, [sortConfig]);

    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [selectedTrackingId, setSelectedTrackingId] = useState<string | null>(
        ""
    );
    useEffect(() => {
        if (selectedTrackingId) {
            setOpenDeleteModal(true);
        }
    }, [selectedTrackingId]);

    useEffect(() => {
        if (!openDeleteModal) {
            setSelectedTrackingId(null);
        }
    }, [openDeleteModal]);

    const { scrollContainerRef, scrollOffset } = useScrollTranslation();

    return (
        <div className="flex flex-col pt-3">
            {!loading && trackingsArrived && trackingsArrived.length > 0 ? (
                <>
                    <div
                        ref={scrollContainerRef}
                        className={`max-h-[650px] overflow-y-auto overflow-x-scroll relative scrollbar ${
                            sortLoading
                                ? "pointer-events-none overflow-hidden"
                                : ""
                        }`}
                    >
                        <SortLoading
                            sortLoading={sortLoading}
                            scrollOffset={scrollOffset}
                        />
                        <table className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full">
                            <thead className="sticky top-0 bg-white z-10">
                                <tr className="text-left text-sm">
                                    {tableHeaders.map((header) => (
                                        <th
                                            key={header.key}
                                            onClick={() =>
                                                sortableColumns.includes(
                                                    header.key
                                                ) && toggleSort(header.key)
                                            }
                                            className={`group py-2 cursor-pointer ${
                                                sortableColumns.includes(
                                                    header.key
                                                )
                                                    ? "hover:bg-gray-100"
                                                    : ""
                                            } ${header.className}`}
                                        >
                                            {header.label}
                                            <ColumnSort
                                                header={header}
                                                sortConfig={sortConfig}
                                            />
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {trackingsArrived.map((tracking, index) => (
                                    <tr
                                        key={index}
                                        className="relative border-t border-gray-200 text-sm text-gray-500"
                                    >
                                        <td className="py-2.5">
                                            {formatDateFromDb(tracking.date)}
                                        </td>
                                        <td className="py-2.5">
                                            {tracking.tracking}
                                        </td>
                                        <td className="">{tracking.carrier}</td>
                                        {tracking.tracking_url !== "Unknown" ? (
                                            <td className="">
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={tracking.tracking_url}
                                                    className="text-accent"
                                                >
                                                    View
                                                </a>
                                            </td>
                                        ) : (
                                            <td>Undetermined</td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <nav
                        aria-label="Pagination"
                        className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                    >
                        <div className="hidden sm:block">
                            <div className="flex flex-row items-center space-x-3">
                                <PaginationResults
                                    results={trackingsArrivedResults}
                                />
                                <AdminLimitSelector
                                    limit={limit}
                                    setLimit={setLimit}
                                    setOffset={setOffset}
                                />
                            </div>
                        </div>
                        <div className="flex flex-1 justify-between sm:justify-end">
                            <button
                                onClick={() =>
                                    setOffset((prevOffset) => prevOffset - 1)
                                }
                                disabled={offset === 0}
                                className={`${
                                    offset === 0
                                        ? "pointer-events-none text-gray-400 ring-gray-200"
                                        : "text-gray-900 ring-gray-300"
                                } relative inline-flex items-center rounded-md bg-white px-3 py-1.5 text-xs font-semibold ring-1 ring-inset hover:bg-gray-50 focus-visible:outline-offset-0`}
                            >
                                Previous
                            </button>
                            <button
                                onClick={() =>
                                    setOffset((prevOffset) => prevOffset + 1)
                                }
                                disabled={
                                    trackingsArrivedResults.showing_to >=
                                    trackingsArrivedResults.total_results
                                }
                                className={`${
                                    trackingsArrivedResults.showing_to >=
                                    trackingsArrivedResults.total_results
                                        ? "pointer-events-none text-gray-400 ring-gray-200"
                                        : "text-gray-900 ring-gray-300"
                                } relative inline-flex items-center rounded-md bg-white px-3 py-1.5 text-xs font-semibold ring-1 ring-inset hover:bg-gray-50 focus-visible:outline-offset-0 ml-3`}
                            >
                                Next
                            </button>
                        </div>
                    </nav>
                </>
            ) : (
                <div className="flex flex-grow items-center pt-44 justify-center">
                    {loading ? (
                        <LoadingWheel />
                    ) : (
                        <span className="text-sm text-gray-500">
                            User has no trackings added
                        </span>
                    )}
                </div>
            )}
        </div>
    );
}

export default ShipmentTrackingsTable;
