/** @format */

import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Order } from "../../../../../models/order";
import Results from "../../../../../models/results";
import { useCheckInDatesModal } from "../../../../../hooks/useCheckInDates";
import { StatusTag } from "../../../../../components/statusTags";
import { formatDateFromDb } from "../../../../../utils/date/date_formatter";
import { formatMoney } from "../../../../../utils/price/formatMoney";
import { PaginationResults } from "../../../../../utils/pagination/paginationResults";
import { fetchClientOrders } from "../../api/unitsApi";
import { useNotification } from "../../../../../utils/notification/notificationContext";
import CheckInDatesModal from "../../../../../components/modals/checkInDatesModal";
import LoadingWheel, {
    SortLoading,
} from "../../../../../components/loadingWheel";
import { AdminClientTypesRefs } from "../../../../../models/refsModels";
import { capitalizeFirstLetter } from "../../../../../utils/strings/string";
import LimitSelector from "../../../../../utils/pagination/limitSelector";
import useScrollTranslation from "../../../../../hooks/useScrollTranslation";
import { sortableColumns, useSort } from "../../../../../hooks/useSort";
import ColumnSort from "../../../../../utils/sort/columnSort";
import useCellSelection from "../../../../../hooks/useCellSelection";

const tableHeaders = [
    {
        key: "status",
        label: "Status",
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "fulfilled_by",
        label: "Fulfilled by",
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "quantity_remaining",
        label: "Remaning Quantity",
        className: "sm:min-w-44 min-w-32",
    },
    {
        key: "created_at",
        label: "Date",
        className: "sm:min-w-32 min-w-28 pl-2",
    },
    { key: "retailer", label: "Retailer", className: "min-w-32" },
    { key: "order_number", label: "Order #", className: "min-w-32" },
    { key: "item_name", label: "Item Name", className: "sm:min-w-48 min-w-48" },
    { key: "brand_sku", label: "Brand SKU", className: "sm:min-w-32 min-w-28" },
    { key: "size", label: "Size", className: "sm:min-w-32 min-w-28" },
    { key: "quantity", label: "Quantity", className: "sm:min-w-32 min-w-28" },
    { key: "color", label: "Color", className: "sm:min-w-32 min-w-28 pl-2" },
    { key: "asin", label: "ASIN", className: "sm:min-w-32 min-w-28 pl-2" },
    {
        key: "amazon_sku",
        label: "Amazon SKU",
        className: "sm:min-w-32 min-w-28",
    },
    {
        key: "unit_cost",
        label: "Unit cost",
        className: "sm:min-w-32 min-w-28",
    },
    {
        key: "list_price",
        label: "List price",
        className: "sm:min-w-32 min-w-28",
    },
    {
        key: "shipment_value",
        label: "Shipment Value",
        className: "sm:min-w-32 min-w-28",
    },
    { key: "notes", label: "Notes", className: "sm:min-w-32 min-w-28" },
];

interface ClientInventoryTableProps {
    inventoryOrders: Order[];
    setInventoryOrders: React.Dispatch<React.SetStateAction<Order[]>>;
    inventoryResults: Results;
    setInventoryResults: React.Dispatch<React.SetStateAction<Results>>;
    selectedOrders?: Order[];
    setSelectedOrders?: React.Dispatch<React.SetStateAction<Order[]>>;
    pageRefs: AdminClientTypesRefs;
    handleFullRefetch: () => void;
}

function ClientInventoryTable({
    inventoryOrders,
    setInventoryOrders,
    inventoryResults,
    setInventoryResults,
    selectedOrders,
    setSelectedOrders,
    pageRefs,
    handleFullRefetch,
}: ClientInventoryTableProps) {
    const { user_id } = useParams();
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const { showNotification } = useNotification();

    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const {
        openCheckInDatesModal,
        setOpenCheckInDatesModal,
        selectedOrder,
        handleViewDates,
    } = useCheckInDatesModal();

    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(0);

    const fetchData = async () => {
        const data = await fetchClientOrders(
            user_id,
            "inventory",
            undefined,
            offset,
            searchParams.get("limit") ? Number(searchParams.get("limit")) : -1,
            sortConfig
        );
        if (data.status === "success") {
            setInventoryOrders(data.data.orders);
            setInventoryResults(data.data.results);
        } else {
            showNotification("Failed fetching orders", data.message, "error");
        }
        setLoading(false);
        setSortLoading(false);
        pageRefs.inventoryRef.current = true;
    };

    useEffect(() => {
        if (!pageRefs.inventoryRef.current || offset !== 0 || limit !== 0) {
            setLoading(true);
        }
        fetchData();
    }, [offset, limit, sortConfig]);

    const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (setSelectedOrders) {
            if (e.target.checked) {
                setSelectedOrders(inventoryOrders);
            } else {
                // setAllClientsSelected(false);
                setSelectedOrders([]);
            }
        }
    };

    const handleCheckboxChange = (order: Order) => {
        const orderId = order.order_id;
        if (selectedOrders && setSelectedOrders) {
            if (selectedOrders.some((o) => o.order_id === orderId)) {
                // Remove the order by its id
                setSelectedOrders(
                    selectedOrders.filter((o) => o.order_id !== orderId)
                );
            } else {
                // Add the order using its id
                setSelectedOrders([...selectedOrders, order]);
            }
        }
    };

    const { scrollContainerRef, scrollOffset } = useScrollTranslation();

    const getCellValue = (
        rowIndex: number,
        columnIndex: number,
        data: any[]
    ) => {
        const order = data[rowIndex];
        switch (columnIndex) {
            case 3:
                return order.quantity_remaining;
            case 10:
                return order.quantity;
            case 14:
                return order.unit_cost;
            case 15:
                return order.list_price;
            case 16:
                return order.shipment_value;
            default:
                return 0;
        }
    };

    const {
        selectedCells,
        handleSingleClick,
        handleMouseDown,
        handleMouseEnter,
        handleMouseUp,
        sumSelectedQuantities,
    } = useCellSelection([], getCellValue, [3, 10, 14, 15, 16]);

    return (
        <div className="flex flex-col pt-3">
            {!loading && inventoryOrders && inventoryOrders.length > 0 ? (
                <>
                    <div
                        ref={scrollContainerRef}
                        className={`max-h-[650px] overflow-y-auto overflow-x-scroll relative scrollbar ${
                            sortLoading
                                ? "pointer-events-none overflow-hidden"
                                : ""
                        }`}
                    >
                        <SortLoading
                            sortLoading={sortLoading}
                            scrollOffset={scrollOffset}
                        />
                        <table
                            onMouseUp={handleMouseUp}
                            className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full"
                        >
                            <thead className="sticky top-0 bg-white z-10">
                                <tr className="text-left text-sm">
                                    {selectedOrders ? (
                                        <th className="py-2 min-w-10">
                                            <input
                                                type="checkbox"
                                                className="h-4 w-4 rounded border-gray-300 text-accent focus:ring-0 focus:ring-transparent ring-0 focus:ring-offset-0 focus:outline transition-all"
                                                onClick={(e) =>
                                                    e.stopPropagation()
                                                }
                                                onChange={handleSelectAll}
                                                checked={
                                                    selectedOrders &&
                                                    inventoryOrders.length ===
                                                        selectedOrders.length &&
                                                    inventoryOrders.length > 0
                                                }
                                            />
                                        </th>
                                    ) : (
                                        <></>
                                    )}
                                    {tableHeaders.map((header) => (
                                        <th
                                            key={header.key}
                                            onClick={() =>
                                                sortableColumns.includes(
                                                    header.key
                                                ) && toggleSort(header.key)
                                            }
                                            className={`group py-2 cursor-pointer ${
                                                sortableColumns.includes(
                                                    header.key
                                                )
                                                    ? "hover:bg-gray-100"
                                                    : ""
                                            } ${header.className}`}
                                        >
                                            {header.label}
                                            <ColumnSort
                                                header={header}
                                                sortConfig={sortConfig}
                                            />
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="relative">
                                {inventoryOrders.map((order, rowIndex) => (
                                    <tr
                                        key={order.order_id}
                                        className="relative border-t border-gray-200 text-sm text-gray-500"
                                    >
                                        {selectedOrders ? (
                                            <td className="py-3 flex items-center">
                                                <input
                                                    type="checkbox"
                                                    className="h-4 w-4 rounded border-gray-300 text-accent focus:ring-0 focus:ring-transparent ring-0 focus:ring-offset-0 focus:outline transition-all"
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            order
                                                        )
                                                    }
                                                    checked={selectedOrders.some(
                                                        (o) =>
                                                            o.order_id ===
                                                            order.order_id
                                                    )}
                                                />
                                            </td>
                                        ) : (
                                            <></>
                                        )}
                                        <td className="py-2.5 pr-4 whitespace-nowrap">
                                            <StatusTag
                                                status={order.unit_status}
                                                quantity_received={
                                                    order.quantity_received
                                                }
                                                quantity={order.quantity}
                                                handleClick={(e) => {
                                                    e.stopPropagation();
                                                    handleViewDates(order);
                                                }}
                                            />
                                        </td>
                                        <td>
                                            {capitalizeFirstLetter(
                                                order.fulfilled_by
                                            )}
                                        </td>
                                        <td
                                            onMouseDown={() =>
                                                handleMouseDown(
                                                    rowIndex,
                                                    3,
                                                    inventoryOrders
                                                )
                                            }
                                            onMouseEnter={() =>
                                                handleMouseEnter(
                                                    rowIndex,
                                                    3,
                                                    inventoryOrders
                                                )
                                            }
                                            onClick={() =>
                                                handleSingleClick(
                                                    rowIndex,
                                                    3,
                                                    inventoryOrders
                                                )
                                            }
                                            className={`${
                                                selectedCells.some(
                                                    (cell) =>
                                                        cell.rowIndex ===
                                                            rowIndex &&
                                                        cell.columnIndex === 3
                                                )
                                                    ? "bg-blue-200 select-none"
                                                    : "select-none"
                                            }`}
                                        >
                                            {order.quantity_remaining}
                                        </td>
                                        <td className="pl-2">
                                            {formatDateFromDb(order.date)}
                                        </td>
                                        <td className="">{order.retailer}</td>
                                        <td className="">
                                            {order.order_number}
                                        </td>
                                        <td className="">{order.item_name}</td>
                                        <td className="">{order.brand_sku}</td>
                                        <td className="">{order.size}</td>
                                        <td
                                            onMouseDown={() =>
                                                handleMouseDown(
                                                    rowIndex,
                                                    10,
                                                    inventoryOrders
                                                )
                                            }
                                            onMouseEnter={() =>
                                                handleMouseEnter(
                                                    rowIndex,
                                                    10,
                                                    inventoryOrders
                                                )
                                            }
                                            onClick={() =>
                                                handleSingleClick(
                                                    rowIndex,
                                                    10,
                                                    inventoryOrders
                                                )
                                            }
                                            className={`${
                                                selectedCells.some(
                                                    (cell) =>
                                                        cell.rowIndex ===
                                                            rowIndex &&
                                                        cell.columnIndex === 10
                                                )
                                                    ? "bg-blue-200 select-none"
                                                    : "select-none"
                                            }`}
                                        >
                                            {order.quantity}
                                        </td>
                                        <td className="pl-2">{order.color}</td>
                                        <td className="pl-2">{order.asin}</td>
                                        <td className="">{order.amazon_sku}</td>
                                        <td
                                            onMouseDown={() =>
                                                handleMouseDown(
                                                    rowIndex,
                                                    14,
                                                    inventoryOrders
                                                )
                                            }
                                            onMouseEnter={() =>
                                                handleMouseEnter(
                                                    rowIndex,
                                                    14,
                                                    inventoryOrders
                                                )
                                            }
                                            onClick={() =>
                                                handleSingleClick(
                                                    rowIndex,
                                                    14,
                                                    inventoryOrders
                                                )
                                            }
                                            className={`${
                                                selectedCells.some(
                                                    (cell) =>
                                                        cell.rowIndex ===
                                                            rowIndex &&
                                                        cell.columnIndex === 14
                                                )
                                                    ? "bg-blue-200 select-none"
                                                    : "select-none"
                                            }`}
                                        >
                                            {formatMoney(order.unit_cost)}
                                        </td>
                                        <td
                                            onMouseDown={() =>
                                                handleMouseDown(
                                                    rowIndex,
                                                    15,
                                                    inventoryOrders
                                                )
                                            }
                                            onMouseEnter={() =>
                                                handleMouseEnter(
                                                    rowIndex,
                                                    15,
                                                    inventoryOrders
                                                )
                                            }
                                            onClick={() =>
                                                handleSingleClick(
                                                    rowIndex,
                                                    15,
                                                    inventoryOrders
                                                )
                                            }
                                            className={`${
                                                selectedCells.some(
                                                    (cell) =>
                                                        cell.rowIndex ===
                                                            rowIndex &&
                                                        cell.columnIndex === 15
                                                )
                                                    ? "bg-blue-200 select-none"
                                                    : "select-none"
                                            }`}
                                        >
                                            {formatMoney(order.list_price)}
                                        </td>
                                        <td
                                            onMouseDown={() =>
                                                handleMouseDown(
                                                    rowIndex,
                                                    16,
                                                    inventoryOrders
                                                )
                                            }
                                            onMouseEnter={() =>
                                                handleMouseEnter(
                                                    rowIndex,
                                                    16,
                                                    inventoryOrders
                                                )
                                            }
                                            onClick={() =>
                                                handleSingleClick(
                                                    rowIndex,
                                                    16,
                                                    inventoryOrders
                                                )
                                            }
                                            className={`${
                                                selectedCells.some(
                                                    (cell) =>
                                                        cell.rowIndex ===
                                                            rowIndex &&
                                                        cell.columnIndex === 16
                                                )
                                                    ? "bg-blue-200 select-none"
                                                    : "select-none"
                                            }`}
                                        >
                                            {formatMoney(order.shipment_value)}
                                        </td>
                                        <td className="">{order.user_notes}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {inventoryResults && (
                        <>
                            <nav
                                aria-label="Pagination"
                                className="flex items-center justify-between border-t border-gray-200 bg-white pt-3 pb-1"
                            >
                                <div className="hidden sm:block">
                                    <div className="flex flex-row items-center space-x-3">
                                        <PaginationResults
                                            results={inventoryResults}
                                        />
                                        <LimitSelector />
                                    </div>
                                </div>
                                <div className="flex flex-1 justify-between sm:justify-end">
                                    <button
                                        onClick={() =>
                                            setOffset(
                                                (prevOffset) => prevOffset - 1
                                            )
                                        }
                                        disabled={offset === 0}
                                        className={`${
                                            offset === 0
                                                ? "pointer-events-none text-gray-400 ring-gray-200"
                                                : "text-gray-900 ring-gray-300"
                                        } relative inline-flex items-center rounded-md bg-white px-3 py-1.5 text-xs font-semibold ring-1 ring-inset hover:bg-gray-50 focus-visible:outline-offset-0`}
                                    >
                                        Previous
                                    </button>
                                    <button
                                        onClick={() =>
                                            setOffset(
                                                (prevOffset) => prevOffset + 1
                                            )
                                        }
                                        disabled={
                                            inventoryResults.showing_to >=
                                            inventoryResults.total_results
                                        }
                                        className={`${
                                            inventoryResults.showing_to >=
                                            inventoryResults.total_results
                                                ? "pointer-events-none text-gray-400 ring-gray-200"
                                                : "text-gray-900 ring-gray-300"
                                        } relative inline-flex items-center rounded-md bg-white px-3 py-1.5 text-xs font-semibold ring-1 ring-inset hover:bg-gray-50 focus-visible:outline-offset-0 ml-3`}
                                    >
                                        Next
                                    </button>
                                </div>
                            </nav>
                            {selectedOrders && selectedOrders?.length > 0 && (
                                <span className="text-gray-700 text-sm">
                                    {selectedOrders.length} shipment
                                    {selectedOrders.length > 1 && "s"} selected
                                </span>
                            )}
                            {selectedCells.length > 0 && (
                                <span className="text-sm text-gray-900 flex flex-row items-center">
                                    {"Sum: "}
                                    {sumSelectedQuantities.map(
                                        ({ columnIndex, sum }) => (
                                            <>
                                                {sum > 0 && (
                                                    <p
                                                        key={columnIndex}
                                                        className="pl-1"
                                                    >
                                                        {columnIndex !== 14 &&
                                                        columnIndex !== 15 &&
                                                        columnIndex !== 16
                                                            ? sum
                                                            : formatMoney(sum)}
                                                    </p>
                                                )}
                                            </>
                                        )
                                    )}
                                </span>
                            )}
                        </>
                    )}
                </>
            ) : (
                <div className="flex flex-grow items-center pt-10 justify-center">
                    {loading ? (
                        <LoadingWheel />
                    ) : (
                        <span className="text-sm text-gray-500">
                            User has no inventory orders
                        </span>
                    )}
                </div>
            )}

            <CheckInDatesModal
                openCheckInDatesModal={openCheckInDatesModal}
                setOpenCheckInDatesModal={setOpenCheckInDatesModal}
                selectedOrder={selectedOrder}
                handleRefetch={handleFullRefetch}
            />
        </div>
    );
}

export default ClientInventoryTable;
