/** @format */

import { useSearchParams } from "react-router-dom";
import { useNotification } from "../../../../utils/notification/notificationContext";
import { useSort } from "../../../../hooks/useSort";
import { useEffect, useState } from "react";
import { ShipmentPageSelector } from "../../../../models/pageSelectors";
import { fetchShipments, unrequestOutboundShipment } from "../api/outboundApi";
import ClientSideBar from "../../../../components/client/sideBar";
import Header from "../../../../components/header";
import ShipmentsTable from "../shipmentsTable";
import Results from "../../../../models/results";
import Shipment from "../../../../models/shipments";
import ClientSearchBar from "../../../../components/client/searchBar";

function OutboundRequestedPage() {
    const { showNotification } = useNotification();
    const [searchParams] = useSearchParams();

    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [shipments, setShipments] = useState<Shipment[]>([]);

    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [loading, setLoading] = useState(true);

    const [refetchData, setRefetchData] = useState<number>(0);
    const handleRefetch = () => {
        setRefetchData((prevValue) => prevValue + 1);
    };

    const fetchData = async () => {
        const data = await fetchShipments(
            searchParams,
            sortConfig,
            "requested"
        );
        if (data.status === "success") {
            setShipments(data.data.shipments);
            setResults(data.data.results);
        } else {
            showNotification("Failed fetching data", data.message, "error");
        }
        setLoading(false);
        setSortLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        fetchData();
    }, [refetchData]);

    useEffect(() => {
        fetchData();
    }, [sortConfig]);

    return (
        <div className="flex flex-row">
            <ClientSideBar />
            <div className="flex flex-col flex-grow overflow-x-auto sm:px-10 px-4">
                <div className="">
                    <Header />
                </div>

                <div className="pt-10 flex flex-col overflow-x-auto">
                    <span className="text-2xl font-bold">Outbound</span>

                    <div className="py-4">
                        <ClientSearchBar
                            setData={setShipments}
                            setLoading={setLoading}
                            handleRefetch={handleRefetch}
                            page="outbound"
                            sub_page="requested"
                        />
                    </div>

                    <ShipmentsTable
                        shipments={shipments}
                        results={results}
                        loading={loading}
                        pageSelected="requested"
                        sortConfig={sortConfig}
                        toggleSort={toggleSort}
                        sortLoading={sortLoading}
                        handleRefetch={handleRefetch}
                    />
                </div>
            </div>
        </div>
    );
}

export default OutboundRequestedPage;
