/** @format */

import { useEffect, useRef, useState } from "react";
import ClientSideBar from "../../../../components/client/sideBar";
import InventoryTable from "./../inventory-data/inventoryTable";
import Header from "../../../../components/header";
import { fetchInventoryOrders } from "./../api/inventoryApi";
import Results from "../../../../models/results";
import { useNotification } from "../../../../utils/notification/notificationContext";
import { Order } from "../../../../models/order";
import { useSearchParams } from "react-router-dom";
import { RequestOutboundShipmentModal } from ".././inventory-data/request-shipment/requestOutboundShipmentModal";
import { useSort } from "../../../../hooks/useSort";
import ClientSearchBar from "../../../../components/client/searchBar";
import { BulkEditInventoryOrdersModal } from "../inventory-data/bulkEditInventoryOrders";

function InventoryUnitsPage() {
    const { showNotification } = useNotification();

    const [searchParams] = useSearchParams();

    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [loading, setLoading] = useState(true);

    const [inventoryOrders, setInventoryOrders] = useState<Order[]>([]);
    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [refetchData, setRefetchData] = useState<number>(0);
    const handleRefetch = () => {
        setRefetchData((prevValue) => prevValue + 1);
    };

    const hasFetchedOrders = useRef(false);
    const fetchData = async () => {
        const data = await fetchInventoryOrders(searchParams, sortConfig);
        if (data.status === "success") {
            setInventoryOrders(data.data.inventory);
            setResults(data.data.results);
        } else {
            showNotification("Failed fetching data", data.message, "error");
        }
        setLoading(false);
        setSortLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        fetchData();
    }, [refetchData]);

    useEffect(() => {
        if (hasFetchedOrders.current) {
            setSortLoading(true);
            fetchData();
        }
    }, [sortConfig]);

    const [selectedOrders, setSelectedOrders] = useState<Order[]>([]);
    const [
        openRequestOutboundShipmentModal,
        setOpenRequestOutboundShipmentModal,
    ] = useState(false);

    const [openBulkEditModal, setOpenBulkEditModal] = useState(false);

    return (
        <div className="flex flex-row">
            <ClientSideBar />
            <div className="flex flex-col flex-grow overflow-x-auto">
                <div className="sm:px-10 px-4">
                    <Header />
                </div>
                <div className="mx-4 sm:mx-10 pt-10 flex flex-col flex-grow overflow-x-auto">
                    <div className="flex flex-row items-center justify-between">
                        <span className="text-2xl font-bold">Inventory</span>
                        <button
                            disabled={selectedOrders.length < 1}
                            onClick={
                                selectedOrders.length > 0 &&
                                selectedOrders[0].fulfilled_by === "merchant"
                                    ? () =>
                                          setOpenRequestOutboundShipmentModal(
                                              true
                                          )
                                    : () =>
                                          showNotification(
                                              "Do not select amazon fulfilled orders.",
                                              "You can only select merchant for requests.",
                                              "error"
                                          )
                            }
                            className={`${
                                selectedOrders.length < 1
                                    ? "bg-accentdim"
                                    : "bg-accent hover:bg-accenthighlight"
                            } text-white font-medium px-5 text-sm rounded-md h-8`}
                        >
                            Request Outbound Shipment
                        </button>
                    </div>

                    <div className="flex flex-row items-center py-4 space-x-4">
                        <ClientSearchBar
                            setData={setInventoryOrders}
                            setLoading={setLoading}
                            handleRefetch={handleRefetch}
                            page="inventory"
                            sub_page="units"
                        />
                        <button
                            onClick={() => setOpenBulkEditModal(true)}
                            disabled={selectedOrders.length < 1}
                            className={`flex h-8 flex-row items-center text-xs px-4 shadow-sm rounded-md text-black ${
                                selectedOrders.length > 0
                                    ? "hover:bg-gray-50 bg-white border border-gray-400"
                                    : "bg-gray-50 border border-gray-200"
                            }`}
                        >
                            Edit
                        </button>
                    </div>

                    <InventoryTable
                        inventoryOrders={inventoryOrders}
                        results={results}
                        loading={loading}
                        selectedOrders={selectedOrders}
                        setSelectedOrders={setSelectedOrders}
                        handleRefetch={handleRefetch}
                        sortConfig={sortConfig}
                        toggleSort={toggleSort}
                        sortLoading={sortLoading}
                    />
                </div>
            </div>

            <RequestOutboundShipmentModal
                selectedOrders={selectedOrders}
                openRequestOutboundShipmentModal={
                    openRequestOutboundShipmentModal
                }
                setOpenRequestOutboundShipmentModal={
                    setOpenRequestOutboundShipmentModal
                }
                handleRefetch={handleRefetch}
            />

            <BulkEditInventoryOrdersModal
                orderIds={selectedOrders.map((order) => order.order_id)}
                openEditModal={openBulkEditModal}
                setOpenEditModal={setOpenBulkEditModal}
                handleRefetch={handleRefetch}
            />
        </div>
    );
}

export default InventoryUnitsPage;
