/** @format */

import { useEffect, useRef, useState } from "react";
import ClientSideBar from "../../../../components/client/sideBar";
import Header from "../../../../components/header";
import Results from "../../../../models/results";
import { useNotification } from "../../../../utils/notification/notificationContext";
import { TrackingArrived } from "../../../../components/trackingArrived";
import { fetchTrackings } from "./../api/trackingsApi";
import TrackingsArrivedTable from "./../tracking-data/trackingsTable";
import { useSearchParams } from "react-router-dom";
import { useSort } from "../../../../hooks/useSort";
import ClientSearchBar from "../../../../components/client/searchBar";

function InventoryTrackingsPage() {
    const { showNotification } = useNotification();

    const [searchParams] = useSearchParams();

    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [loading, setLoading] = useState(true);

    const [trackingsArrived, setTrackingsArrived] = useState<TrackingArrived[]>(
        []
    );
    const [trackingsResults, setTrackingsResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [refetchData, setRefetchData] = useState<number>(0);
    const handleRefetch = () => {
        setRefetchData((prevValue) => prevValue + 1);
    };

    const hasFetchedTrackings = useRef(false);

    const fetchTrackingsData = async () => {
        const data = await fetchTrackings(searchParams, sortConfig);
        if (data.status === "success") {
            setTrackingsArrived(data.data.trackings);
            setTrackingsResults(data.data.results);
            hasFetchedTrackings.current = true;
        } else {
            showNotification("Failed fetching data", data.message, "error");
        }
        setLoading(false);
        setSortLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        fetchTrackingsData();
    }, []);

    useEffect(() => {
        if (hasFetchedTrackings.current) {
            setSortLoading(true);
            fetchTrackingsData();
        }
    }, [sortConfig]);

    const [
        openRequestOutboundShipmentModal,
        setOpenRequestOutboundShipmentModal,
    ] = useState(false);

    return (
        <div className="flex flex-row">
            <ClientSideBar />
            <div className="flex flex-col flex-grow overflow-x-auto">
                <div className="sm:px-10 px-4">
                    <Header />
                </div>
                <div className="mx-4 sm:mx-10 pt-10 flex flex-col flex-grow overflow-x-auto">
                    <div className="flex flex-row items-center justify-between">
                        <span className="text-2xl font-bold">Inventory</span>
                    </div>

                    <div className="py-4">
                        <ClientSearchBar
                            setData={setTrackingsArrived}
                            setLoading={setLoading}
                            handleRefetch={handleRefetch}
                            page="inventory"
                            sub_page="units"
                        />
                    </div>

                    <TrackingsArrivedTable
                        trackings={trackingsArrived}
                        results={trackingsResults}
                        loading={loading}
                        handleRefetch={handleRefetch}
                        sortConfig={sortConfig}
                        toggleSort={toggleSort}
                        sortLoading={sortLoading}
                    />
                </div>
            </div>
        </div>
    );
}

export default InventoryTrackingsPage;
