import Cookies from "js-cookie"
import Results from "../../../../models/results"
import { Order } from "../../../../models/order"
import { buildUrl } from "../../../../utils/url/buildUrl";
import { SortConfig } from "../../../../hooks/useSort";


export const fetchInboundOrders = async (searchParams: URLSearchParams, sortConfig: SortConfig) => {
    try {
        const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/inbound`;
        const params = {
            offset: searchParams.get('offset'),
            limit: searchParams.get('limit'),
            sort_key: sortConfig.key,
            sort_order: sortConfig.order,
        };

        const url = buildUrl(baseUrl, params);

        const response = await fetch(
            url,
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
            }
        )
        const data = await response.json()
        return data;
    } catch(error) {
        return { data: {status: "failed", message: String(error)}}
    }
}

export const createInboundOrder = async (
    fulfilledBy: string | null,
    date: string,
    retailer: string,
    orderNumber: string,
    itemName: string,
    color: string,
    brandSKU: string,
    size: string,
    quantity: string,
    asin: string,
    amazonSKU: string,
    cogs: string,
    listPrice: string,
    notes: string,
    trackingNumbers: string[],
) => {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + "/api/v1/inbound",
            {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
                body: JSON.stringify({
                    fulfilledBy,
                    date,
                    retailer,
                    orderNumber,
                    itemName,
                    color,
                    brandSKU,
                    size,
                    quantity,
                    asin,
                    amazonSKU,
                    cogs,
                    listPrice,
                    notes,
                    trackingNumbers
                })
            }
        )
        const data = await response.json()
        return {success: data.status === "success", message: data.message}
    } catch(error) {
        return {success: false, message: String(error)}
    }
}

export const createBulkInboundOrder = async (
    selectedFile: File | null,
    showNotification: (title: string, subtitle: string, type: 'success' | 'error') => void
) => {
    try {
        if (!selectedFile) {
            return {success: false, message: "No file selected"}
        }
        const formData = new FormData();
        formData.append("file", selectedFile);
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + "/api/v1/inbound",
            {
                method: "POST",
                headers: { 
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
                body: formData
            }
        )
        const data = await response.json()
        return {success: data.status === "success", message: data.message} 
    } catch(error) {
        showNotification("An error occurred creating the order", "", "error")
        return {success: false, message: String(error)}
    }
}

export const fetchInboundOrder = async (
    inbound_id: string | undefined,
    setInboundOrder: React.Dispatch<React.SetStateAction<Order | null>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    try {
        setLoading(true)
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + "/api/v1/inbound/" + inbound_id,
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                }
            }
        )

        const data = await response.json()
        if (data.status === "success") {
            setInboundOrder(data.data)
            setLoading(false)
        } else {
            setLoading(false)
        }

    } catch(error) {
        setLoading(false)
    }
}

export const deleteInboundOrder = async (
    order_ids: string[]
) => {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + "/api/v1/inbound/delete",
            {
                method: "DELETE",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
                body: JSON.stringify({order_ids})
            }
        )

        const data = await response.json()
        return {success: data.status === "success", message: data.message} 

    } catch(error) {
        return {success: false, message: String(error)} 
    }
}

export const updateInboundOrder = async (
    inbound_id: string | undefined,
    fulfilledBy: string,
    date: string,
    retailer: string,
    orderNumber: string,
    itemName: string,
    color: string,
    brandSKU: string,
    size: string,
    quantity: string,
    asin: string,
    amazonSKU: string,
    cogs: string,
    listPrice: string,
    notes: string,
    trackingNumbers: string[],
) => {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + "/api/v1/inbound/" + inbound_id,
            {
                method: "PUT",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
                body: JSON.stringify({
                    fulfilledBy,
                    date,
                    retailer,
                    orderNumber,
                    itemName,
                    color,
                    brandSKU,
                    size,
                    quantity,
                    asin,
                    amazonSKU,
                    cogs,
                    listPrice,
                    notes,
                    trackingNumbers
                })
            }
        )

        const data = await response.json()
        return {success: data.status === "success", message: data.message}

    } catch(error) {
        return {success: false, message: String(error)}
    }
}

export const bulkUpdateInboundOrders = async (
    order_ids: string[],
    fulfilledBy: string,
    date: string,
    retailer: string,
    orderNumber: string,
    itemName: string,
    color: string,
    brandSKU: string,
    size: string,
    quantity: string,
    asin: string,
    amazonSKU: string,
    cogs: string,
    listPrice: string,
    notes: string,
) => {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + "/api/v1/inbound/bulk-edit",
            {
                method: "PUT",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
                body: JSON.stringify({
                    order_ids,
                    fulfilledBy,
                    date,
                    retailer,
                    orderNumber,
                    itemName,
                    color,
                    brandSKU,
                    size,
                    quantity,
                    asin,
                    amazonSKU,
                    cogs,
                    listPrice,
                    notes,
                })
            }
        )

        const data = await response.json()
        return {success: data.status === "success", message: data.message}

    } catch(error) {
        return {success: false, message: String(error)}
    }
}