/** @format */

import { useEffect } from "react";
import TotalOrdersFilters from "../../../../models/filtersCount";
import { ClientPage } from "../../../../models/pageSelectors";
import { getAdminType } from "../../../../utils/adminTypes/actions";
import { formatNumberWithCommas } from "../../../../utils/numbers/formatNumber";

function PageSelector({
    pageSelected,
    handlePageChange,
    filtersCount,
}: {
    pageSelected: ClientPage;
    handlePageChange: (newPage: ClientPage) => void;
    filtersCount: TotalOrdersFilters | null;
}) {
    // Dynamically create selectorsData based on filtersCount
    const selectorsData = [
        { label: "Client", value: "data" },
        {
            label: `Inbound (${
                formatNumberWithCommas(filtersCount?.inbound) || 0
            })`,
            value: "inbound",
        },
        {
            label: `Inventory (${
                formatNumberWithCommas(filtersCount?.inventory) || 0
            })`,
            value: "inventory",
        },
        {
            label: `Outbound (${
                formatNumberWithCommas(filtersCount?.outbound) || 0
            })`,
            value: "outbound",
        },
        { label: "Billing", value: "billing" },
    ];

    // Define if the user is an owner or not
    const isOwner = getAdminType() === "owner";

    // Get filtered selectors based on user role
    const filteredSelectors = isOwner
        ? selectorsData
        : selectorsData.filter((selector) => selector.value !== "billing");

    return (
        <div className="flex flex-col pt-10">
            <div className="flex flex-row font-semibold pb-2 relative">
                {filteredSelectors.map((item) => (
                    <button
                        className={`relative ${
                            pageSelected === item.value
                                ? "text-accent after:bg-purple-500"
                                : "text-gray-400 after:bg-transparent"
                        } w-44 after:absolute after:left-0 after:bottom-[-12px] after:h-1 after:w-full`}
                        onClick={() =>
                            handlePageChange(item.value as ClientPage)
                        }
                    >
                        {item.label}
                    </button>
                ))}
            </div>
            <div className="w-full h-1 bg-gray-300"></div>
        </div>
    );
}

export default PageSelector;
