/** @format */
/** @format */

import ClientSideBar from "../../../../components/client/sideBar";
import Header from "../../../../components/header";
import TrackingsArrivedTable from "../../inventory/tracking-data/trackingsTable";
import { fetchTrackings } from "../../inventory/api/trackingsApi";
import { useEffect, useRef, useState } from "react";
import Results from "../../../../models/results";
import { TrackingArrived } from "../../../../components/trackingArrived";
import { useSearchParams } from "react-router-dom";
import { useNotification } from "../../../../utils/notification/notificationContext";
import { useSort } from "../../../../hooks/useSort";
import ClientSearchBar from "../../../../components/client/searchBar";

export default function InboundTrackingsPage() {
    const [searchParams] = useSearchParams();
    const { showNotification } = useNotification();

    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [trackingsLoading, setTrackingsLoading] = useState(false);
    const [trackings, setTrackings] = useState<TrackingArrived[]>([]);
    const [trackingsResults, setTrackingsResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [refetchData, setRefetchData] = useState<number>(0);
    const handleRefetch = () => {
        setRefetchData((prevValue) => prevValue + 1);
    };

    const hasFetchedTrackings = useRef(false);

    const fetchTrackingsData = async () => {
        const data = await fetchTrackings(searchParams, sortConfig, "inbound");
        if (data.status === "success") {
            setTrackings(data.data.trackings);
            setTrackingsResults(data.data.results);
        } else {
            showNotification("Failed fetching data", data.message, "error");
        }
        setTrackingsLoading(false);
        setSortLoading(false);
        hasFetchedTrackings.current = true;
    };

    useEffect(() => {
        setTrackingsLoading(true);
        fetchTrackingsData();
    }, []);

    useEffect(() => {
        if (hasFetchedTrackings.current) {
            setSortLoading(true);
            fetchTrackingsData();
        }
    }, [sortConfig]);

    return (
        <div className="flex flex-row">
            <ClientSideBar />
            <div className="flex flex-col flex-grow overflow-x-auto">
                <div className="sm:px-10 px-4">
                    <Header />
                </div>
                <div className="mx-4 sm:mx-10 pt-10 flex flex-col flex-grow overflow-x-auto">
                    <div className="flex flex-col sm:flex-row justify-between sm:items-center">
                        <span className="text-2xl font-bold">Inbound</span>
                    </div>

                    <div className="py-4">
                        <ClientSearchBar
                            setData={setTrackings}
                            setLoading={setTrackingsLoading}
                            handleRefetch={handleRefetch}
                            page="inbound"
                            sub_page="trackings"
                        />
                    </div>
                    <TrackingsArrivedTable
                        trackings={trackings}
                        results={trackingsResults}
                        loading={trackingsLoading}
                        handleRefetch={handleRefetch}
                        sortConfig={sortConfig}
                        toggleSort={toggleSort}
                        sortLoading={sortLoading}
                        type="inbound"
                    />
                </div>
            </div>
        </div>
    );
}
