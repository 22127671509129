/** @format */

import { CheckIcon, ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import { useState } from "react";

export default function CopyButton({ textToCopy }: { textToCopy: string }) {
    const [copied, setCopied] = useState(false);

    const copyUrl = () => {
        navigator.clipboard.writeText(textToCopy);
        setCopied(true);

        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    return (
        <button
            onClick={() => copyUrl()}
            // className="flex flex-row items-center gap-2 hover:border-gray-300 font-medium text-left px-3 py-1 text-sm rounded-md bg-lightGray border border-grey-200"
            className="px-2"
        >
            {copied ? (
                <CheckIcon className="w-4 h-4 text-green-500" />
            ) : (
                <ClipboardDocumentIcon className="w-4 h-4" />
            )}
        </button>
    );
}
