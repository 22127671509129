/** @format */

import { useState, useRef, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import Client from "../../models/clients";
import { searchData } from "../../pages/admin/3plclients/api/clientsApi";
import { Order } from "../../models/order";
import Shipment from "../../models/shipments";
import { useLocation, useParams } from "react-router-dom";
import { TrackingArrived } from "../trackingArrived";

interface HeaderSearchBarProps {
    setData:
        | React.Dispatch<React.SetStateAction<Client[]>>
        | React.Dispatch<React.SetStateAction<Order[]>>
        | React.Dispatch<React.SetStateAction<Shipment[]>>
        | React.Dispatch<React.SetStateAction<TrackingArrived[]>>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    handleRefetch: () => void;
}

export default function HeaderSearchBar({
    setData,
    setLoading,
    handleRefetch,
}: HeaderSearchBarProps) {
    const { user_id } = useParams();
    const [keywords, setKeywords] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState<string>("");
    const inputRef = useRef<HTMLInputElement>(null);

    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const page = queryParams.get("page");
        const sub_page = queryParams.get("sub_page");

        // Determine search term based on keywords or inputValue
        const searchTerm =
            keywords.length > 0 ? keywords.join(";") : inputValue;

        searchData(
            searchTerm,
            user_id,
            page,
            sub_page,
            setData,
            setLoading,
            handleRefetch
        );

        return () => searchData.cancel();
    }, [keywords, inputValue]); // Add inputValue to dependencies

    // Function to add a keyword when the user presses Enter
    const handleAddKeyword = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && inputValue.trim()) {
            setKeywords([...keywords, inputValue.trim()]);
            setInputValue("");
        }
    };

    // Function to remove a keyword
    const handleRemoveKeyword = (index: number) => {
        setKeywords(keywords.filter((_, i) => i !== index));
    };

    return (
        <div className="relative w-full sm:w-96">
            <div className="group">
                <div className="group flex flex-row items-center space-x-3 px-2 h-8 rounded-md bg-gray-100 w-full">
                    <FaSearch className="text-gray-400 w-2.5 h-2.5 ml-1" />
                    <input
                        placeholder="Search"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        className="outline-none px-0 py-0 text-xs bg-transparent h-8 w-full border-transparent focus:border-transparent focus:ring-0"
                        ref={inputRef}
                        onKeyDown={handleAddKeyword}
                    />
                </div>
                <div className="flex flex-wrap mt-2">
                    {keywords.map((keyword, index) => (
                        <div
                            key={index}
                            className="bg-gray-100 px-2 py-1 rounded-md flex items-center space-x-2 m-1"
                        >
                            <span className="text-sm">{keyword}</span>
                            <button
                                onClick={() => handleRemoveKeyword(index)}
                                className="text-red-500 text-xs"
                            >
                                x
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
