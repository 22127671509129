/** @format */

import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
} from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import Header from "../../components/website/header";
import { useNotification } from "../../utils/notification/notificationContext";
import { useState } from "react";
import { sendContactRequest } from "./api/contactApi";
import LoadingWheel from "../../components/loadingWheel";

const faqs = [
    {
        question: "Online Arbitrage Prep",
        answer: "At our tax-free Delaware warehouse, we streamline the entire online arbitrage process for you. Once your packages arrive, we log their contents into our in-house dashboard, giving you real-time visibility into every shipment and order status. We take care of all aspects of Amazon FBA and FBM prep, ensuring your products are properly labeled, packaged, and fully compliant with Amazon’s requirements. From the moment we receive your inventory to the final step of fulfillment, we ensure everything is ready for fast, efficient shipping.",
    },
    {
        question: "Wholesale & Private Label Prep",
        answer: "Our tax-free Delaware warehouse is fully equipped to handle your wholesale and private label shipments, whether they arrive in pallets or individual packages. We log every shipment into our dedicated dashboard, giving you instant visibility into the status of each order. From receiving and processing to Amazon FBA and FBM prep, we ensure every item is carefully handled. As experts in brand partnerships, we offer custom packaging solutions tailored to your needs. We’re also experienced in managing large orders, including LTL shipments directly to Amazon warehouses, guaranteeing your products arrive on time and in perfect condition.",
    },
    {
        question: "Order Fulfillment for All Platforms",
        answer: "Whether you run an online store on Shopify, eBay, Walmart, or any platform outside of Amazon, our full-service fulfillment solutions are designed to support your business. We receive your products, log shipments for real-time tracking, and manage every step of order processing—from inventory management to packing and shipping. With our dedicated dashboard, you’ll have real-time access to monitor orders, shipments, and inventory status, keeping you fully in control. Our warehouse team ensures accurate, on-time deliveries, whether orders are going to customers or retail partners. No matter the size or complexity of your operations, we tailor our services to meet your unique fulfillment needs, providing smooth and efficient support for your business.",
    },
    {
        question: "Return Management",
        answer: "Our return management service handles the entire process, ensuring your returned items are managed efficiently and with care. When a return arrives, we thoroughly inspect and categorize each item based on its condition. Sellable products are restocked into your inventory and prepared for immediate fulfillment, while unsellable items are processed and stored according to your instructions. If needed, we offer reshipping services for unsellable items, ensuring they reach the correct destination. Throughout the process, you have full visibility through our tracking system, keeping you informed every step of the way. Our goal is to minimize the hassle of returns and keep your operations running smoothly",
    },
];

function OrderFulfillmentPage() {
    const scrollToSection = (sectionId: string) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };

    const [loading, setLoading] = useState(false);
    const { showNotification } = useNotification();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [message, setMessage] = useState("");

    const handleContact = async () => {
        setLoading(true);
        const data = await sendContactRequest(
            firstName,
            lastName,
            companyName,
            email,
            phoneNumber,
            message
        );
        if (data.status === "success") {
            showNotification("Succesfully sent contact request", "", "success");
        } else {
            showNotification("Failed fetching data", data.message, "error");
        }
        setLoading(false);
    };

    return (
        <div>
            <Header />
            <div className="bg-white">
                <div className="mx-auto max-w-7xl px-6 lg:px-8 pt-32 flex flex-col sm:flex-row">
                    <div className="mr-20 w-full">
                        <div className="lg:mr-10 w-full flex flex-col items-start">
                            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                Order Fulfillment Services
                            </p>
                            <div className="divide-y divide-gray-900/10 w-full">
                                <p className="relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
                                    At Urva, we simplify fulfillment for
                                    ecommerce businesses by offering a smooth,
                                    reliable process. Whether you're shipping
                                    wholesale, private label, or online
                                    arbitrage products, we’ve got you covered.
                                    Our user-friendly dashboard lets you manage
                                    all your inbound and outbound shipments in
                                    one place. No matter where you sell, whether
                                    it’s Amazon or another platform, we handle
                                    the logistics so you can focus on growing
                                    your business.
                                </p>
                                <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                                    {faqs.map((faq) => (
                                        <Disclosure
                                            key={faq.question}
                                            as="div"
                                            className="pt-6"
                                        >
                                            <dt>
                                                <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
                                                    <span className="text-base font-semibold leading-7">
                                                        {faq.question}
                                                    </span>
                                                    <span className="ml-6 flex h-7 items-center">
                                                        <PlusSmallIcon
                                                            aria-hidden="true"
                                                            className="h-6 w-6 group-data-[open]:hidden"
                                                        />
                                                        <MinusSmallIcon
                                                            aria-hidden="true"
                                                            className="h-6 w-6 [.group:not([data-open])_&]:hidden"
                                                        />
                                                    </span>
                                                </DisclosureButton>
                                            </dt>
                                            <DisclosurePanel
                                                as="dd"
                                                className="mt-2 pr-12"
                                            >
                                                <p className="text-base leading-7 text-gray-600">
                                                    {faq.answer}
                                                </p>
                                                <p
                                                    onClick={() =>
                                                        scrollToSection("quote")
                                                    }
                                                    className="cursor-pointer text-accent mt-2 font-medium"
                                                >
                                                    Request a quote
                                                </p>
                                            </DisclosurePanel>
                                        </Disclosure>
                                    ))}
                                </dl>
                                <div className="w-full bg-gray-900/200 mt-6"></div>
                            </div>
                        </div>
                    </div>
                    <img
                        // className="object-cover w-full h-96 mt-10 rounded-md border border-gray-200"
                        className="object-cover rounded-lg h-96 w-full md:h-[30rem] mt-10 md:mt-0"
                        src="/img-boxes.jpeg"
                    ></img>
                </div>
            </div>

            <div className="pt-32 pb-20">
                <div id="quote" className="px-6 lg:px-8">
                    <div className="mx-auto max-w-xl">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 mb-10">
                            Request a quote
                        </h2>
                        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                            <div>
                                <label
                                    htmlFor="first-name"
                                    className="block text-sm font-semibold leading-6 text-gray-900"
                                >
                                    First name
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        id="first-name"
                                        name="first-name"
                                        type="text"
                                        autoComplete="given-name"
                                        value={firstName}
                                        onChange={(e) =>
                                            setFirstName(e.target.value)
                                        }
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <label
                                    htmlFor="last-name"
                                    className="block text-sm font-semibold leading-6 text-gray-900"
                                >
                                    Last name
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        id="last-name"
                                        name="last-name"
                                        type="text"
                                        autoComplete="family-name"
                                        value={lastName}
                                        onChange={(e) =>
                                            setLastName(e.target.value)
                                        }
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label
                                    htmlFor="company-name"
                                    className="block text-sm font-semibold leading-6 text-gray-900"
                                >
                                    Company Name
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        // id="company-name"
                                        // name="company-name"
                                        // type="company-name"
                                        value={companyName}
                                        onChange={(e) =>
                                            setCompanyName(e.target.value)
                                        }
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-semibold leading-6 text-gray-900"
                                >
                                    Email
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label
                                    htmlFor="phone-number"
                                    className="block text-sm font-semibold leading-6 text-gray-900"
                                >
                                    Phone number
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        id="phone-number"
                                        name="phone-number"
                                        type="tel"
                                        autoComplete="tel"
                                        value={phoneNumber}
                                        onChange={(e) =>
                                            setPhoneNumber(e.target.value)
                                        }
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label
                                    htmlFor="message"
                                    className="block text-sm font-semibold leading-6 text-gray-900"
                                >
                                    Message
                                </label>
                                <div className="mt-2.5">
                                    <textarea
                                        id="message"
                                        name="message"
                                        rows={4}
                                        value={message}
                                        onChange={(e) =>
                                            setMessage(e.target.value)
                                        }
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                        defaultValue={""}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-8 flex justify-end">
                            <button
                                onClick={handleContact}
                                className="rounded-md bg-accent px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-accenthighlight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
                            >
                                {!loading ? (
                                    <>Request quote</>
                                ) : (
                                    <LoadingWheel size="small" color="white" />
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrderFulfillmentPage;
