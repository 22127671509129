/** @format */

import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Results from "../../../../../../models/results";
import {
    calculateOffset,
    getOffset,
} from "../../../../../../utils/pagination/calculateOffset";
import { formatMoney } from "../../../../../../utils/price/formatMoney";
import { StatusTag } from "../../../../../../components/statusTags";
import { formatDateFromDb } from "../../../../../../utils/date/date_formatter";
import TrackingNumbersModal from "../../../../../../components/modals/trackingNumbersModal";
import CheckInDatesModal from "../../../../../../components/modals/checkInDatesModal";
import { CheckInDate } from "../../../../../../models/checkInDate";
import { useCheckInDatesModal } from "../../../../../../hooks/useCheckInDates";
import { useTrackingNumbersModal } from "../../../../../../hooks/useTrackingNumbers";
import {
    Order,
    ShelfLocations,
    ShipmentQuantities,
} from "../../../../../../models/order";
import { PaginationLink } from "../../../../../../utils/pagination/paginationLink";
import { PaginationResults } from "../../../../../../utils/pagination/paginationResults";
import useCellSelection from "../../../../../../hooks/useCellSelection";

interface ClientInboundTableProps {
    inboundOrders: Order[];
    clientInboundResults?: Results;
    selectedInboundOrders?: Order[];
    setSelectedInboundOrders?: React.Dispatch<React.SetStateAction<Order[]>>;
    shipmentQuantities?: ShipmentQuantities;
    handleQuantityChange?: (orderId: string, value: string) => void;
    shelfs?: ShelfLocations;
    handleShelfChange?: (orderId: string, value: string) => void;
    filesByOrder?: {
        [orderId: string]: File[];
    };
    handleFileChange?: (orderId: string, files: File[]) => void;
    removeFile: (orderId: string, fileToRemove: File) => void;
}

function ClientInboundTable({
    inboundOrders,
    clientInboundResults,
    selectedInboundOrders,
    setSelectedInboundOrders,
    shipmentQuantities,
    handleQuantityChange,
    shelfs,
    handleShelfChange,
    filesByOrder,
    handleFileChange,
    removeFile,
}: ClientInboundTableProps) {
    const [searchParams] = useSearchParams();

    const { user_id } = useParams();

    const [loading] = useState(false);

    const handleCheckboxChange = (order: Order) => {
        const orderId = order.order_id;
        if (selectedInboundOrders && setSelectedInboundOrders) {
            if (selectedInboundOrders.some((o) => o.order_id === orderId)) {
                // Remove the order by its id
                setSelectedInboundOrders(
                    selectedInboundOrders.filter((o) => o.order_id !== orderId)
                );
            } else {
                // Add the order using its id
                setSelectedInboundOrders([...selectedInboundOrders, order]);
            }
        }
    };

    const {
        openTrackingNumbersModal,
        setOpenTrackingNumbersModal,
        selectedTrackingNumbers,
        handleViewTrackings,
    } = useTrackingNumbersModal();

    const {
        openCheckInDatesModal,
        setOpenCheckInDatesModal,
        selectedOrder,
        handleViewDates,
    } = useCheckInDatesModal();

    const getCellValue = (
        rowIndex: number,
        columnIndex: number,
        data: any[]
    ) => {
        const order = data[rowIndex];
        switch (columnIndex) {
            case 8:
                return order.quantity;
            case 12:
                return order.unit_cost;
            case 13:
                return order.list_price;
            default:
                return 0;
        }
    };

    const {
        selectedCells,
        handleSingleClick,
        handleMouseDown,
        handleMouseEnter,
        handleMouseUp,
        sumSelectedQuantities,
    } = useCellSelection([], getCellValue, [8, 12, 13]);

    return (
        <div className="flex flex-col">
            {!loading && inboundOrders && inboundOrders.length > 0 ? (
                <>
                    <div className="max-h-[520px] overflow-y-auto overflow-x-scroll relative scrollbar">
                        <table
                            onMouseUp={handleMouseUp}
                            className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full"
                        >
                            <thead className="sticky top-0 bg-white z-10">
                                <tr className="text-left text-sm">
                                    {selectedInboundOrders ? (
                                        <th className="py-2 min-w-10"></th>
                                    ) : (
                                        <th className="py-2 min-w-36">
                                            Quantity Received
                                        </th>
                                    )}
                                    {selectedInboundOrders ? (
                                        <th className="py-2 min-w-10"></th>
                                    ) : (
                                        <th className="py-2 min-w-36">
                                            Location
                                        </th>
                                    )}
                                    <th className="py-2 sm:min-w-32 min-w-32">
                                        Status
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28 pl-2">
                                        Date
                                    </th>
                                    <th className="py-2 min-w-32">Retailer</th>
                                    <th className="py-2 min-w-32">Order #</th>
                                    <th className="py-2 sm:min-w-48 min-w-48">
                                        Item Name
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Brand SKU
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Size
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Quantity
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Color
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        ASIN
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Amazon SKU
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Unit price
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        List price
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Trackings
                                    </th>
                                    <th className="py-2 sm:min-w-32 min-w-28">
                                        Notes
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {inboundOrders.map((order, rowIndex) => (
                                    <tr
                                        key={order.order_id}
                                        className="relative border-t border-gray-200 text-sm text-gray-500"
                                    >
                                        {selectedInboundOrders ? (
                                            <td className="py-3 flex items-center">
                                                <input
                                                    type="checkbox"
                                                    className="h-4 w-4 rounded border-gray-300 text-accent focus:ring-0 focus:ring-transparent ring-0 focus:ring-offset-0 focus:outline transition-all"
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            order
                                                        )
                                                    }
                                                    checked={selectedInboundOrders.some(
                                                        (o) =>
                                                            o.order_id ===
                                                            order.order_id
                                                    )}
                                                />
                                            </td>
                                        ) : (
                                            <></>
                                        )}
                                        {shipmentQuantities &&
                                            handleQuantityChange && (
                                                <td>
                                                    <input
                                                        className="block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                                        type="number"
                                                        value={
                                                            shipmentQuantities[
                                                                order.order_id
                                                            ] || ""
                                                        }
                                                        onChange={(e) =>
                                                            handleQuantityChange(
                                                                order.order_id,
                                                                e.target.value
                                                            )
                                                        }
                                                    ></input>
                                                </td>
                                            )}
                                        {shelfs && handleShelfChange && (
                                            <td>
                                                <input
                                                    className="block w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                                    value={
                                                        shelfs[
                                                            order.order_id
                                                        ] || ""
                                                    }
                                                    onChange={(e) =>
                                                        handleShelfChange(
                                                            order.order_id,
                                                            e.target.value
                                                        )
                                                    }
                                                ></input>
                                            </td>
                                        )}
                                        <td className="py-2.5">
                                            <StatusTag
                                                status={order.unit_status}
                                                quantity_received={
                                                    order.quantity_received
                                                }
                                                quantity={order.quantity}
                                                handleClick={(e) => {
                                                    e.stopPropagation();
                                                    handleViewDates(order);
                                                }}
                                            />
                                        </td>
                                        <td className="pl-2">
                                            {formatDateFromDb(order.date)}
                                        </td>
                                        <td className="">{order.retailer}</td>
                                        <td className="">
                                            {order.order_number}
                                        </td>
                                        <td className="">{order.item_name}</td>
                                        <td className="">{order.brand_sku}</td>
                                        <td className="">{order.size}</td>
                                        <td
                                            onMouseDown={() =>
                                                handleMouseDown(
                                                    rowIndex,
                                                    8,
                                                    inboundOrders
                                                )
                                            }
                                            onMouseEnter={() =>
                                                handleMouseEnter(
                                                    rowIndex,
                                                    8,
                                                    inboundOrders
                                                )
                                            }
                                            onClick={() =>
                                                handleSingleClick(
                                                    rowIndex,
                                                    8,
                                                    inboundOrders
                                                )
                                            }
                                            className={`${
                                                selectedCells.some(
                                                    (cell) =>
                                                        cell.rowIndex ===
                                                            rowIndex &&
                                                        cell.columnIndex === 8
                                                )
                                                    ? "bg-blue-200 select-none"
                                                    : "select-none"
                                            }`}
                                        >
                                            {order.quantity}
                                        </td>
                                        <td className="">{order.color}</td>
                                        <td className="">{order.asin}</td>
                                        <td className="">{order.amazon_sku}</td>
                                        <td
                                            onMouseDown={() =>
                                                handleMouseDown(
                                                    rowIndex,
                                                    12,
                                                    inboundOrders
                                                )
                                            }
                                            onMouseEnter={() =>
                                                handleMouseEnter(
                                                    rowIndex,
                                                    12,
                                                    inboundOrders
                                                )
                                            }
                                            onClick={() =>
                                                handleSingleClick(
                                                    rowIndex,
                                                    12,
                                                    inboundOrders
                                                )
                                            }
                                            className={`${
                                                selectedCells.some(
                                                    (cell) =>
                                                        cell.rowIndex ===
                                                            rowIndex &&
                                                        cell.columnIndex === 12
                                                )
                                                    ? "bg-blue-200 select-none"
                                                    : "select-none"
                                            }`}
                                        >
                                            {formatMoney(order.unit_cost)}
                                        </td>
                                        <td
                                            onMouseDown={() =>
                                                handleMouseDown(
                                                    rowIndex,
                                                    13,
                                                    inboundOrders
                                                )
                                            }
                                            onMouseEnter={() =>
                                                handleMouseEnter(
                                                    rowIndex,
                                                    13,
                                                    inboundOrders
                                                )
                                            }
                                            onClick={() =>
                                                handleSingleClick(
                                                    rowIndex,
                                                    13,
                                                    inboundOrders
                                                )
                                            }
                                            className={`${
                                                selectedCells.some(
                                                    (cell) =>
                                                        cell.rowIndex ===
                                                            rowIndex &&
                                                        cell.columnIndex === 13
                                                )
                                                    ? "bg-blue-200 select-none"
                                                    : "select-none"
                                            }`}
                                        >
                                            {formatMoney(order.list_price)}
                                        </td>
                                        <td
                                            onClick={() =>
                                                handleViewTrackings(
                                                    order.trackings
                                                )
                                            }
                                            className="text-accent"
                                        >
                                            View
                                        </td>
                                        <td className="">{order.user_notes}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {clientInboundResults ? (
                        <nav
                            aria-label="Pagination"
                            className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                        >
                            <div className="hidden sm:block">
                                <PaginationResults
                                    results={clientInboundResults}
                                />
                            </div>
                            <div className="flex flex-1 justify-between sm:justify-end">
                                <PaginationLink
                                    href={`/admin/clients/${user_id}?page=shipments&status=inbound&offset=${calculateOffset(
                                        searchParams,
                                        "back"
                                    )}`}
                                    disabled={getOffset(searchParams) === 0}
                                    text="Previous"
                                />
                                <PaginationLink
                                    href={`/admin/clients/${user_id}?page=shipments&status=inbound&offset=${calculateOffset(
                                        searchParams,
                                        "forward"
                                    )}`}
                                    disabled={
                                        clientInboundResults.showing_to >=
                                        clientInboundResults.total_results
                                    }
                                    text="Next"
                                    className="ml-3"
                                />
                            </div>
                        </nav>
                    ) : (
                        <></>
                    )}
                    {selectedCells.length > 0 && (
                        <span className="text-sm text-gray-900 flex flex-row items-center">
                            {"Sum: "}
                            {sumSelectedQuantities.map(
                                ({ columnIndex, sum }) => (
                                    <>
                                        {sum > 0 && (
                                            <p
                                                key={columnIndex}
                                                className="pl-1"
                                            >
                                                {columnIndex !== 12 &&
                                                columnIndex !== 13
                                                    ? sum
                                                    : formatMoney(sum)}
                                            </p>
                                        )}
                                    </>
                                )
                            )}
                        </span>
                    )}
                </>
            ) : (
                <div className="flex flex-grow items-center pt-10 justify-center">
                    <span className="text-sm text-gray-500">
                        User has no inbound orders
                    </span>
                </div>
            )}

            <TrackingNumbersModal
                openTrackingNumbersModal={openTrackingNumbersModal}
                setOpenTrackingNumbersModal={setOpenTrackingNumbersModal}
                trackingNumbers={selectedTrackingNumbers}
            />

            <CheckInDatesModal
                openCheckInDatesModal={openCheckInDatesModal}
                setOpenCheckInDatesModal={setOpenCheckInDatesModal}
                selectedOrder={selectedOrder}
                filesByOrder={filesByOrder}
                handleFileChange={handleFileChange}
                removeFile={removeFile}
            />
        </div>
    );
}

export default ClientInboundTable;
