

export const sendContactRequest = async (
    firstName: string,
    lastName: string,
    companyName: string,
    email: string,
    phoneNumber: string,
    message: string,
) => {
    try {
        const jsonBody = {
            first_name: firstName,
            last_name: lastName,
            company_name: companyName,
            email: email,
            phone_number: phoneNumber,
            message: message,

        }
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + "/api/v1/site/request-quote",
            {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(jsonBody)
            }
        )
        const data = await response.json()
        return data;
    } catch (error) {
        return { data: {status: "failed", message: String(error)} }
    
    }
}

export const sendClaimedDiscount = async (
    name: string,
    email: string,
    phone: string
) => {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + "/api/v1/site/claimed-discount",
            {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({name, email, phone})
            }
        )
        const data = await response.json()
        return data;
    } catch (error) {
        return { data: {status: "failed", message: String(error)} }
    
    }
}