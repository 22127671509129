/** @format */

import Client from "../../../../models/clients";
import { formatMoney } from "../../../../utils/price/formatMoney";
import { useState } from "react";
import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { capitalizeFirstLetter } from "../../../../utils/strings/string";
import { useNavigate } from "react-router-dom";
import { Invoices, AdminNotification } from "../../../../models/adminDashboard";
import { removeNotification } from "../api/dashboardApi";
import LoadingWheel from "../../../../components/loadingWheel";
import NotificationsModal from "../../../../components/modals/notificationsModal";

export const dateValues = [
    { id: 1, name: "Today" },
    { id: 2, name: "Last 4 weeks" },
    { id: 3, name: "Month to date" },
    { id: 4, name: "All time" },
];

function DateSelector({
    selected,
    setSelected,
}: {
    selected: {
        id: number;
        name: string;
    };
    setSelected: React.Dispatch<
        React.SetStateAction<{
            id: number;
            name: string;
        }>
    >;
}) {
    return (
        <Listbox value={selected} onChange={setSelected}>
            <div className="relative mt-3 mb-3">
                <ListboxButton className="relative cursor-default rounded-md bg-white py-1 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none sm:text-sm sm:leading-6">
                    <span className="block truncate text-xs font-medium">
                        {selected.name}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon
                            aria-hidden="true"
                            className="h-5 w-5 text-gray-400"
                        />
                    </span>
                </ListboxButton>

                <ListboxOptions
                    transition
                    className="absolute z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-xs"
                >
                    {dateValues.map((value) => (
                        <ListboxOption
                            key={value.id}
                            value={value}
                            // className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-accent data-[focus]:text-white flex flex-row items-center"
                            className={({ active, selected }) =>
                                `group relative cursor-default select-none py-2 pl-3 pr-9
                            ${
                                active
                                    ? "bg-accent text-white"
                                    : selected
                                    ? "text-accent font-semibold"
                                    : "text-gray-900"
                            }
                            `
                            }
                        >
                            <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-accent group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                <CheckIcon
                                    aria-hidden="true"
                                    className="h-3 w-3"
                                />
                            </span>
                            <span className="block truncate font-normal group-data-[selected]:font-semibold group-data-[focus]:text-white">
                                {value.name}
                            </span>
                        </ListboxOption>
                    ))}
                </ListboxOptions>
            </div>
        </Listbox>
    );
}

function BillingStats({
    topSpendClients,
    invoicesData,
    notificationsData,
    setNotificationsData,
    selected,
    setSelected,
}: {
    topSpendClients: Client[];
    invoicesData: Invoices | null;
    notificationsData: AdminNotification[];
    setNotificationsData: React.Dispatch<
        React.SetStateAction<AdminNotification[]>
    >;
    selected: {
        id: number;
        name: string;
    };
    setSelected: React.Dispatch<
        React.SetStateAction<{
            id: number;
            name: string;
        }>
    >;
}) {
    const navigate = useNavigate();
    const invoices = [
        {
            label: "open",
            value: invoicesData?.open_invoices_count,
            color: "bg-gray-400",
        },
        {
            label: "closed",
            value: invoicesData?.closed_unpaid_invoices_count,
            color: "bg-yellow-400",
        },
        {
            label: "paid",
            value: invoicesData?.closed_paid_invoices_count,
            color: "bg-green-400",
        },
    ];

    const [deletingNotifications, setDeletingNotifications] = useState<
        string[]
    >([]);

    const [totalNotificationsCount, setTotalNotificationsCount] = useState(
        notificationsData.length > 0 ? notificationsData[0].total_count : 0
    );

    const handleRemove = async (
        e: React.MouseEvent<SVGSVGElement, MouseEvent>,
        notificationId: string
    ) => {
        setDeletingNotifications((prev) => [...prev, notificationId]);
        e.stopPropagation();
        const data = await removeNotification(notificationId);
        if (data.status === "success") {
            setNotificationsData((prevNotifications) =>
                prevNotifications.filter(
                    (notification) =>
                        notification.notification_id !== notificationId
                )
            );
            setTotalNotificationsCount((prev) => prev - 1);
        }
    };

    const [openNotificationsModal, setOpenNotificationsModal] = useState(false);

    return (
        <div className="">
            <DateSelector selected={selected} setSelected={setSelected} />
            <div className="w-full h-px bg-gray-200"></div>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-20 pt-6">
                <div className="w-full flex flex-col justify-between">
                    <div className="flex flex-col space-y-3">
                        <h2 className="font-semibold text-sm">Invoices</h2>
                        <div className="flex flex-row items-end space-x-1">
                            <h2 className="font-medium text-2xl">
                                {formatMoney(invoicesData?.total_invoices_sum)}
                            </h2>
                            <p className="text-sm text-gray-400 pb-0.5">
                                in revenue
                            </p>
                        </div>
                    </div>
                    <div className="divide-y">
                        {invoices.map((invoice) => (
                            <div className="flex flex-row justify-between py-2 items-center">
                                <div className="flex flex-row items-center space-x-2">
                                    <div
                                        className={`h-4 w-4 ${invoice.color} rounded-md`}
                                    ></div>
                                    <span className="text-xs font-semibold">
                                        {capitalizeFirstLetter(invoice.label)}
                                    </span>
                                </div>
                                <span className="text-gray-800 text-xs font-medium">
                                    {invoice.value}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="w-full flex flex-col">
                    <div className="flex flex-row justify-between w-full items-center">
                        <h2 className="font-semibold text-sm">Notifications</h2>
                        <div className="bg-red-500 h-5 px-1 text-white text-xs items-center flex justify-center font-medium rounded-md">
                            {totalNotificationsCount}
                        </div>
                    </div>
                    {notificationsData.length > 0 ? (
                        <div className="divide-y pt-1">
                            {notificationsData
                                .slice(0, 5)
                                .map((notification) => (
                                    <div
                                        key={notification.notification_id}
                                        onClick={() =>
                                            navigate(notification.href)
                                        }
                                        className="flex flex-row justify-between py-2 items-center hover:bg-gray-100 cursor-pointer transition-all"
                                    >
                                        <div className="flex flex-col">
                                            <span className="text-xs font-semibold">
                                                {notification.title}
                                            </span>
                                            <span className="text-xs text-gray-600">
                                                {notification.subtitle}
                                            </span>
                                        </div>
                                        {deletingNotifications.includes(
                                            notification.notification_id
                                        ) ? (
                                            <LoadingWheel
                                                color="black"
                                                size="small"
                                            />
                                        ) : (
                                            <XMarkIcon
                                                onClick={(e) =>
                                                    handleRemove(
                                                        e,
                                                        notification.notification_id
                                                    )
                                                }
                                                className="w-4 h-4 text-gray-600 hover:text-gray-500"
                                            />
                                        )}
                                    </div>
                                ))}
                            <div className="flex flex-row justify-between pt-1">
                                <button
                                    onClick={() =>
                                        setOpenNotificationsModal(true)
                                    }
                                    className="text-xs text-accent font-medium"
                                >
                                    View all
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className="h-full w-full border border-dashed border-gray-300 mt-2 flex justify-center items-center rounded-md">
                            <p className="text-xs text-gray-600">
                                Nothing new here.
                            </p>
                        </div>
                    )}
                </div>

                <div className="w-full flex flex-col">
                    <div className="flex flex-row justify-between w-full">
                        <h2 className="font-semibold text-sm">
                            Top customers by spend
                        </h2>
                        <p className="text-sm text-gray-500">All time</p>
                    </div>
                    <div className="divide-y">
                        {topSpendClients.map((client, index) => (
                            <div
                                key={index}
                                onClick={() =>
                                    navigate("/admin/clients/" + client.user_id)
                                }
                                className="flex flex-row justify-between py-2 items-center hover:bg-gray-100 cursor-pointer"
                            >
                                <div className="flex flex-col">
                                    <span className="text-xs font-semibold">
                                        {client.first_name} {client.last_name}
                                    </span>
                                    <span className="text-xs text-gray-600">
                                        {client.email}
                                    </span>
                                </div>
                                <span className="text-gray-800 text-xs font-medium">
                                    {formatMoney(client.total_spent)}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <NotificationsModal
                openNotificationsModal={openNotificationsModal}
                setOpenNotificationsModal={setOpenNotificationsModal}
            />
        </div>
    );
}

export default BillingStats;
